import { useEffect, useState, useCallback } from 'react'
import { get, snakeCase } from 'lodash/fp'
import Cookie from 'js-cookie'
import { useBabylonUser } from '@babylon/babylon-user'
import { useProductConfigProps } from './types'

const useProductConfig = ({ getConfig }: useProductConfigProps) => {
  const { partnerIds: partnerList, uuid } = useBabylonUser()

  const [cache, setCache] = useState(
    JSON.parse(localStorage.getItem(`product-config-${uuid}`) || '{}')
  )

  useEffect(() => {
    if (Object.keys(cache).length === 0) {
      const fetchData = async () => {
        const data = await getConfig(partnerList)
        setCache(data)
        localStorage.setItem(`product-config-${uuid}`, JSON.stringify(data))
      }
      fetchData()
    }
  }, [getConfig, cache, partnerList, uuid])

  const determineBetaFeatureValue = <T = boolean>(data, propName): T => {
    const mainFeatureValue = data[propName]
    const betaFeatureValue = data.betaFeatures[propName]
    const cookieFeatureValue = (propName) => Cookie.get(snakeCase(propName))

    return mainFeatureValue === false && betaFeatureValue === true
      ? cookieFeatureValue(propName)
      : mainFeatureValue
  }

  const getBlob = useCallback(
    (pathName: string) => {
      const data = get(pathName, cache)

      if (data?.hasOwnProperty('betaFeatures')) {
        return Object.keys(data.betaFeatures).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: determineBetaFeatureValue(data, curr),
          }),
          data
        )
      }

      return data || {}
    },
    [cache]
  )

  const getProp = useCallback(
    <T = boolean>(pathName: string, propName: string): T | false => {
      const data = get(pathName, cache)

      if (data?.hasOwnProperty('betaFeatures')) {
        const isPropExperiment = data.betaFeatures.hasOwnProperty(propName)

        if (isPropExperiment) {
          return determineBetaFeatureValue<T>(data, propName)
        }
      }

      return get(propName, data) ?? false
    },
    [cache]
  )

  return {
    getBlob,
    getProp,
  }
}

export default useProductConfig

import React from 'react'
import { formatDateTimeWithTimezone } from '@babylon/date-time-formatter'
import cx from 'classnames'
import styles from './styles.module.scss'

export interface AppointmentDateProps {
  timezone: string
  scheduledTime?: string | null
  className?: string
}

export const AppointmentDate = ({
  timezone,
  scheduledTime,
  className,
}: AppointmentDateProps) => {
  if (!scheduledTime) {
    return null
  }

  const twelveHourFormat = timezone.split('/')[0] === 'America'

  const formattedScheduledTime = formatDateTimeWithTimezone(scheduledTime, {
    desiredTimeZone: timezone,
    twelveHourFormat,
  })

  return (
    <div
      className={cx(styles.AppointmentDate, className)}
      data-testid="timeline-card-scheduled-time"
    >
      <div>{formattedScheduledTime}</div>
    </div>
  )
}
export default AppointmentDate

import axios from 'axios'
import { envUrl, envVar } from '@babylon/babylon-env'

// Tenancy uses ISO-3166 standard codes so this is a temporary mapping
// until we migrate over our codes in the manifests
enum AdminRegionISO {
  'UK' = 'GBR',
  'CA' = 'CAN',
  'US' = 'USA',
  'SG' = 'SGP',
}

const getProductConfig = async (
  partnerList: string[] | null | undefined,
  userUuid?: string
) => {
  const headers = userUuid ? { headers: { 'X-Ab-Identifier': userUuid } } : {}
  const { data: productConfig } = await axios.get(
    `${envUrl('PRODUCT_CONFIG_URL')}`,
    {
      params: {
        appname: 'agnostic',
        regions: [
          AdminRegionISO[
            envVar('ADMINISTRATIVE_COUNTRY') as keyof typeof AdminRegionISO
          ],
        ],
        partners: partnerList,
      },
      withCredentials: true,
      ...headers,
    }
  )

  return productConfig
}

export default getProductConfig

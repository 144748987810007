import React from 'react'
import { useQuery } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { formatTimeWithTimezone } from '@babylon/date-time-formatter'
import { getMinutesAndSeconds } from '../../utils'
import messages from './CallList.messages'
import styles from './styles.module.scss'
import { APPOINTMENT_CALL_INFO } from '../../../../queries'
import { useTimezoneContext } from '../../../Member/View/components/TimezoneProvider'

const CallList = ({ appointmentId }: { appointmentId: string }) => {
  const { timezone } = useTimezoneContext()
  const { data, loading } = useQuery(APPOINTMENT_CALL_INFO, {
    variables: {
      appointmentId,
    },
  })

  if (loading) {
    return null
  }

  const callSteps = data?.getCallFlowSteps?.call_flow_steps

  const twelveHourFormat = timezone.split('/')[0] === 'America'

  return (
    <div className={styles.CallList}>
      {callSteps && callSteps.length > 0 ? (
        <ol className={styles.CallList__list} data-testid="call-list">
          {callSteps.map((step) => (
            <li key={step.triggered_at} data-testid="call-listitem">
              {formatTimeWithTimezone(step.triggered_at, {
                desiredTimeZone: timezone,
                twelveHourFormat,
              })}
              &nbsp; (
              <FormattedMessage
                {...messages.duration_in_sec}
                values={getMinutesAndSeconds(step.duration_in_ms)}
              />
              )
            </li>
          ))}
        </ol>
      ) : (
        <div className={styles.CallList_noCalls}>
          <FormattedMessage {...messages.no_calls} />
        </div>
      )}
    </div>
  )
}

export default CallList

import React, { useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { envFlag } from '@babylon/babylon-env'
import { useProductConfig } from '@babylon/product-config'
import { Spinner } from '@babylon/core-ui'

import { useIsOnGPAtHandNetwork, useQueryParams } from '../../hooks'
import { AppointmentFields, ArrangeAppointment } from '../..'
import CreateAppointment from '../../CreateAppointment'
import DigitalAppointmentForm from '../../DigitalAppointmentForm'

interface QueryParams {
  page: string
}

const WITH_SERVICE_TYPES_PAGE = 'with-service-types'

export default function CreateAppointmentRoute() {
  const { getProp } = useProductConfig()
  const history = useHistory()
  const { state } = useLocation()
  const { id: memberId } = useParams<{ id: string }>()
  const [filters] = useState<AppointmentFields>(state?.filters)
  const [params] = useQueryParams<QueryParams>({})

  const { page } = params

  const isServiceTypesPage = page === WITH_SERVICE_TYPES_PAGE

  const canBookWithServiceType = envFlag(
    'BOOK_APPOINTMENT_WITH_SERVICE_TYPE',
    false
  )
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )
  const digitalInviteEnabled =
    getProp('clinicalCare.appointments.adminPortal', 'digitalInviteEnabled') ||
    isServiceTypesPage

  const enableGPAtHandCheckForDigitalInvites = envFlag(
    'ENABLE_GP_AT_HAND_CHECK_FOR_DIGITAL_INVITES',
    false
  )

  const { isOnGPAtHandNetwork, loading } = useIsOnGPAtHandNetwork(memberId, {
    skip:
      !(enableGPAtHandCheckForDigitalInvites && digitalInviteEnabled) &&
      (!canBookWithServiceType || !digitalInviteExtensionEnabled),
  })

  const mediumSelectorEnabled = envFlag(
    'ENABLE_GLOBAL_INVITE_MEDIUM_SELECTOR',
    false
  )

  const shouldShowMediumSelector =
    mediumSelectorEnabled &&
    (enableGPAtHandCheckForDigitalInvites ? isOnGPAtHandNetwork : true)

  if (loading) {
    return <Spinner size="large" centered />
  }

  if (digitalInviteEnabled) {
    return (
      <DigitalAppointmentForm
        memberId={memberId}
        mediumSelectorEnabled={shouldShowMediumSelector}
      />
    )
  }

  if (!canBookWithServiceType) {
    history.push(`/admin/patients/${memberId}/schedule-appointment`)
  }

  const skipCreateLink = `/admin/patients/${memberId}/schedule-appointment`

  const skipCreate = () => {
    history.push(skipCreateLink)
  }

  const updateFilters = (newFilters: AppointmentFields) => {
    history.push(`/admin/patients/${memberId}/schedule-appointment`, {
      filters: newFilters,
    })
  }

  if (isOnGPAtHandNetwork) {
    return <ArrangeAppointment skipLink={skipCreateLink} />
  }

  return (
    <CreateAppointment
      filters={filters}
      memberId={memberId}
      onFiltersChange={updateFilters}
      onSkip={skipCreate}
    />
  )
}

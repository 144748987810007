type Dict<T> = {
  [key: string]: T | null | undefined
}

export type TrackingServiceProps = {
  app: string
  appName: string
  appVersion: string
  env: string
  countryIsoCode: string
}

export type TrackingService = TrackingServiceProps & {
  trackView: (payload: TrackingEventProps) => void
  trackEvent: (payload: TrackingEventProps) => void
}

export type TrackProps = {
  appointmentId?: string
  membershipId?: string
  patientId?: string
  elementType?: TrackingElementType
  elementName?: string
  screenTitle?: string
  moduleName?: string
  linkDestination?: string
  actionType: TrackingActionType
  eventLabel?: string
  eventAction?: string
  eventCategory?: string
  tealiumEvent?: TrackingActionType
}

export type TrackingEventProps = {
  appointment_id?: string
  membership_id?: string
  patient_id?: string
  screen_title?: string
  element_id?: string
  element_name?: string
  uuid?: string
} & Dict<string>

export enum TrackingActionType {
  pageRedirected = 'Page Redirected',
  pageLoaded = 'Page loaded',
  cardLoaded = 'Card loaded',
  popupLoaded = 'Popup loaded',
  formSubmitted = 'Form submitted',
  formError = 'Form submitted error',
  formSuccess = 'Form submitted success',
  click = 'click',
}

export enum TrackingElementType {
  button = 'button',
  form = 'form',
  link = 'link',
  page = 'page',
  card = 'card',
  popup = 'popup',
  dropdown = 'dropdown',
}

export type ClickEventPayload = {
  elementName: string
  elementType: TrackingElementType
  href?: string
}

import { useQuery, QueryHookOptions } from '@apollo/client'

import { CONSUMER_NETWORKS_QUERY } from './getPatientConsumerNetworkQuery'

interface useIsOnGPAtHandNetworkReturnProps {
  isOnGPAtHandNetwork: boolean
  loading: boolean
}

interface patientConsumerNetworksType {
  is_preferred: boolean
  active: boolean
  gp_at_hand: boolean
}

export default function useIsOnGPAtHandNetwork(
  memberId: string,
  options?: Pick<QueryHookOptions, 'skip'>
): useIsOnGPAtHandNetworkReturnProps {
  const { data, loading } = useQuery(CONSUMER_NETWORKS_QUERY, {
    variables: {
      patientId: memberId,
    },
    skip: options?.skip,
  })

  const isOnGPAtHandNetwork = data?.patientConsumerNetworks?.find(
    (consumerNetwork: patientConsumerNetworksType) =>
      consumerNetwork.is_preferred &&
      consumerNetwork.active &&
      consumerNetwork.gp_at_hand
  )

  return { isOnGPAtHandNetwork: !!isOnGPAtHandNetwork, loading }
}

import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
import cx from 'classnames'
import axios, { AxiosResponse } from 'axios'

import { envUrl } from '@babylon/babylon-env'
import RequestIdGenerator from '@babylon/request-id-generator'
import { LinkButton } from '@babylon/medkit'
import Cookies from 'js-cookie'
import messages from './messages'
import styles from './StyledDropzone.module.scss'

const UploadStatus = {
  Success: 'Success',
  Error: 'Error',
  Ready: 'Ready',
  InProgress: 'InProgress',
  Rejected: 'Rejected',
} as const

type UploadStatusKeys = typeof UploadStatus[keyof typeof UploadStatus]

export type UploadResponse = {
  content: {
    content_type: string
    title: string
    url: string
  }
  created_at: string
  id: string
  identifier: {
    system: string
    value: string
  }
  patient_id: string
  status: string
  uploader: {
    type: string
    id: string
  }
}

const DOCUMENT_UPLOAD_URL = envUrl('DOCUMENT_UPLOAD_URL')

const requestIdGenerator = new RequestIdGenerator({
  app: 'document-repository',
})

export interface StyledDropzoneProps {
  memberId?: string
  memberName?: string
  onUploadSuccess?: (uploadedFile: UploadResponse) => void
}

const StyledDropzone = ({
  memberId,
  memberName,
  onUploadSuccess,
}: StyledDropzoneProps) => {
  const { formatMessage } = useIntl()
  const [uploadStatus, setUploadStatus] = useState<UploadStatusKeys>(
    UploadStatus.Ready
  )
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [isDragActive, setIsDragActive] = useState(false)

  useEffect(() => {
    if (uploadStatus === UploadStatus.Success) {
      setTimeout(() => {
        setUploadStatus(UploadStatus.Ready)
      }, 2000)
    }
  }, [uploadStatus])

  const uploadFile = async (
    file: File
  ): Promise<AxiosResponse<UploadResponse>> => {
    const formData = new FormData()
    formData.append('file', file)

    const cookie = Cookies.get('autologin_info') || '{}'
    const { csrf_token } = JSON.parse(cookie)

    const url = `${DOCUMENT_UPLOAD_URL}/${memberId}`

    return axios.post(url, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Security-Token': csrf_token,
        'babylon-request-id': requestIdGenerator.generate(),
      },
    })
  }

  const onConfirmUpload = async () => {
    setUploadStatus(UploadStatus.InProgress)

    try {
      if (!fileToUpload) {
        throw new Error('No file to upload')
      }

      const response = await uploadFile(fileToUpload)

      if (response.data) {
        onUploadSuccess?.(response.data)
      }

      setUploadStatus(UploadStatus.Success)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Document Upload Error: ${error}`)
      setUploadStatus(UploadStatus.Error)
    } finally {
      setFileToUpload(undefined)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    setIsDragActive(false)
    const file = acceptedFiles[0]

    if (!file) {
      setUploadStatus(UploadStatus.Rejected)

      return
    }

    setFileToUpload(file)
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    noClick: !!fileToUpload,
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
  }) // TODO: dynamically set accepted files based on env var

  const containerClassName = cx(
    styles.baseStyle,
    isFocused && styles.focusedStyle,
    isDragAccept && styles.acceptStyle,
    isDragReject && styles.rejectStyle,
    isDragActive && styles.activeStyle,
    uploadStatus === UploadStatus.Success && styles.successStyle,
    uploadStatus === UploadStatus.Error && styles.errorStyle
  )

  return (
    <div className={containerClassName}>
      <div
        {...getRootProps()}
        data-testid="document-repository-dropzone"
        onDragOver={() => {
          if (
            uploadStatus === UploadStatus.Error ||
            uploadStatus === UploadStatus.Rejected
          ) {
            setUploadStatus(UploadStatus.Ready)
          }

          setIsDragActive(true)
        }}
        onDragLeave={() => setIsDragActive(false)}
      >
        <input {...getInputProps()} />
        {!!fileToUpload &&
          uploadStatus === UploadStatus.Ready &&
          formatMessage(messages.upload_confirm_message, {
            fileName: <b>{fileToUpload.name}</b>,
            patientName: <b>{memberName ? ` ${memberName}` : ''}</b>,
            confirmButton: (
              <LinkButton
                className={styles.UploadLinkButton__confirmButton}
                type="button"
                onClick={onConfirmUpload}
              >
                {formatMessage(messages.confirm_button)}
              </LinkButton>
            ),
            cancelButton: (
              <LinkButton
                className={styles.UploadLinkButton}
                type="button"
                onClick={() => setFileToUpload(undefined)}
              >
                {formatMessage(messages.cancel_button)}
              </LinkButton>
            ),
          })}
        {!fileToUpload &&
          !isDragReject &&
          uploadStatus === UploadStatus.Ready &&
          formatMessage(messages.drag_and_drop)}
        {uploadStatus === UploadStatus.InProgress &&
          formatMessage(messages.upload_in_progress)}
        {uploadStatus === UploadStatus.Success &&
          formatMessage(messages.upload_success)}
        {uploadStatus === UploadStatus.Error &&
          formatMessage(messages.upload_error)}
        {(isDragReject || uploadStatus === UploadStatus.Rejected) &&
          formatMessage(messages.drag_and_drop_invalid_filetype)}
      </div>
    </div>
  )
}

export default StyledDropzone

import React, { useState, memo } from 'react'
import cx from 'classnames'
import { PortalTransaction as IPortalTransaction } from '@babylon/graphql-middleware-types'
import { useFormatMessage } from '@babylon/intl'
import { Grid, Cell } from '@babylon/core-ui'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { formatDateTimeWithTimezone } from '@babylon/date-time-formatter'
import { ViewMoreToggler, MemberOpsModuleName } from '../../../../../../..'

import { braintreeUrl } from './utils'
import styles from './Transaction.module.scss'
import messages from './Transaction.messages'
import TransactionStatus from './TransactionStatus'

interface TransactionProps extends IPortalTransaction {
  onRefund: (id: string) => void
}

export default memo(
  ({
    purchaseType,
    braintreeToken,
    braintreeStatus,
    creditCard,
    currencySymbol,
    price,
    createdAt,
    id,
    refundedAt,
    showReason,
    onRefund,
  }: TransactionProps) => {
    const { trackClick } = useTrackClick({
      moduleName: MemberOpsModuleName.profileAccountServices,
    })
    const fm = useFormatMessage()
    const { cardType, last4 } = creditCard || {}
    const createdDate = new Date(createdAt)
    const [toggled, setToggled] = useState(false)
    const symbol = currencySymbol.slice(currencySymbol.length - 1)
    const region = currencySymbol.slice(0, currencySymbol.length - 1)
    const refundStatus = () => {
      switch (braintreeStatus) {
        default:
          return (
            <TransactionStatus message={fm(messages.null)} success={false} />
          )
        case 'Submitted for settlement':
        case 'Settling':
        case 'Settled':
          return (
            <a
              href="#refund"
              onClick={() => onRefund(id)}
              data-testid="refund-cta"
            >
              {fm(messages.refund)}
            </a>
          )
        case 'Refunded':
          return <TransactionStatus message={fm(messages.refunded)} success />
        case 'Free':
          return <TransactionStatus message={fm(messages.free)} success />
        case 'Voided':
          return (
            <TransactionStatus message={fm(messages.voided)} success={false} />
          )
      }
    }
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const twelveHourFormat = userTimezone.split('/')[0] === 'America'

    const refundedAtDate = () =>
      refundedAt
        ? formatDateTimeWithTimezone(refundedAt, {
            desiredTimeZone: userTimezone,
            twelveHourFormat,
          })
        : null

    return (
      <>
        <Grid columns={9} className={styles.Transaction}>
          <Cell width={2} middle className={styles.Transaction__Cell}>
            <div
              className={styles.Transaction__bold}
              title={purchaseType || ''}
            >
              {purchaseType}
            </div>
            <a
              href={braintreeUrl(braintreeToken || '')}
              onClick={(e) => {
                trackClick({
                  elementName: 'transaction-braintree-link',
                  elementType: TrackingElementType.link,
                  href: e.currentTarget.href,
                })
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {braintreeToken}
            </a>
            )
          </Cell>
          <Cell middle className={styles.Transaction__Cell}>
            <div title={cardType || ''}>{cardType}</div>
            <div>{last4}</div>
          </Cell>
          <Cell middle className={styles.Transaction__Cell}>
            <div>{region}</div>
            <div>
              {symbol}
              {price}
            </div>
          </Cell>
          <Cell width={2} middle className={styles.Transaction__Cell}>
            {formatDateTimeWithTimezone(createdDate, {
              desiredTimeZone: userTimezone,
              twelveHourFormat,
            })}
          </Cell>
          <Cell middle width={2} className={styles.Transaction__Cell}>
            <div>{refundStatus()}</div>
          </Cell>
          <Cell middle center className={styles.Transaction__Cell}>
            <ViewMoreToggler
              open={toggled}
              testId="toggler"
              onToggle={() => {
                setToggled((state) => !state)
                trackClick({
                  elementName: 'transaction-view-more-toggle',
                  elementType: TrackingElementType.button,
                })
              }}
            />
          </Cell>
        </Grid>
        {toggled && (
          <div className={cx(styles.Transaction, styles.DropDown)}>
            <div className={styles.Transaction__Cell}>
              <span className={styles.Transaction__bold}>
                {fm(messages.refund_date)}:
              </span>{' '}
              {refundedAtDate()}
            </div>
            <div className={styles.Transaction__Cell}>
              <span className={styles.Transaction__bold}>
                {fm(messages.refund_reason)}:
              </span>{' '}
              {showReason}
            </div>
          </div>
        )}
      </>
    )
  },
  (previousProps, nextProps) =>
    previousProps.braintreeStatus === nextProps.braintreeStatus
)

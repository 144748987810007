export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  WorkflowDefinitionParentMapping: any;
  JSON: any;
  Void: any;
};


export type ActionDataPatch = {
  description?: Maybe<CarePlanValuePatchString>;
};

export type ActionDescriptionEmpty = {
  actionDraftId: Scalars['ID'];
};

export type ActionDraftDoesNotExistError = {
  actionDraftId: Scalars['ID'];
};

export type ActionWithoutGoal = {
  actionDraftId: Scalars['ID'];
};

export type ActivityLog = {
  reportParameters?: Maybe<Scalars['String']>;
};

export type AddActionDraftResult = AddActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type AddActionDraftSuccess = {
  actionDraft: CarePlanActionDraft;
};

export type AddGoalDraftSuccess = {
  goalDraft: CarePlanGoalDraft;
};

export type Address = {
  id?: Maybe<Scalars['ID']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  premises?: Maybe<Array<Maybe<Scalars['String']>>>;
  first_line?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  third_line?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  town: Scalars['String'];
  county: Scalars['String'];
  postcode: Scalars['String'];
};

export type AdminActiveIngredient = Paging & {
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  activeIngredientId: Scalars['ID'];
  name: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
};

export type AdminActiveIngredientInput = {
  activeIngredientId: Scalars['ID'];
  name: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
};

export type AdminActiveIngredients = Pagination & {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<AdminActiveIngredient>>;
};

export type AdminDrug = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<Array<Maybe<AdminActiveIngredient>>>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  drugPreparation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  drugRegions?: Maybe<Array<Maybe<AdminDrugRegionFlag>>>;
  vmpDmdId?: Maybe<Scalars['String']>;
  vtmId?: Maybe<Scalars['String']>;
  expensive?: Maybe<Scalars['Boolean']>;
  generic?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type AdminDrugAlert = Paging & {
  id: Scalars['ID'];
  activeIngredient?: Maybe<AdminActiveIngredient>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  prescriptionLimit?: Maybe<Scalars['Int']>;
  timePeriod?: Maybe<Scalars['String']>;
  allowPrescribe?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type AdminDrugAlertInput = {
  id?: Maybe<Scalars['ID']>;
  activeIngredient?: Maybe<AdminActiveIngredientInput>;
  activeIngredientId?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  prescriptionLimit?: Maybe<Scalars['Int']>;
  timePeriod?: Maybe<Scalars['String']>;
  allowPrescribe?: Maybe<Scalars['Boolean']>;
};

export type AdminDrugAlerts = Pagination & {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<AdminDrugAlert>>;
};

export type AdminDrugAudit = {
  id: Scalars['ID'];
  auditableId?: Maybe<Scalars['String']>;
  babylonRequestId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventReason?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['String']>;
  performedById?: Maybe<Scalars['String']>;
  performedByName?: Maybe<Scalars['String']>;
};

export type AdminDrugInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  activeIngredients: Array<Maybe<AdminActiveIngredientInput>>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  drugPreparation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  drugRegions: Array<Maybe<AdminDrugRegionFlagInput>>;
  vmpDmdId?: Maybe<Scalars['String']>;
  vtmId?: Maybe<Scalars['String']>;
  generic: Scalars['Boolean'];
};

export type AdminDrugPackaging = {
  id: Scalars['ID'];
  drugId: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  decimalPrice?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  legalCategory?: Maybe<Scalars['String']>;
  ampDmdId?: Maybe<Scalars['String']>;
};

export type AdminDrugRegion = {
  id: Scalars['ID'];
  regionCode: Scalars['String'];
  name: Scalars['String'];
  regionIri?: Maybe<Scalars['String']>;
};

export type AdminDrugRegionFlag = {
  id: Scalars['ID'];
  region: AdminDrugRegion;
  controlled: Scalars['Boolean'];
  blacklisted: Scalars['Boolean'];
  packaging: Array<Maybe<AdminDrugPackaging>>;
};

export type AdminDrugRegionFlagInput = {
  id?: Maybe<Scalars['ID']>;
  region: Scalars['String'];
  controlled: Scalars['Boolean'];
  blacklisted: Scalars['Boolean'];
  blacklistReason?: Maybe<Scalars['String']>;
  packaging: Array<Maybe<DrugPackagingInput>>;
  _isRemoved?: Maybe<Scalars['Boolean']>;
  _isNew?: Maybe<Scalars['Boolean']>;
};

export type AdminDrugResult = {
  totalElements: Scalars['Int'];
  items: Array<Maybe<AdminDrug>>;
};

export type Allergy = {
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type AnswerTemplate = {
  id: Scalars['ID'];
  tag: Tag;
  text: Scalars['String'];
};

export type ApplyRefundInput = {
  patientId: Scalars['ID'];
  transactionId: Scalars['ID'];
  reasonId: Scalars['ID'];
};

export enum AppName {
  BabylonUs = 'BABYLON_US'
}

export type Appointment = {
  id?: Maybe<Scalars['Int']>;
  ask_for_gp_details: Scalars['Boolean'];
  call_id?: Maybe<Scalars['Int']>;
  cancel_appointment?: Maybe<Scalars['String']>;
  checkIn?: Maybe<CheckIn>;
  clinic?: Maybe<Practice>;
  clinical_note_annotations?: Maybe<Array<Maybe<ClinicalNoteAnnotation>>>;
  completed: Scalars['Boolean'];
  consultant?: Maybe<Consultant>;
  consumer_network?: Maybe<ConsumerNetwork>;
  currency?: Maybe<CurrencyCode>;
  consultant_specialism?: Maybe<Scalars['String']>;
  diagnosis_codes?: Maybe<Array<Maybe<Read2Code>>>;
  diagnosis_notes?: Maybe<Scalars['String']>;
  drugsList?: Maybe<Array<Maybe<Drug>>>;
  examination_codes?: Maybe<Array<Maybe<Read2Code>>>;
  examination_notes?: Maybe<Scalars['String']>;
  further_action_codes?: Maybe<Array<Maybe<Read2Code>>>;
  further_action_notes?: Maybe<Scalars['String']>;
  further_information_urls?: Maybe<Array<Maybe<ConsultationFurtherInfo>>>;
  gp_consent?: Maybe<Scalars['Boolean']>;
  history_codes?: Maybe<Array<Maybe<Read2Code>>>;
  history_notes?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_full_url?: Maybe<Scalars['String']>;
  is_using_promotion: Scalars['Boolean'];
  management_codes?: Maybe<Array<Maybe<Read2Code>>>;
  management_notes?: Maybe<Scalars['String']>;
  medium?: Maybe<AppointmentMedium>;
  membership_code_message_for_gp?: Maybe<Scalars['String']>;
  next_appointment_start_time?: Maybe<Scalars['String']>;
  nlp_review?: Maybe<NlpReview>;
  original_image?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  patient_note?: Maybe<Scalars['String']>;
  pre_auth_number?: Maybe<Scalars['String']>;
  prescription_uploaded?: Maybe<Scalars['Boolean']>;
  prescriptions?: Maybe<Array<Maybe<Prescription>>>;
  prescription_id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  price_fractional?: Maybe<Scalars['Int']>;
  promo_code_message_for_gp?: Maybe<Scalars['String']>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
  sick_note?: Maybe<SickNote>;
  show_gp_consent_option: Scalars['Boolean'];
  skip_plans_screen_go_to_payment?: Maybe<Scalars['Boolean']>;
  slot_size?: Maybe<Scalars['Int']>;
  specialism_categories?: Maybe<Array<Maybe<SpecialismCategory>>>;
  state?: Maybe<AppointmentBookingStatus>;
  time?: Maybe<Scalars['String']>;
  review?: Maybe<Review>;
  is_rated?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<Consultation>;
  /** @deprecated Field no longer supported */
  member_id?: Maybe<Scalars['ID']>;
  babylon_id?: Maybe<Scalars['String']>;
  recording_consent?: Maybe<Scalars['Boolean']>;
  consumer_network_id?: Maybe<Scalars['Int']>;
  call_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  duration?: Maybe<Scalars['Int']>;
  language_requested?: Maybe<Scalars['String']>;
  service_type_uuid?: Maybe<Scalars['ID']>;
  receipt?: Maybe<Receipt>;
  length_in_minutes?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  multimedia?: Maybe<Multimedia>;
  timezone_id?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  timezone?: Maybe<Scalars['String']>;
  listing_details?: Maybe<ListingDetails>;
  service_type?: Maybe<ServiceType>;
  consultation_type?: Maybe<AppointmentMedium>;
  duration_minutes: Scalars['Int'];
  booking_type?: Maybe<Scalars['String']>;
};

export type AppointmentAvailabilitySlot = {
  id: Scalars['ID'];
  time: Scalars['Date'];
  duration_minutes: Scalars['Int'];
  slot_medium: SlotMedium;
  clinic_id?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentAvailabilitySlotAppointment>;
};

export type AppointmentAvailabilitySlotAppointment = {
  id: Scalars['ID'];
  medium: AppointmentMedium;
  image_attached?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  language_requested?: Maybe<Scalars['String']>;
  status?: Maybe<ConsultationStatus>;
  consumer_network?: Maybe<ConsumerNetwork>;
  patient?: Maybe<Patient>;
};

export type AppointmentAvailabilitySlotsFilter = {
  consultantUUID?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  clientCompatibleFlows?: Maybe<Scalars['String']>;
};

export enum AppointmentBookingStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  TimedOut = 'timed_out',
  NoShow = 'no_show',
  Paid = 'paid',
  Completed = 'completed'
}

export type AppointmentCategory = {
  uuid: Scalars['String'];
  key: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  keywords?: Maybe<Array<Scalars['String']>>;
  highlighted?: Maybe<Scalars['Boolean']>;
  icon_url?: Maybe<Scalars['String']>;
  consultation_types: Array<AppointmentMedium>;
  order?: Maybe<Scalars['Int']>;
  service_types: Array<ServiceType>;
  service_type_uuids: Array<Scalars['String']>;
};

export type AppointmentCategoryDetailsInput = {
  uuid: Scalars['String'];
  key: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  icon_url: Scalars['String'];
  consultation_types: Array<AppointmentMediumForConfigCategory>;
  serviceTypes: Array<ServiceTypeForConfigCategory>;
};

export type AppointmentConfigurations = {
  appointment_configurations: Array<Maybe<ConfigurationTenancies>>;
};

export type AppointmentCredit = {
  id: Scalars['ID'];
  is_redeemed?: Maybe<Scalars['Boolean']>;
  consumer_network_id?: Maybe<Scalars['String']>;
  consumer_network_name?: Maybe<Scalars['String']>;
  reason_id?: Maybe<Scalars['String']>;
  show_reason?: Maybe<Scalars['String']>;
  professions?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<PortalUser>;
};

export type AppointmentCreditReason = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export enum AppointmentFeatures {
  ServiceTypeBooking = 'SERVICE_TYPE_BOOKING'
}

export enum AppointmentFeaturesParams {
  ServiceTypeBooking = 'service_type_booking'
}

export type AppointmentInvite = {
  id: Scalars['ID'];
  member_uuid: Scalars['ID'];
  member?: Maybe<Patient>;
  /** @deprecated Field no longer supported */
  service_type_uuid: Scalars['ID'];
  nullable_service_type_uuid?: Maybe<Scalars['ID']>;
  duration_minutes: Scalars['Int'];
  consultation_type: Scalars['String'];
  created_at: Scalars['String'];
  earliest_booking_date: Scalars['String'];
  recurrence?: Maybe<Recurrence>;
  booking_agent_uuid: Scalars['String'];
  notes_for_member?: Maybe<Scalars['String']>;
  service_type?: Maybe<ServiceType>;
  intimate: Scalars['Boolean'];
  appointment_id?: Maybe<Scalars['ID']>;
  preferred_medium?: Maybe<Scalars['String']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_profession?: Maybe<Scalars['String']>;
  allowed_professions?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_profession_name?: Maybe<Scalars['String']>;
  consultant_uuid?: Maybe<Scalars['String']>;
  group_correlation_id?: Maybe<Scalars['String']>;
  consultant?: Maybe<Consultant>;
  associated_appointments?: Maybe<Array<Appointment>>;
  expired?: Maybe<Scalars['Boolean']>;
};

export enum AppointmentInviteFeatures {
  CreateInviteWithBookings = 'create_invite_with_bookings'
}

export type AppointmentInviteFilter = {
  source_appointment_id?: Maybe<Scalars['ID']>;
  member_uuid?: Maybe<Scalars['ID']>;
  including_digital?: Maybe<Scalars['Boolean']>;
  unused?: Maybe<Scalars['Boolean']>;
};

export type AppointmentInviteInput = {
  member_uuid: Scalars['ID'];
  service_type_uuid?: Maybe<Scalars['ID']>;
  source_appointment_id?: Maybe<Scalars['ID']>;
  consultation_type?: Maybe<Scalars['String']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_medium?: Maybe<Scalars['String']>;
  earliest_booking_date: Scalars['String'];
  recurrence?: Maybe<RecurrenceInput>;
  notes_for_member: Scalars['String'];
  duration_minutes?: Maybe<Scalars['Int']>;
  intimate: Scalars['Boolean'];
  preferred_profession?: Maybe<Scalars['String']>;
  allowed_professions?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultant_uuid?: Maybe<Scalars['String']>;
  preferred_date_time?: Maybe<Scalars['Date']>;
  timezone_id?: Maybe<Scalars['String']>;
  appointment_invite_features?: Maybe<Array<AppointmentInviteFeatures>>;
};

export enum AppointmentMedium {
  Voice = 'voice',
  Video = 'video',
  Physical = 'physical',
  Text = 'text'
}

export enum AppointmentMediumForConfigCategory {
  Voice = 'voice',
  Video = 'video',
  Physical = 'physical',
  Text = 'text'
}

export type AppointmentRequestInput = {
  image?: Maybe<Scalars['String']>;
  image_filename?: Maybe<Scalars['String']>;
  medium: AppointmentMedium;
  patient_id: Scalars['Int'];
  patient_note?: Maybe<Scalars['String']>;
  preferred_date_time?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  specialist_id?: Maybe<Scalars['Int']>;
};

export type AppointmentRequestInputForMicroservice = {
  appointment_features?: Maybe<Array<Maybe<AppointmentFeaturesParams>>>;
  appointment_invite_id?: Maybe<Scalars['String']>;
  appointment_reason?: Maybe<Scalars['String']>;
  chaperone_gender?: Maybe<Scalars['String']>;
  chaperone_reason?: Maybe<Scalars['String']>;
  chaperone_required?: Maybe<Scalars['Boolean']>;
  clinic_id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['String']>;
  consultant_type?: Maybe<Scalars['String']>;
  consultation_type?: Maybe<AppointmentMedium>;
  timezone_id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  member_id: Scalars['Int'];
  preferred_date_time?: Maybe<Scalars['String']>;
  recording_consent?: Maybe<Scalars['Boolean']>;
  duration_minutes?: Maybe<Scalars['Int']>;
  language_requested?: Maybe<Scalars['String']>;
  service_type_uuid?: Maybe<Scalars['ID']>;
  service_types?: Maybe<Array<Maybe<Scalars['ID']>>>;
  needs_payment?: Maybe<Scalars['Boolean']>;
  client_compatible_flows?: Maybe<Array<ClientCompatibleFlows>>;
};

export type AppointmentSlot = {
  time?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  clinic_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  consultants_availability?: Maybe<Array<Maybe<ConsultantAvailability>>>;
};

export type AppointmentSlots = {
  appointment_slots?: Maybe<Array<Maybe<AppointmentSlot>>>;
};

export type AppointmentSlotsRequest = {
  memberId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  consultationType?: Maybe<AppointmentMedium>;
  consultantType?: Maybe<Scalars['String']>;
  consultantId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  serviceTypeUuid?: Maybe<Scalars['ID']>;
  availableOnly?: Maybe<Scalars['Boolean']>;
  include?: Maybe<Scalars['String']>;
  appointmentFeatures?: Maybe<AppointmentFeatures>;
};

export type AppointmentV2 = {
  id?: Maybe<Scalars['Int']>;
  member_id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['ID']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
  expected_consultant_profession_name?: Maybe<Scalars['String']>;
  state?: Maybe<AppointmentBookingStatus>;
  consultation_type?: Maybe<AppointmentMedium>;
  appointment_time?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  paid_at?: Maybe<Scalars['Date']>;
  timezone_id?: Maybe<Scalars['String']>;
  duration_minutes: Scalars['Int'];
  appointment_reason?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  confirmed?: Maybe<Scalars['Boolean']>;
  refundable?: Maybe<Scalars['Boolean']>;
  image_attached?: Maybe<Scalars['Boolean']>;
  gp_consent?: Maybe<Scalars['Boolean']>;
  recording_consent?: Maybe<Scalars['Boolean']>;
  booking_type?: Maybe<Scalars['String']>;
  tenancy_contract_id?: Maybe<Scalars['ID']>;
  tenancy_plan_id?: Maybe<Scalars['ID']>;
  tenancy_partner_id?: Maybe<Scalars['ID']>;
  tenancy_region_id?: Maybe<Scalars['ID']>;
};

export type AppointmentWaitTime = {
  medium: Scalars['String'];
  status: Scalars['Int'];
  nextAppointmentTimes: Array<Scalars['String']>;
  consumerNetwork: ConsumerNetwork;
  serviceType: ServiceType;
  timeUpdated: Scalars['String'];
};

export type AppointmentWaitTimeConfig = {
  consumerNetwork: ConsumerNetwork;
  serviceType?: Maybe<ServiceType>;
  waitTimeStatusThresholds: Array<AppointmentWaitTimeThreshold>;
};

export type AppointmentWaitTimeConfigs = {
  waitTimeConfigs: Array<AppointmentWaitTimeConfig>;
  total: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type AppointmentWaitTimeConfigsInput = {
  consumer_network_id: Scalars['Int'];
  service_type_uuid?: Maybe<Scalars['String']>;
  wait_time_status_thresholds: Array<AppointmentWaitTimeThresholdInput>;
};

export type AppointmentWaitTimes = {
  waitTimes: Array<AppointmentWaitTime>;
  total: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export enum AppointmentWaitTimeStatusType {
  Critical = 'critical',
  Overloaded = 'overloaded',
  Busy = 'busy'
}

export type AppointmentWaitTimeThreshold = {
  type: Scalars['String'];
  value: Scalars['Int'];
};

export type AppointmentWaitTimeThresholdInput = {
  type: AppointmentWaitTimeStatusType;
  value: Scalars['Int'];
};

export type AssociateGoalAndActionDraftResult = AssociateGoalAndActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | ActionDraftDoesNotExistError | GoalActionDraftAssociationAlreadyExistError | CarePlanAuthorizationError;

export type AssociateGoalAndActionDraftSuccess = {
  goalDraft: CarePlanGoalDraft;
  actionDraft: CarePlanActionDraft;
};

export type Author = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  portal_loginable_type?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type AvailabilitySlot = {
  id: Scalars['ID'];
  slot_time: Scalars['String'];
  slot_size: Scalars['Int'];
  physical: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  digital_bookable: Scalars['Boolean'];
  physical_bookable: Scalars['Boolean'];
};

export type AvailabilitySlots = {
  availability_slots: Array<AvailabilitySlot>;
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type AvailabilitySlotsInput = {
  clinicianId: Scalars['ID'];
  slotTimes: Array<Scalars['Date']>;
  slotsSize: Scalars['Int'];
  digitalBookable?: Maybe<Scalars['Boolean']>;
  physicalBookable?: Maybe<Scalars['Boolean']>;
  textBookable?: Maybe<Scalars['Boolean']>;
};

export type AvailabilitySlotsRequest = {
  clinician_id?: Maybe<Array<Scalars['ID']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  slot_size?: Maybe<Array<Scalars['Int']>>;
  slot_time_from: Scalars['String'];
  slot_time_to: Scalars['String'];
};

export type BirthDetailInput = {
  country: Scalars['String'];
  town: Scalars['String'];
};

export type BirthDetails = {
  country: Scalars['String'];
  town: Scalars['String'];
};

export type BloodTest = TimelineEventInterface & {
  id: Scalars['ID'];
  url: Scalars['String'];
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export type BookingRequestResult = {
  appointment?: Maybe<Appointment>;
  availability?: Maybe<Array<Maybe<ClinicianAvailability>>>;
};

export type BookingRestricted = {
  duration_unit?: Maybe<Scalars['String']>;
  duration_value?: Maybe<Scalars['Int']>;
};

export type BookingRestrictedInput = {
  duration_unit?: Maybe<Scalars['String']>;
  duration_value?: Maybe<Scalars['Int']>;
};

export enum BookingType {
  Fixed = 'fixed',
  Rtm = 'rtm',
  Queued = 'queued'
}

export type Call = {
  id?: Maybe<Scalars['ID']>;
  expiry?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CallFlowStatus = {
  call_flow_steps: Array<Maybe<CallFlowStepItem>>;
  missed_by?: Maybe<MultimediaFeedbackClientType>;
};

export type CallFlowStepItem = {
  appointment_id: Scalars['ID'];
  flow_step_type: CallStep;
  app_name: Scalars['String'];
  app_version: Scalars['String'];
  platform_name: Scalars['String'];
  platform_version: Scalars['String'];
  triggered_at: Scalars['Date'];
  user_agent: Scalars['String'];
  duration_in_ms?: Maybe<Scalars['Int']>;
};

export enum CallStep {
  CallStarted = 'call_started',
  MultimediaCallServiceInvoked = 'multimedia_call_service_invoked',
  NotificationAccepted = 'notification_accepted',
  NotificationReceived = 'notification_received',
  NotificationsServiceInvoked = 'notifications_service_invoked',
  TimerStarted = 'timer_started',
  TwilioVoiceCallAnswered = 'twilio_voice_call_answered',
  TwilioVoiceCallBusy = 'twilio_voice_call_busy',
  TwilioVoiceCallCancelled = 'twilio_voice_call_cancelled',
  TwilioVoiceCallCompleted = 'twilio_voice_call_completed',
  TwilioVoiceCallFailed = 'twilio_voice_call_failed',
  TwilioVoiceCallInitiated = 'twilio_voice_call_initiated',
  TwilioVoiceCallNoAnswer = 'twilio_voice_call_no_answer',
  TwilioVoiceCallRinging = 'twilio_voice_call_ringing',
  VendorNotificationsProviderInvoked = 'vendor_notifications_provider_invoked',
  VideoCallEndedByClinician = 'video_call_ended_by_clinician',
  VideoCallEndedByPatient = 'video_call_ended_by_patient',
  VideoCallRejectedByPatient = 'video_call_rejected_by_patient',
  VoiceCallEndedByClinician = 'voice_call_ended_by_clinician',
  VoiceCallStarted = 'voice_call_started'
}

export type CancelledAppointment = {
  id?: Maybe<Scalars['Int']>;
};

export type CancelReason = {
  id: Scalars['ID'];
  cancel_reason_type?: Maybe<Scalars['String']>;
  consultant_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  show_reason?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type CarePlan = {
  id: Scalars['ID'];
  entityCreatedAt: Scalars['String'];
  revisionCreatedAt: Scalars['String'];
  revisionId: Scalars['String'];
  goals: Array<CarePlanGoal>;
  actions: Array<CarePlanAction>;
  goalActionAssociations: Array<CarePlanGoalActionAssociation>;
};

export type CarePlanAction = {
  id: Scalars['ID'];
  description: Scalars['String'];
  entityCreatedAt: Scalars['String'];
  revisionCreatedAt: Scalars['String'];
};

export type CarePlanActionDraft = {
  draftId: Scalars['ID'];
  description: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  parentCarePlanDraft?: Maybe<CarePlanDraft>;
};

export type CarePlanAddActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  actionDraftId?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
};

export type CarePlanAddGoalDraftData = {
  addGoalDraft: CarePlanAddGoalDraftResult;
};

export type CarePlanAddGoalDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  description: Scalars['String'];
};

export type CarePlanAddGoalDraftResult = AddGoalDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanAddGoalDraftVariables = {
  input?: Maybe<CarePlanAddGoalDraftInput>;
};

export type CarePlanAssociateGoalAndActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export enum CarePlanAuthorizationAction {
  Create = 'CREATE',
  Read = 'READ',
  Update = 'UPDATE',
  Delete = 'DELETE',
  Publish = 'PUBLISH'
}

export type CarePlanAuthorizationError = {
  action: CarePlanAuthorizationAction;
  resource: CarePlanAuthorizationResource;
};

export enum CarePlanAuthorizationResource {
  CarePlan = 'CARE_PLAN',
  CarePlanDraft = 'CARE_PLAN_DRAFT'
}

export type CarePlanDeleteActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  actionDraftId: Scalars['ID'];
};

export type CarePlanDeleteActionDraftResult = DeleteActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | ActionDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanDeleteGoalDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
};

export type CarePlanDeleteGoalDraftResult = DeleteGoalDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanDissociateGoalAndActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type CarePlanDissociateGoalAndActionDraftResult = DissociateGoalAndActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | ActionDraftDoesNotExistError | GoalActionDraftAssociationDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanDraft = {
  draftId: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  upstreamRevisionId: Scalars['String'];
  goals: Array<CarePlanGoalDraft>;
  actions: Array<CarePlanActionDraft>;
  goalActionAssociations: Array<CarePlanGoalActionAssociationDraft>;
  metadata: DraftMetadata;
};

export type CarePlanDraftContext = {
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
  carePlanDraftId: Scalars['ID'];
  numberOfEdits: Scalars['Int'];
};

export type CarePlanDraftDoesNotExistError = {
  draftId: Scalars['ID'];
};

export type CarePlanDraftResult = CarePlanDraft | None | CarePlanAuthorizationError;

export type CarePlanDraftWithoutGoalsAndActions = {
  _?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEditActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  actionDraftId: Scalars['ID'];
  actionDataPatch: ActionDataPatch;
};

export type CarePlanEditActionDraftResult = EditActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanEditGoalDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
  goalDataPatch: CarePlanGoalDataPatch;
};

export type CarePlanEditGoalDraftResult = EditGoalDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanGoal = {
  id: Scalars['ID'];
  description: Scalars['String'];
  entityCreatedAt: Scalars['String'];
  revisionCreatedAt: Scalars['String'];
  associatedActions: Array<CarePlanAction>;
};

export type CarePlanGoalActionAssociation = {
  actionId: Scalars['ID'];
  goalId: Scalars['ID'];
  createdAt: Scalars['String'];
};

export type CarePlanGoalActionAssociationDraft = {
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type CarePlanGoalDataPatch = {
  description: CarePlanValuePatchString;
};

export type CarePlanGoalDraft = {
  draftId: Scalars['ID'];
  description: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  associatedActionDrafts?: Maybe<Array<CarePlanActionDraft>>;
  parentCarePlanDraft?: Maybe<CarePlanDraft>;
};

export type CarePlanPublishedInConsultationResult = CarePlan | None | CarePlanAuthorizationError;

export type CarePlanResult = CarePlan | None | CarePlanAuthorizationError;

export type CarePlanValuePatchString = {
  newValue: Scalars['String'];
};

export type Carer = {
  id: Scalars['ID'];
  type: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  profile: Profile;
};

export type CategoryServiceTypeMapping = {
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  serviceTypes?: Maybe<Array<Maybe<ServiceTypeForConfig>>>;
  icon_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  consultation_types?: Maybe<Array<Maybe<AppointmentMedium>>>;
};

export type ChatacriptMessageInputParamsChoice = {
  id: Scalars['String'];
  label: Scalars['String'];
  multiselect?: Maybe<Scalars['Boolean']>;
  preselected?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type ChatscriptConversation = TimelineEventInterface & {
  id: Scalars['ID'];
  preview?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  result?: Maybe<ChatscriptConversationResult>;
  startedAt: Scalars['String'];
  status?: Maybe<ChatscriptConversationStatus>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
  patientUuid?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};

export enum ChatscriptConversationOutcome {
  Success = 'success',
  Failure = 'failure',
  Undefined = 'undefined'
}

export type ChatscriptConversationResult = {
  outcome?: Maybe<ChatscriptConversationOutcome>;
  type?: Maybe<Scalars['String']>;
};

export type ChatscriptConversationsResponse = {
  more?: Maybe<Scalars['Boolean']>;
  conversations: Array<ChatscriptConversation>;
};

export enum ChatscriptConversationStatus {
  Started = 'started',
  Finished = 'finished'
}

export type ChatscriptMessage = {
  id: Scalars['ID'];
  author?: Maybe<ChatscriptMessageAuthor>;
  input?: Maybe<ChatscriptMessageInput>;
  rateable?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<ChatscriptMessageRating>;
  style?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  undoable?: Maybe<Scalars['Boolean']>;
  value?: Maybe<ChatscriptMessageValue>;
  sender?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
};

export type ChatscriptMessageAuthor = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ChatscriptMessageInput = {
  type?: Maybe<Scalars['String']>;
  params?: Maybe<ChatscriptMessageInputParams>;
};

export type ChatscriptMessageInputParams = {
  choices?: Maybe<Array<ChatacriptMessageInputParamsChoice>>;
  suggest?: Maybe<Scalars['Boolean']>;
};

export type ChatscriptMessageRating = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ChatscriptMessagesResponse = {
  messages: Array<ChatscriptMessage>;
  more?: Maybe<Scalars['Boolean']>;
};

export type ChatscriptMessageValue = {
  helpText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type CheckIn = {
  check_in_flow_support?: Maybe<Scalars['String']>;
  check_in_data?: Maybe<CheckInData>;
};

export type CheckInData = {
  check_in_open_before_appointment_minutes?: Maybe<Scalars['Int']>;
  check_in_open_at?: Maybe<Scalars['String']>;
  check_in_close_at?: Maybe<Scalars['String']>;
  check_in_display_close_at?: Maybe<Scalars['String']>;
  checked_in_at?: Maybe<Scalars['String']>;
  check_in_status?: Maybe<Scalars['String']>;
};

export type CheckInDetails = {
  status: CheckInStatus;
  checkedInAt?: Maybe<Scalars['String']>;
  location?: Maybe<CheckInLocation>;
};

export type CheckInLocation = {
  useRegisteredLocation: Scalars['Boolean'];
  temporaryLocation?: Maybe<CheckInTemporaryLocation>;
};

export enum CheckInStatus {
  NotOpened = 'NOT_OPENED',
  Open = 'OPEN',
  CheckedIn = 'CHECKED_IN',
  Missed = 'MISSED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED'
}

export type CheckInTemporaryLocation = {
  countryIsoCode: Scalars['String'];
  address: Scalars['String'];
};

export enum ClientCompatibleFlows {
  CheckIn = 'CHECK_IN'
}

export type ClinicalCode = {
  code?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
};

export type ClinicalCodeInput = {
  iri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicalComissioningGroup = {
  code?: Maybe<Scalars['String']>;
  gss_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicalKnowledgeEntity = {
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  altLabels: Array<Scalars['String']>;
  semanticType?: Maybe<SemanticType>;
  definitions: Array<Scalars['String']>;
  externalOntologies: Array<ExternalOntology>;
  parents: Array<ClinicalKnowledgeEntity>;
  children: Array<ClinicalKnowledgeEntity>;
  outgoingRelations: Array<ClinicalKnowledgeRelation>;
  incomingRelations: Array<ClinicalKnowledgeRelation>;
  domains?: Maybe<Array<SemanticType>>;
  ranges?: Maybe<Array<SemanticType>>;
  events: Array<ClinicalKnowledgeProvenanceEvent>;
  type: Scalars['String'];
  legacyIri?: Maybe<Scalars['String']>;
};

export type ClinicalKnowledgeLabel = {
  id?: Maybe<Scalars['ID']>;
  iri?: Maybe<Scalars['String']>;
  suggestedLabel?: Maybe<Scalars['String']>;
  matchLabel?: Maybe<Scalars['String']>;
  matchLabelLanguage?: Maybe<Scalars['String']>;
  preferredLabel?: Maybe<Scalars['String']>;
  preferredLabelLanguage?: Maybe<Scalars['String']>;
  hasNotes?: Maybe<Scalars['Boolean']>;
};

export type ClinicalKnowledgeProvenanceEvent = {
  id: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  dataset: Scalars['String'];
  time: Scalars['Date'];
  vote: Vote;
  datasetChangeVersion: Scalars['String'];
  statement: ProvenanceEventStatement;
};

export type ClinicalKnowledgeRelation = {
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  entities: Array<ClinicalKnowledgeRelationEntity>;
};

export type ClinicalKnowledgeRelationEntity = {
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
};

export type ClinicalKnowledgeView = {
  name: Scalars['String'];
  populated: Scalars['Boolean'];
};

export type ClinicalNote = {
  iri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  furtherActionNotes?: Maybe<Scalars['String']>;
  managementNotes?: Maybe<Scalars['String']>;
  treatmentPlans?: Maybe<Array<Maybe<TreatmentPlan>>>;
  guidelines?: Maybe<Scalars['String']>;
};

export type ClinicalNoteAnnotation = {
  id?: Maybe<Scalars['ID']>;
};

export type ClinicalQueryInput = {
  preferred_date_time?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  specialist_id?: Maybe<Scalars['Int']>;
  medium?: Maybe<AppointmentMedium>;
};

export type ClinicalTerminologyAttribute = {
  iri: Scalars['String'];
  snomedCode?: Maybe<Scalars['String']>;
  preferredLabel: Scalars['String'];
};

export type ClinicalTerminologyAttributeComplete = {
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  domains: Array<ClinicalTerminologyDomain>;
  ranges: Array<ClinicalTerminologyRange>;
};

export type ClinicalTerminologyBaseEntity = {
  preferredLabel: Scalars['String'];
  iri: Scalars['String'];
  isContribution?: Maybe<Scalars['Boolean']>;
};

export type ClinicalTerminologyCardinality = {
  min: Scalars['String'];
  max: Scalars['String'];
};

export type ClinicalTerminologyContribution = {
  id: Scalars['String'];
  path: Scalars['String'];
  author: Scalars['String'];
  reviews: Array<Maybe<ContributionReview>>;
  status: Scalars['String'];
  time: Scalars['Int'];
  ticket?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ClinicalTerminologyContributionList = {
  contributions: Array<ClinicalTerminologyContribution>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
};

export type ClinicalTerminologyDomain = {
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  cardinality: ClinicalTerminologyCardinality;
};

export type ClinicalTerminologyEntity = {
  iri: Scalars['String'];
  legacyIri?: Maybe<Scalars['String']>;
  preferredLabel: Scalars['String'];
  semanticTag: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  altLabels: Array<Maybe<Scalars['String']>>;
  parents: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  children: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  outgoingRelations: Array<Maybe<ClinicalTerminologyExternalRelation>>;
  incomingRelations: Array<Maybe<ClinicalTerminologyExternalRelation>>;
  inheritedParents: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  inheritedChildren: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  code: SystemCode;
  isDefined: Scalars['Boolean'];
};

export type ClinicalTerminologyEntityBaseLabel = {
  value: Scalars['String'];
  lang: Scalars['String'];
};

export type ClinicalTerminologyEvents = {
  iri: Scalars['String'];
  events: Array<ClinicalKnowledgeProvenanceEvent>;
};

export type ClinicalTerminologyExternalRelation = {
  preferredLabel: Scalars['String'];
  iri: Scalars['String'];
  isContribution: Scalars['Boolean'];
  entities?: Maybe<Array<ClinicalTerminologyBaseEntity>>;
};

export type ClinicalTerminologyLabelsEntity = {
  iri: Scalars['String'];
  snomedCode?: Maybe<Scalars['String']>;
  preferredLabel: Scalars['String'];
  semanticTag: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  altLabels: Array<Maybe<Scalars['String']>>;
};

export type ClinicalTerminologyRange = {
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
};

export type ClinicalTerminologySearchEntity = {
  concepts: Array<Maybe<ClinicalTerminologyLabelsEntity>>;
  total: Scalars['Int'];
  pageCursor: Scalars['String'];
};

export type ClinicianAvailability = {
  appointment_id?: Maybe<Scalars['Int']>;
  available_for_booking?: Maybe<Scalars['Boolean']>;
  category_id: Scalars['Int'];
  consultant_id: Scalars['Int'];
  diff_to_preferred_time: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  priority: Scalars['Int'];
  recurrent: Scalars['Boolean'];
  slot_size: Scalars['Int'];
  supply_network_id?: Maybe<Scalars['Int']>;
  time: Scalars['String'];
};

export type ClinicianAvailabilityShift = {
  id: Scalars['ID'];
  shift_start_time: Scalars['String'];
  shift_end_time: Scalars['String'];
  shift_type: ClinicianAvailabilityShiftType;
};

export type ClinicianAvailabilityShifts = {
  items: Array<ClinicianAvailabilityShift>;
};

export enum ClinicianAvailabilityShiftType {
  Rtm = 'RTM',
  Fixed = 'FIXED',
  Hybrid = 'HYBRID'
}

export type ClinicianVisibility = {
  clinician_slots?: Maybe<Array<Maybe<ClinicianVisibilityClinicianSlots>>>;
};

export type ClinicianVisibilityClinicianSlots = {
  clinician?: Maybe<Consultant>;
  slots?: Maybe<Array<Maybe<ClinicianVisibilitySlots>>>;
};

export type ClinicianVisibilitySlot = {
  id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['ID']>;
  appointment?: Maybe<Appointment>;
  slot_time: Scalars['String'];
  slot_size: Scalars['Int'];
  physical: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  digital_bookable: Scalars['Boolean'];
  physical_bookable: Scalars['Boolean'];
  clinic_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  rtm_slot: Scalars['Boolean'];
};

export type ClinicianVisibilitySlots = {
  visibility_slots?: Maybe<Array<Maybe<ClinicianVisibilitySlot>>>;
  clinic?: Maybe<Practice>;
};

export type CloneAppointmentConfiguration = {
  name?: Maybe<Scalars['String']>;
};

export type Code = {
  id: Scalars['Int'];
  promotion?: Maybe<Promotion>;
  membership?: Maybe<Membership>;
};

export type CodeAndDisplayInput = {
  code: Scalars['String'];
  display: Scalars['String'];
};

export type CodeTranslation = {
  name: Scalars['String'];
  valueCoding: ValueCoding;
};

export type Comment = Paging & {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Author>;
  type?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
};

export type Comments = Pagination & {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<Comment>>;
};

export type Concept = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};

export type Condition = {
  id?: Maybe<Scalars['ID']>;
  info?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
};

export type ConfigurationByContract = {
  contractId?: Maybe<Scalars['ID']>;
  configuration?: Maybe<Configurations>;
};

export type ConfigurationDetailsType = {
  serviceTypesForConfiguration?: Maybe<Array<Maybe<ServiceTypeForConfig>>>;
  categoryServiceTypeMapping?: Maybe<Array<Maybe<CategoryServiceTypeMapping>>>;
};

export type Configurations = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  medium_configuration?: Maybe<Array<Maybe<Medium>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  service_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['String']>;
};

export type ConfigurationTenancies = {
  configuration: Configurations;
  tenancies: Array<Maybe<Tenancies>>;
};

export type Consultant = Subject & {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  avatar?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  full_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  digital_signature_file_name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  bupa_provider_number?: Maybe<Scalars['String']>;
  /** @deprecated Use `avatar_expiring_url`. */
  avatar_full_url?: Maybe<Scalars['String']>;
  avatar_expiring_url?: Maybe<Scalars['String']>;
  nhs?: Maybe<Scalars['Boolean']>;
  profession_name?: Maybe<Scalars['String']>;
  country_of_residence?: Maybe<Scalars['String']>;
  medical_identifier?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  rating?: Maybe<ConsultantRating>;
  supply_networks?: Maybe<Array<Maybe<SupplyNetwork>>>;
  license_number?: Maybe<Scalars['String']>;
  specialism?: Maybe<Scalars['String']>;
};

export type ConsultantAvailability = {
  consultant_id?: Maybe<Scalars['ID']>;
  consultant?: Maybe<Consultant>;
  available?: Maybe<Scalars['Boolean']>;
  clinic_id?: Maybe<Scalars['Int']>;
};

export type ConsultantRating = {
  average_rating?: Maybe<Scalars['Float']>;
  number_of_ratings?: Maybe<Scalars['Int']>;
};

export type ConsultantRtmLastRequest = {
  status: ConsultantRtmRequestStatus;
  assignedAppointmentId?: Maybe<Scalars['ID']>;
};

export enum ConsultantRtmRequestStatus {
  New = 'NEW',
  Assigned = 'ASSIGNED',
  Failed = 'FAILED',
  TimedOut = 'TIMED_OUT',
  Cancelled = 'CANCELLED',
  NoShow = 'NO_SHOW',
  Completed = 'COMPLETED'
}

export type ConsultantRtmStatus = {
  canRequestNextPatient: Scalars['Boolean'];
  available: Scalars['Boolean'];
  lastRequest?: Maybe<ConsultantRtmLastRequest>;
};

export type ConsultantSearchFilters = {
  id?: Maybe<Scalars['ID']>;
  full_name?: Maybe<Scalars['String']>;
};

export type Consultation = Paging & TimelineEventInterface & {
  id: Scalars['ID'];
  consultationId: Scalars['ID'];
  consultationType: Scalars['String'];
  createdAt: Scalars['String'];
  consultationNote?: Maybe<ConsultationNote>;
  usEhrConsultation?: Maybe<UsEhrConsultation>;
  usEhrNote?: Maybe<UsEhrNote>;
  furtherInfo?: Maybe<Array<Maybe<ConsultationFurtherInfo>>>;
  patientId: Scalars['ID'];
  patient: Patient;
  carePlan?: Maybe<CarePlan>;
  patientV2: PatientResult;
  patientNote?: Maybe<Scalars['String']>;
  consultantId?: Maybe<Scalars['ID']>;
  consultant?: Maybe<Consultant>;
  scheduledTime: Scalars['String'];
  /** @deprecated Field no longer supported */
  consumerNetwork: Scalars['Int'];
  consumerNetworkId: Scalars['Int'];
  consumerNetworkName?: Maybe<Scalars['String']>;
  tenancyContractId?: Maybe<Scalars['ID']>;
  consumerNetworkV2: ConsumerNetworkResult;
  region?: Maybe<Region>;
  files: Array<Maybe<Scalars['String']>>;
  status: Scalars['String'];
  statusV2: ConsultationStatusV2;
  /** @deprecated Field no longer supported */
  images: Array<Scalars['String']>;
  imagesV2: ConsultationImagesResult;
  hasPatientImage?: Maybe<Scalars['Boolean']>;
  consultationFiles?: Maybe<Array<Maybe<ConsultationFile>>>;
  permissions?: Maybe<Array<ConsultationPermission>>;
  drugDosageOptions?: Maybe<Array<Maybe<DrugDosage>>>;
  medium: Scalars['String'];
  coreAppointmentId?: Maybe<Scalars['Int']>;
  appointment?: Maybe<Appointment>;
  appointmentInvites?: Maybe<Array<AppointmentInvite>>;
  cancelledAt?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Reason>>>;
  category?: Maybe<Scalars['String']>;
  preConsultationFlowId?: Maybe<Scalars['String']>;
  bookingType: BookingType;
  promoCodeMessageForGp?: Maybe<Scalars['String']>;
  membershipCodeMessageForGp?: Maybe<Scalars['String']>;
  diagnosticTests: Array<ConsultationTest>;
  pathologyTests: Array<ConsultationTest>;
  prescriptions: Array<Prescription>;
  referrals?: Maybe<Array<Maybe<ConsultationReferral>>>;
  sickNotes: Array<ConsultationSickNote>;
  workflows?: Maybe<Array<Workflow>>;
  workflowsV2?: Maybe<Array<WorkflowV2>>;
  workflowDefinitions?: Maybe<Array<WorkflowDefinition>>;
  gpConsent?: Maybe<Scalars['Boolean']>;
  sentToGp?: Maybe<Scalars['Boolean']>;
  physicalAppointmentAdvised?: Maybe<Scalars['Boolean']>;
  testResults?: Maybe<Array<Maybe<ConsultationTestResult>>>;
  recordingConsent?: Maybe<Scalars['String']>;
  languageRequested?: Maybe<Scalars['String']>;
  audioSession?: Maybe<ConsultationAudioSession>;
  videoSession?: Maybe<ConsultationVideoSession>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
  hasInsuranceClaim?: Maybe<Scalars['Boolean']>;
  insuranceClaim?: Maybe<InsuranceClaim>;
  participants: Array<Participant>;
};


export type ConsultationWorkflowDefinitionsArgs = {
  locale?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export type ConsultationAudioSession = {
  token?: Maybe<Scalars['String']>;
  encryptedVoiceParams?: Maybe<Scalars['String']>;
  to_number?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
};

export type ConsultationConsultantSearchCriteria = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ConsultationDiagnosis = {
  asessment?: Maybe<Scalars['String']>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};

export type ConsultationDoesNotExistError = {
  consultationId: Scalars['ID'];
};

export type ConsultationFile = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<Scalars['String']>;
  uploaded_at?: Maybe<Scalars['String']>;
};

export type ConsultationFurtherInfo = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ConsultationHistory = {
  consultation: Consultation;
  notes?: Maybe<Array<Maybe<ConsultationNote>>>;
  diagnosis?: Maybe<ConsultationDiagnosis>;
};

export type ConsultationImages = {
  items: Array<Scalars['String']>;
};

export type ConsultationImagesResult = ConsultationImages | RetrievalError;

export type ConsultationInfo = {
  date: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
};

export type ConsultationInput = {
  gpConsent?: Maybe<Scalars['Boolean']>;
  sentToGp?: Maybe<Scalars['Boolean']>;
  physicalAppointmentAdvised?: Maybe<Scalars['Boolean']>;
};

export type ConsultationNote = {
  id: Scalars['ID'];
  toldByPatient?: Maybe<Scalars['String']>;
  toldByPatientCodes?: Maybe<Array<ConsultationNoteCode>>;
  exploredByGp?: Maybe<Scalars['String']>;
  exploredByGpCodes?: Maybe<Array<ConsultationNoteCode>>;
  assessment?: Maybe<Scalars['String']>;
  assessmentCodes?: Maybe<Array<ConsultationNoteCode>>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};

export type ConsultationNoteCode = {
  code: Scalars['String'];
  term: Scalars['String'];
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ConsultationNoteCodeInput = {
  code: Scalars['String'];
  term: Scalars['String'];
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ConsultationNoteInput = {
  toldByPatient?: Maybe<Scalars['String']>;
  toldByPatientCodes?: Maybe<Array<Maybe<ConsultationNoteCodeInput>>>;
  exploredByGp?: Maybe<Scalars['String']>;
  exploredByGpCodes?: Maybe<Array<Maybe<ConsultationNoteCodeInput>>>;
  assessment?: Maybe<Scalars['String']>;
  assessmentCodes?: Maybe<Array<Maybe<ConsultationNoteCodeInput>>>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};

export type ConsultationPatientSearchCriteria = {
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gp_address_present?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Gender>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ConsultationPermission = {
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type ConsultationReferral = Paging & {
  id: Scalars['ID'];
  consultationId?: Maybe<Scalars['ID']>;
  specialismId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['String']>;
  referralPdf?: Maybe<Scalars['String']>;
  specialismName?: Maybe<Scalars['String']>;
  specialismCategoryId?: Maybe<Scalars['ID']>;
  externalSpecialist?: Maybe<ReferralExternalSpecialist>;
  /** @deprecated unused field */
  fax?: Maybe<ReferralFax>;
  comments?: Maybe<Array<Maybe<ReferralComment>>>;
  urgent?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<Patient>;
  consultant?: Maybe<Consultant>;
};

export type ConsultationReferralInput = {
  specialismId?: Maybe<Scalars['ID']>;
  externalSpecialist?: Maybe<ReferralExternalSpecialistInput>;
  fax?: Maybe<ReferralFaxInput>;
  comments?: Maybe<ReferralCommentsInput>;
  urgent?: Maybe<Scalars['Boolean']>;
};

export type ConsultationResults = Pagination & {
  pageIndex: Scalars['Int'];
  totalItems: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
  items: Array<Consultation>;
  showBroadWarning?: Maybe<Scalars['String']>;
};

export type ConsultationSearchCriteria = {
  consultationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateRange?: Maybe<DateRange>;
  status?: Maybe<ConsultationStatus>;
  statuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  gpConsent?: Maybe<Scalars['Boolean']>;
  markedAsSent?: Maybe<Scalars['Boolean']>;
  consumerNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  patientSearchCriteria?: Maybe<ConsultationPatientSearchCriteria>;
  consultantSearchCriteria?: Maybe<ConsultationConsultantSearchCriteria>;
};

export type ConsultationSickNote = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type ConsultationSickNoteInput = {
  reason: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type ConsultationSortCriteria = {
  field?: Maybe<ConsultationSortField>;
  order?: Maybe<SortOrder>;
};

export enum ConsultationSortField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Time = 'time',
  ConsumerNetwork = 'consumerNetwork',
  PracticeName = 'practiceName',
  PatientsGpAddressPresent = 'patientsGpAddressPresent'
}

export enum ConsultationStatus {
  Created = 'created',
  Completed = 'completed',
  Complete = 'complete',
  Confirmed = 'confirmed',
  NoShow = 'no_show',
  Cancelled = 'cancelled',
  Paid = 'paid'
}

export enum ConsultationStatusV2 {
  Created = 'created',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  Completed = 'completed',
  NoShow = 'no_show'
}

export type ConsultationTest = {
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  testId: Scalars['String'];
  reason: Scalars['String'];
  created_at?: Maybe<Scalars['String']>;
};

export type ConsultationTestInput = {
  id?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  testId: Scalars['ID'];
  reason: Scalars['String'];
};

export type ConsultationTestResult = {
  id: Scalars['ID'];
  appointmentPathologyTestId: Scalars['ID'];
  createdAt: Scalars['Date'];
  url: Scalars['String'];
  thumbUrl?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ConsultationTestType {
  Diagnostic = 'diagnostic',
  Pathology = 'pathology'
}

export type ConsultationVideoSession = {
  id: Scalars['ID'];
  sessionId: Scalars['String'];
  consultationId: Scalars['ID'];
  provider: Scalars['String'];
  providerApiKey: Scalars['String'];
  providerSessionId: Scalars['String'];
  providerToken: Scalars['String'];
};

export type ConsultationWorkflowsResponse = {
  workflows: WorkflowsResponse;
  workflowDefinitions: Array<WorkflowDefinition>;
};

export type ConsumerNetwork = {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  business_type?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  enable_prescriptions?: Maybe<Scalars['Boolean']>;
  enable_referrals?: Maybe<Scalars['Boolean']>;
  show_gp_consent?: Maybe<Scalars['Boolean']>;
  enable_voice_calls?: Maybe<Scalars['Boolean']>;
  enable_video_calls?: Maybe<Scalars['Boolean']>;
  enable_nhs?: Maybe<Scalars['Boolean']>;
  faq_url?: Maybe<Scalars['String']>;
  insurer_name?: Maybe<Scalars['String']>;
  insurer_logo_file_name?: Maybe<Scalars['String']>;
  make_gp_consent_mandatory?: Maybe<Scalars['Boolean']>;
  partner?: Maybe<Scalars['String']>;
  prescription_template?: Maybe<Scalars['String']>;
  terms_and_conditions_url?: Maybe<Scalars['String']>;
  consultant_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultation_types?: Maybe<Array<Maybe<AppointmentMedium>>>;
  enable_recording_consent?: Maybe<Scalars['Boolean']>;
  language_requested?: Maybe<Scalars['Boolean']>;
};

export type ConsumerNetworkName = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ConsumerNetworkProfession = {
  id: Scalars['ID'];
  name: Scalars['String'];
  alternative_slot_durations_minutes: Array<Scalars['Int']>;
  default_slot_duration_minutes: Scalars['Int'];
};

export type ConsumerNetworkResult = ConsumerNetwork | RetrievalError;

export type ContactDetails = {
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type Contract = {
  id: Scalars['ID'];
  regionId: Scalars['String'];
  region: TenancyRegion;
  fullRegionPath: Scalars['String'];
  partnerId: Scalars['String'];
  partner: TenancyPartner;
  fullPartnerPath: Scalars['String'];
  createdBy?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  plan: MembershipPlan;
  consumerNetwork?: Maybe<Partner>;
  membersCount: Scalars['Int'];
};

export type ContractInput = {
  id?: Maybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  partnerId: Scalars['ID'];
  planId: Scalars['ID'];
};

export type ContractPlan = {
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  partner_id: Scalars['String'];
  regions_ids: Array<Scalars['String']>;
  partner_path: Scalars['String'];
  created_by: Scalars['String'];
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  available_regions: Array<Scalars['String']>;
  full_partner_path: Scalars['String'];
};

export type ContractsByPlan = {
  plan: MembershipPlan;
  items: Array<Contract>;
};

export type ContractsPaginated = {
  items: Array<Contract>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
  /** @deprecated use 'total' instead */
  totalItems: Scalars['Int'];
  total: Scalars['Int'];
};

export type ContributionReview = {
  comment?: Maybe<Scalars['String']>;
  reviewer: Scalars['String'];
  time: Scalars['Int'];
  vote: Scalars['String'];
};

export type CoverageEligibility = {
  id: Scalars['ID'];
  validationKey: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  memberType?: Maybe<Scalars['String']>;
  businessUnit?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CoverageEligibilityCreateInput = {
  validationKey: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  expiry: Scalars['String'];
  removed: Scalars['Boolean'];
  memberType?: Maybe<Scalars['String']>;
  businessUnit?: Maybe<Scalars['String']>;
};

export type CoverageEligibilityError = {
  severity: CoverageEligibilityErrorSeverityEnum;
  code: Scalars['String'];
  details?: Maybe<Scalars['String']>;
};

export enum CoverageEligibilityErrorSeverityEnum {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type CoverageEligibilityList = {
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<CoverageEligibility>;
};

export type CoverageEligibilityUpdateInput = {
  validationKey?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  memberType?: Maybe<Scalars['String']>;
  businessUnit?: Maybe<Scalars['String']>;
};

export type CoverageEligibilityUpdateResult = {
  items: Array<CoverageEligibility>;
  errors?: Maybe<Array<CoverageEligibilityError>>;
};

export type Cp3ClinicalCodeWeightingsResult = {
  id: Scalars['ID'];
  countryCode: KenticoCountryCode;
  rankings: Scalars['String'];
};

export type Cp3LinksAndResourcesResult = {
  id: Scalars['ID'];
  countryCode: KenticoCountryCode;
  title: Scalars['String'];
  url: Scalars['String'];
  icon: Scalars['String'];
  dashboardLinkTypes: Cp3LinksAndResourcesType;
};

export type Cp3LinksAndResourcesType = {
  name: Scalars['String'];
  codename: Scalars['String'];
};

export type Cp3ReleaseNotesResult = {
  id: Scalars['ID'];
  countryCode: KenticoCountryCode;
  versionNumber: Scalars['String'];
  releaseDate: Scalars['Date'];
  changes: Scalars['String'];
  title: Scalars['String'];
};

export type CreateAppointmentCategoryOutput = {
  uuid: Scalars['String'];
};

export type CreateAppointmentErrors = {
  date?: Maybe<Scalars['Date']>;
};

export type CreateAppointmentInviteResponse = {
  invite: AppointmentInvite;
  errors?: Maybe<Array<CreateAppointmentErrors>>;
};

export type CreateCarePlanDraftInput = {
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type CreateCarePlanDraftResult = CreateCarePlanDraftSuccess | DraftInFlightAlreadyError | RevisionAlreadyPublishedInConsultationError | ConsultationDoesNotExistError | CarePlanAuthorizationError;

export type CreateCarePlanDraftSuccess = {
  carePlanDraft: CarePlanDraft;
};

/** Input for creating a new ghost appointment */
export type CreateGhostConsultationInput = {
  /** Uuid of patient to create the ghost appointment for */
  patientId: Scalars['ID'];
  /** ID of consultant to book the appointment for */
  consultantUuid: Scalars['ID'];
  /** Notes to add to consultation */
  consultationNotes: CreateGhostConsultationNotesInput;
  /** Consumer network to temporarily switch the patient to */
  consumerNetwork: Scalars['ID'];
  /** Service type(s) to use for creating the availability */
  allowedServiceTypes: Array<Scalars['String']>;
  /** Reason for the Ghost Consultation */
  appointmentReason: Scalars['String'];
  /** Whether or not the patient has given consent to share appointment details with GP */
  consentToShareWithGP: Scalars['Boolean'];
};

/** Diagnosis code for Ghost Appointment Consultation Notes */
export type CreateGhostConsultationNotesDiagnosisCodeInput = {
  code: Scalars['String'];
  term: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

/** Notes to add to the consultation, these fields can be found on the consultation view in clinical portal */
export type CreateGhostConsultationNotesInput = {
  toldByPatient: Scalars['String'];
  exploredByClinician: Scalars['String'];
  diagnosis: Scalars['String'];
  /**
   * The diagnosis code input fields are checked against the diagnosis itself. If
   * it is invalid, then the ghost consultation workflow will fail.
   * For example, an invalid term-offset duo is provided or non-existent or non-configured code is provided.
   */
  diagnosisCodes?: Maybe<Array<CreateGhostConsultationNotesDiagnosisCodeInput>>;
  adviceToPatient: Scalars['String'];
  safetyNetting: Scalars['String'];
};

export type CreatePartnerMembershipCodeInput = {
  partnerId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  validationKey?: Maybe<Scalars['String']>;
  validationProvider?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  messageClinician?: Maybe<Scalars['String']>;
};

export type CreatePlanFeatureStateInput = {
  feature: FeatureInput;
  state: FeatureStateValueInput;
};

export type CreatePlanInput = {
  partner_id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  region_ids: Array<Scalars['String']>;
  feature_states?: Maybe<Array<CreatePlanFeatureStateInput>>;
};

export type CreateProfessionInput = {
  name: Scalars['String'];
  alternative_slot_durations_minutes?: Maybe<Array<Scalars['Int']>>;
  default_slot_duration_minutes?: Maybe<Scalars['Int']>;
  default_appointment_price_cents?: Maybe<Scalars['Int']>;
  medical_identifier_label?: Maybe<Scalars['String']>;
};

export type CreateServiceTypeInput = {
  name: Scalars['String'];
  member_facing_name?: Maybe<Scalars['String']>;
  default_duration_minutes?: Maybe<Scalars['Int']>;
  intimate?: Maybe<Scalars['Boolean']>;
  member_description?: Maybe<Scalars['String']>;
  member_instructions?: Maybe<Scalars['String']>;
  us_ehr_appointment_type?: Maybe<Scalars['String']>;
  grouping?: Maybe<Scalars['String']>;
  common?: Maybe<Scalars['Boolean']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_medium?: Maybe<Scalars['String']>;
  service_type_categories?: Maybe<Array<Maybe<ServiceTypeCategoryInput>>>;
};

export enum CtSearchSystemType {
  Bbl = 'bbl',
  Ecl = 'ecl'
}

export type Currency = {
  id?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbol_first?: Maybe<Scalars['Boolean']>;
  decimal_mark?: Maybe<Scalars['String']>;
  thousands_separator?: Maybe<Scalars['String']>;
};

export enum CurrencyCode {
  Gbp = 'gbp',
  Usd = 'usd',
  Eur = 'eur',
  Cad = 'cad'
}


export type DateRange = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type DateWithShiftInfo = {
  date: Scalars['String'];
  hasIncompleteConsultations: Scalars['Boolean'];
  hasShift: Scalars['Boolean'];
};

export type DecryptedPatientsSearchFilters = {
  id?: Maybe<Scalars['Int']>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type DeleteActionDraftSuccess = {
  parentCarePlanDraft: CarePlanDraft;
};

export type DeleteAppointmentWaitTimeConfigsInput = {
  consumer_network_id: Scalars['Int'];
  service_type_uuid?: Maybe<Scalars['String']>;
};

export type DeleteCarePlanDraftInput = {
  carePlanDraftId: Scalars['ID'];
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type DeleteCarePlanDraftResult = DeleteCarePlanDraftSuccess | ConsultationDoesNotExistError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type DeleteCarePlanDraftSuccess = {
  /** @deprecated A pointless field used to work around GraphQL's disallowance of empty object types. */
  _: Scalars['Void'];
};

export type DeleteGoalDraftSuccess = {
  parentCarePlanDraft: CarePlanDraft;
};

export type DeletePatientReport = {
  babylonRequestId?: Maybe<Scalars['String']>;
  patient: Patient;
  servicesCalled?: Maybe<Array<Maybe<ServiceCall>>>;
};

export type DemographicInformation = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  date_of_birth?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
};

export type Demographics = {
  uuid: Scalars['ID'];
  status?: Maybe<DemographicStatus>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
  lookup_retry_attempts_left?: Maybe<Scalars['Int']>;
};

export enum DemographicStatus {
  Accepted = 'accepted',
  Errored = 'errored',
  Failed = 'failed',
  Incomplete = 'incomplete',
  NoResults = 'no_results',
  Pending = 'pending',
  RateLimitReached = 'rate_limit_reached',
  Redacted = 'redacted',
  Rejected = 'rejected',
  Retrieved = 'retrieved',
  UnderReview = 'under_review'
}

export type DeviceInfo = {
  platform?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  dateLastUsed?: Maybe<Scalars['String']>;
};

export type DiagnosticTest = {
  id?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  diagnostic_test_type_id?: Maybe<Scalars['Int']>;
};

export type DissociateGoalAndActionDraftSuccess = {
  goalDraft?: Maybe<CarePlanGoalDraft>;
  actionDraft?: Maybe<CarePlanActionDraft>;
};

export type DraftInFlightAlreadyError = {
  draftId: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  consultationId: Scalars['ID'];
};

export type DraftMetadata = {
  numberOfEdits: Scalars['Int'];
  warnings: Array<DraftMetadataWarning>;
};

export type DraftMetadataWarning = GoalDescriptionEmpty | ActionDescriptionEmpty | GoalWithoutAction | ActionWithoutGoal | CarePlanDraftWithoutGoalsAndActions;

export type Drug = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<Scalars['String']>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  drugPreparation?: Maybe<Scalars['String']>;
  vmpDmdId?: Maybe<Scalars['String']>;
  vtmId?: Maybe<Scalars['String']>;
  expensive?: Maybe<Scalars['Boolean']>;
  controlled?: Maybe<Scalars['Boolean']>;
  blacklisted?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  generic?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  packaging?: Maybe<Array<Maybe<DrugPackaging>>>;
};

export type DrugDirections = {
  drugId?: Maybe<Scalars['ID']>;
  drugName?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  dosage?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  errorInDirections?: Maybe<Scalars['Boolean']>;
  errorInDosage?: Maybe<Scalars['Boolean']>;
  errorInDuration?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type DrugDosage = {
  shortcut?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DrugPackaging = {
  id: Scalars['ID'];
  packagingId: Scalars['ID'];
  drugId: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  decimalPrice?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  packageDuration?: Maybe<Scalars['String']>;
  legalCategory?: Maybe<Scalars['String']>;
  ampDmdId?: Maybe<Scalars['String']>;
  expensive?: Maybe<Scalars['Boolean']>;
};

export type DrugPackagingInput = {
  id?: Maybe<Scalars['ID']>;
  drugId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  legalCategory: Scalars['String'];
  ampDmdId?: Maybe<Scalars['String']>;
};

export type EditActionDraftSuccess = {
  actionDraft: CarePlanActionDraft;
};

export type EditGoalDraftSuccess = {
  goalDraft: CarePlanGoalDraft;
};

export type EhrPatient = {
  externalEhrId?: Maybe<Scalars['ID']>;
  babylonPatientId?: Maybe<Scalars['ID']>;
  patientExist?: Maybe<Scalars['Boolean']>;
};

export type EmergencyContactDetails = {
  id?: Maybe<Scalars['ID']>;
  phone_country_code?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
};

export type EncryptedSearchCriteria = {
  iv?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  mop_search_using_profiles_service?: Maybe<Scalars['Boolean']>;
  mop_search_with_user_access_token?: Maybe<Scalars['Boolean']>;
};

export type EncryptedSearchFilters = {
  iv: Scalars['String'];
  q: Scalars['String'];
  page?: Maybe<Scalars['String']>;
  per_page?: Maybe<Scalars['String']>;
};

export enum EntryState {
  Active = 'active',
  Deleted = 'deleted'
}

export type Error = {
  message: Scalars['String'];
};

export type ExemptedConsultation = {
  id: Scalars['ID'];
  state: Scalars['String'];
  updatedAt: Scalars['String'];
  createdAt: Scalars['String'];
};

export type ExternalOntology = {
  source: Scalars['String'];
  code: Scalars['String'];
};

export type FamiliesOwnershipTransferral = {
  new_owner_id: Scalars['ID'];
  old_owner_id: Scalars['ID'];
};

export type FamilyAccount = {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  members?: Maybe<Array<Maybe<FamilyAccountMembership>>>;
};

export type FamilyAccountMembership = {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  minor?: Maybe<Scalars['Boolean']>;
  avatar_full_url?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invitation_sent?: Maybe<Scalars['Boolean']>;
  invitation_accepted?: Maybe<Scalars['Boolean']>;
  deletable?: Maybe<Scalars['Boolean']>;
  removable?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  can_add_subscription?: Maybe<Scalars['Boolean']>;
  can_cancel_subscription?: Maybe<Scalars['Boolean']>;
};

export type FamilyMember = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  date_of_birth: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  redemptions_attributes?: Maybe<RedemptionsAttributes>;
};

/** A feature, uniquely idetified by name, is used by other services to identify which code paths to follow */
export type Feature = {
  /** The unique id of the feature */
  id: Scalars['ID'];
  /** The unique name of the feature */
  name: Scalars['String'];
  /** The human readable name of the feature */
  displayName: Scalars['String'];
  /** The human readable description of what the feature is used for */
  description: Scalars['String'];
  /** The list of tags used for grouping features, e.g., plan-default */
  tags?: Maybe<Array<Scalars['String']>>;
};

/** A feature, uniquely idetified by name, is used by other services to identify which code paths to follow */
export type FeatureInput = {
  name: Scalars['String'];
};

/** A scope used to identify a cohort of users or devices to which a feature could be applied */
export type FeatureScope = {
  /** The type of scope, e.g., plan */
  type: FeatureScopeType;
  /** The value of the feature scope */
  value: Scalars['String'];
};

/** A scope used to identify a cohort of users or devices to which a feature could be applied */
export type FeatureScopeInput = {
  /** The type of scope, e.g., plan */
  type: FeatureScopeType;
  /** The value of the feature scope */
  value: Scalars['String'];
};

/** The accepted values for the types of scopes that can be associated to features */
export enum FeatureScopeType {
  Plan = 'plan',
  Region = 'region'
}

/** A feature and its state for a particular scope */
export type FeatureState = {
  /** The feature used by other services to identify which code paths to follow */
  feature: Feature;
  /** The state of the feature for a particular scope */
  state: FeatureStateValue;
  /** The scope used to identify a cohort of users or devices to which a feature could be applied */
  scope: FeatureScope;
};

/** A feature and its state for a particular scope */
export type FeatureStateInput = {
  feature: FeatureInput;
  scope: FeatureScopeInput;
  state: FeatureStateValueInput;
};

/** The state of a feature for a particular scope */
export type FeatureStateValue = {
  /** The type of value of a feature for a particular scope, e.g., boolean */
  type: FeatureStateValueType;
  /** The value of a feature for a particular scope */
  value: Scalars['Boolean'];
};

/** The state of a feature for a particular scope */
export type FeatureStateValueInput = {
  /** The type of value of a feature for a particular scope, e.g., boolean */
  type: FeatureStateValueType;
  /** The value of a feature for a particular scope */
  value: Scalars['Boolean'];
};

/** The accepted values for the types of states that can be associated to features */
export enum FeatureStateValueType {
  Boolean = 'boolean'
}

export type FeatureSwitch = {
  enable_adding_adults?: Maybe<Scalars['Boolean']>;
  enable_adding_children?: Maybe<Scalars['Boolean']>;
  enable_appointments?: Maybe<Scalars['Boolean']>;
  enable_changes_to_medical_history?: Maybe<Scalars['Boolean']>;
  enable_clinical_coding?: Maybe<Scalars['Boolean']>;
  enable_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_consultant_booking_on_behalf_of_patient?: Maybe<Scalars['Boolean']>;
  enable_dynamic_mailer?: Maybe<Scalars['Boolean']>;
  enable_family_accounts?: Maybe<Scalars['Boolean']>;
  enable_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  enable_home_prescription_delivery?: Maybe<Scalars['Boolean']>;
  enable_kits?: Maybe<Scalars['Boolean']>;
  enable_membership_type?: Maybe<Scalars['Boolean']>;
  enable_monitor_me?: Maybe<Scalars['Boolean']>;
  enable_nhs?: Maybe<Scalars['Boolean']>;
  enable_patient_ccg_update?: Maybe<Scalars['Boolean']>;
  enable_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_prescriptions?: Maybe<Scalars['Boolean']>;
  enable_print_prescriptions?: Maybe<Scalars['Boolean']>;
  enable_public_healthcare_registration?: Maybe<Scalars['Boolean']>;
  enable_referral_code?: Maybe<Scalars['Boolean']>;
  enable_referrals?: Maybe<Scalars['Boolean']>;
  enable_registration_queue?: Maybe<Scalars['Boolean']>;
  enable_restricted_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_prescription_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_your_details?: Maybe<Scalars['Boolean']>;
  enable_see_pharmacies?: Maybe<Scalars['Boolean']>;
  enable_update_gp_details?: Maybe<Scalars['Boolean']>;
  enable_update_patient_details?: Maybe<Scalars['Boolean']>;
  enable_video_calls?: Maybe<Scalars['Boolean']>;
  enable_voice_calls?: Maybe<Scalars['Boolean']>;
  free_subscription?: Maybe<Scalars['Boolean']>;
  id_verification_required?: Maybe<Scalars['Boolean']>;
  id_verification_required_for_appointments?: Maybe<Scalars['Boolean']>;
  inhibit_warnings?: Maybe<Scalars['Boolean']>;
  make_gp_consent_mandatory?: Maybe<Scalars['Boolean']>;
  nhs_gp_consumer_network?: Maybe<Scalars['Boolean']>;
  nida?: Maybe<Scalars['Boolean']>;
  refer_a_friend?: Maybe<Scalars['Boolean']>;
  show_ask?: Maybe<Scalars['Boolean']>;
  show_billing_history?: Maybe<Scalars['Boolean']>;
  show_billing_overview?: Maybe<Scalars['Boolean']>;
  show_check?: Maybe<Scalars['Boolean']>;
  show_family_accounts?: Maybe<Scalars['Boolean']>;
  show_gp_consent?: Maybe<Scalars['Boolean']>;
  show_gp_details?: Maybe<Scalars['Boolean']>;
  show_insurance?: Maybe<Scalars['Boolean']>;
  show_kit?: Maybe<Scalars['Boolean']>;
  show_medical_history?: Maybe<Scalars['Boolean']>;
  show_membership_codes?: Maybe<Scalars['Boolean']>;
  show_monitor?: Maybe<Scalars['Boolean']>;
  show_payment_details?: Maybe<Scalars['Boolean']>;
  show_pharmacy?: Maybe<Scalars['Boolean']>;
  show_questions?: Maybe<Scalars['Boolean']>;
  show_test_and_kits_not_covered_warning?: Maybe<Scalars['Boolean']>;
  show_upgrade_subscription_button?: Maybe<Scalars['Boolean']>;
  enable_continuity_of_care_for_referral?: Maybe<Scalars['Boolean']>;
};

export type FhirCodeableConcept = {
  coding?: Maybe<Array<FhirCoding>>;
};

export type FhirCoding = {
  system?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type FhirContextUsage = {
  count: Scalars['Int'];
  url: Scalars['String'];
};

export type FhirElementBinding = {
  strength: Scalars['String'];
  valueSet?: Maybe<Scalars['String']>;
};

export type FhirElementType = {
  code?: Maybe<Scalars['String']>;
  profile?: Maybe<Array<Scalars['String']>>;
  targetProfile?: Maybe<Array<Scalars['String']>>;
};

export type FhirProfile = {
  id: Scalars['ID'];
  meta: FhirProfileMeta;
  url: Scalars['String'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  fhirVersion: Scalars['String'];
  kind: Scalars['String'];
  abstract: Scalars['Boolean'];
  type: Scalars['String'];
  baseDefinition?: Maybe<Scalars['String']>;
  derivation?: Maybe<Scalars['String']>;
  snapshot: FhirProfileSnapshot;
  differential?: Maybe<FhirProfileSnapshot>;
};

export type FhirProfileMeta = {
  versionId: Scalars['Int'];
  lastUpdated: Scalars['String'];
};

export type FhirProfileSnapshot = {
  element: Array<FhirSnapshotElement>;
};

export type FhirSnapshotElement = {
  id: Scalars['String'];
  sliceName?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['String']>;
  short?: Maybe<Scalars['String']>;
  contentReference?: Maybe<Scalars['String']>;
  type?: Maybe<Array<FhirElementType>>;
  fixedCode?: Maybe<Scalars['String']>;
  fixedUri?: Maybe<Scalars['String']>;
  fixedString?: Maybe<Scalars['String']>;
  fixedCoding?: Maybe<FhirCoding>;
  fixedCodeableConcept?: Maybe<FhirCodeableConcept>;
  patternCoding?: Maybe<FhirCoding>;
  patternCodeableConcept?: Maybe<FhirCodeableConcept>;
  binding?: Maybe<FhirElementBinding>;
};

export type FhirValueSet = {
  id: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  inclusions: Array<FhirValueSetInclusion>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawJSON: Scalars['String'];
};

export type FhirValueSetConcept = {
  code: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
};

export type FhirValueSetConceptInclusion = FhirValueSetInclusion & {
  system: Scalars['String'];
  concepts: Array<FhirValueSetConcept>;
};

export type FhirValueSetExternalValueSetInclusion = FhirValueSetInclusion & {
  system: Scalars['String'];
  valueSets: Array<Scalars['String']>;
};

export type FhirValueSetFilter = {
  code: Scalars['String'];
  property: Scalars['String'];
  op: Scalars['String'];
};

export type FhirValueSetFilterInclusion = FhirValueSetInclusion & {
  system: Scalars['String'];
  filters: Array<FhirValueSetFilter>;
};

export type FhirValueSetInclusion = {
  system: Scalars['String'];
};

export type File = {
  value: Scalars['String'];
};

export type FileUpload = {
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Flag = {
  id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  appointment?: Maybe<Appointment>;
};

export enum Gender {
  Male = 'male',
  Female = 'female'
}

export type GoalActionDraftAssociation = {
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
  createdAt: Scalars['String'];
};

export type GoalActionDraftAssociationAlreadyExistError = {
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type GoalActionDraftAssociationDoesNotExistError = {
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type GoalDescriptionEmpty = {
  goalDraftId: Scalars['ID'];
};

export type GoalDraftDoesNotExistError = {
  goalDraftId: Scalars['ID'];
};

export type GoalWithoutAction = {
  goalDraftId: Scalars['ID'];
};

export type GpAtHandActionResult = {
  message?: Maybe<Scalars['String']>;
};

export type GpDetails = {
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_address_state_code?: Maybe<Scalars['String']>;
  gp_details_id?: Maybe<Scalars['ID']>;
  gp_full_address?: Maybe<Scalars['String']>;
  gp_name?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
};

export type GpDetailsInput = {
  gp_name?: Maybe<Scalars['String']>;
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
};

export type GpInformation = {
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_name?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  updating_gp_details?: Maybe<Scalars['Boolean']>;
};

export type GpSurgeryFax = {
  id: Scalars['ID'];
  name: Scalars['String'];
  faxNumber: Scalars['String'];
};

export type HealthcareIdentifier = {
  public_identifier?: Maybe<Scalars['String']>;
  private_identifier?: Maybe<Scalars['String']>;
  unconfirmed_public_identifier?: Maybe<Scalars['String']>;
};

export type HealthcareIdentifierInput = {
  public_identifier?: Maybe<Scalars['String']>;
  private_identifier?: Maybe<Scalars['String']>;
  unconfirmed_public_identifier?: Maybe<Scalars['String']>;
};

export type HealthCheckAboutSection = {
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export enum HealthCheckActionType {
  Maintain = 'maintain',
  Change = 'change'
}

export type HealthCheckAdviceItem = {
  content: Scalars['String'];
  header: Scalars['String'];
  suggested_asset?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  action_type?: Maybe<HealthCheckActionType>;
};

export type HealthCheckAssessmentCallToAction = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentContent = {
  body?: Maybe<Scalars['String']>;
  breakdown?: Maybe<HealthCheckAssessmentContentBreakdown>;
  header: Scalars['String'];
  image?: Maybe<HealthCheckAssessmentContentImage>;
};

export type HealthCheckAssessmentContentBreakdown = {
  body?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentContentImage = {
  accessibilityLabel?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlow = {
  durationMinutes?: Maybe<Scalars['Int']>;
  durationMinutesText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  status: HealthCheckAssessmentFlowStatus;
  scores: Array<Maybe<HealthCheckAssessmentScores>>;
};

export type HealthCheckAssessmentFlowConversation = {
  completedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  startedAt?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowDataPoint = {
  dateAdded?: Maybe<Scalars['String']>;
  dateCaptured?: Maybe<Scalars['String']>;
  displayValue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowDataPointCompleteness = {
  associatedDataPointDefinitions: Array<HealthCheckAssessmentFlowDataPointDefinition>;
  completedDataPoints: Array<HealthCheckAssessmentFlowDataPoint>;
  percentageComplete?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowDataPointDefinition = {
  description?: Maybe<Scalars['String']>;
  displayKey?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowStatus = {
  conversationCompletedAt?: Maybe<Scalars['String']>;
  conversationState?: Maybe<Scalars['String']>;
  conversationStateText?: Maybe<Scalars['String']>;
  conversations: Array<HealthCheckAssessmentFlowConversation>;
  dataPointCollectionState?: Maybe<Scalars['String']>;
  dataPointCollectionStateText?: Maybe<Scalars['String']>;
  dataPointCompleteness?: Maybe<HealthCheckAssessmentFlowDataPointCompleteness>;
};

export type HealthCheckAssessmentScoreResult = {
  score: Scalars['String'];
  severityText: Scalars['String'];
  ragStatus?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentScores = {
  id: Scalars['ID'];
  name: Scalars['String'];
  result?: Maybe<HealthCheckAssessmentScoreResult>;
};

export type HealthCheckAssessmentStage = {
  scores: Array<Maybe<HealthCheckAssessmentScores>>;
  callToActions: Array<HealthCheckAssessmentCallToAction>;
  content: HealthCheckAssessmentContent;
  nextFlows: Array<HealthCheckAssessmentFlow>;
};

export type HealthCheckCategory = {
  key: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  actions?: Maybe<HealthCheckCategoryAction>;
  about: HealthCheckAboutSection;
  overview: HealthCheckCategoryOverview;
  medical_history: HealthCheckMedicalHistory;
  health_metrics?: Maybe<Array<HealthCheckMetrics>>;
};

export type HealthCheckCategoryAction = {
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  items?: Maybe<Array<HealthCheckAdviceItem>>;
};

export type HealthCheckCategoryIndicator = {
  key: Scalars['String'];
  title: Scalars['String'];
  suggested_asset: Scalars['String'];
  suggested_colour: Scalars['String'];
};

export type HealthCheckCategoryOverview = {
  value?: Maybe<Scalars['Float']>;
  value_label?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  data_available?: Maybe<Scalars['Boolean']>;
  indicators?: Maybe<Array<Maybe<HealthCheckCategoryIndicator>>>;
  suggested_asset?: Maybe<Scalars['String']>;
  suggested_colour?: Maybe<Scalars['String']>;
};

export type HealthCheckDetail = {
  about?: Maybe<HealthCheckAboutSection>;
  interval_picker?: Maybe<HealthCheckIntervalPicker>;
  overview?: Maybe<HealthCheckDetailOverview>;
  risk?: Maybe<HealthCheckRisk>;
  risk_influence?: Maybe<HealthCheckRiskInfluences>;
  actions?: Maybe<HealthCheckDetailActions>;
};

export type HealthCheckDetailActions = {
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  thresholds?: Maybe<Array<Maybe<HealthCheckThreshold>>>;
  items?: Maybe<Array<Maybe<HealthCheckAdviceItem>>>;
};

export type HealthCheckDetailOverview = {
  body?: Maybe<Scalars['String']>;
};

export enum HealthCheckDisplayType {
  Continuous = 'continuous',
  Simple = 'simple',
  Segmented = 'segmented',
  Status = 'status',
  Time = 'time',
  TrafficLight = 'traffic_light'
}

export type HealthCheckInfluence = {
  asset?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type HealthCheckInterval = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type HealthCheckIntervalPicker = {
  selected_id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckInterval>>>;
};

export type HealthCheckMedicalHistory = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckMedicalHistoryItem>>>;
};

export type HealthCheckMedicalHistoryItem = {
  content?: Maybe<Scalars['String']>;
  suggested_asset?: Maybe<Scalars['String']>;
};

export type HealthCheckMetric = {
  key: Scalars['String'];
  data_available?: Maybe<Scalars['Boolean']>;
  detail?: Maybe<HealthCheckDetail>;
  display_type?: Maybe<HealthCheckDisplayType>;
  title?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_label?: Maybe<Scalars['String']>;
  thresholds?: Maybe<Array<HealthCheckThreshold>>;
};

export type HealthCheckMetrics = {
  key: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckMetric>>>;
};

export type HealthCheckMetricSummary = {
  total: Scalars['Int'];
  totalWithData: Scalars['Int'];
  metrics?: Maybe<Array<Maybe<HealthCheckSeverityValuePair>>>;
};

export type HealthCheckRisk = {
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type HealthCheckRiskInfluences = {
  title?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckInfluence>>>;
};

export type HealthCheckSeverityMetric = {
  colour?: Maybe<Scalars['String']>;
  percentage: Scalars['Float'];
  primary: Scalars['Boolean'];
  severity?: Maybe<Scalars['Int']>;
  icon: Scalars['String'];
  id: Scalars['String'];
};

export type HealthCheckSeverityValuePair = {
  severity?: Maybe<Scalars['Int']>;
  value: Scalars['Int'];
  icon: Scalars['String'];
};

export type HealthCheckSummary = {
  severities: Array<HealthCheckSeverityMetric>;
  diseaseRisks?: Maybe<HealthCheckMetricSummary>;
  organHealth?: Maybe<HealthCheckMetricSummary>;
};

export type HealthCheckThreshold = {
  min: Scalars['Float'];
  max?: Maybe<Scalars['Float']>;
  severity: Scalars['Float'];
  suggested_colour: Scalars['String'];
  title: Scalars['String'];
};

export type HumanitySync = {
  humanity_sync_id: Scalars['ID'];
};

export type HumanitySyncAllowed = {
  sync_allowed: Scalars['Boolean'];
};

export type HumanitySyncShift = {
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_id: Scalars['ID'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  consultant_id: Scalars['ID'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  consultant_name?: Maybe<Scalars['String']>;
  /** @deprecated replaced by HumanitySyncShiftItem */
  status: HumanitySyncShiftStatusEnum;
  /** @deprecated replaced by HumanitySyncShiftItem */
  errors: Array<Maybe<Scalars['String']>>;
  /** @deprecated replaced by HumanitySyncShiftItem */
  warnings: Array<Maybe<Scalars['String']>>;
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_pattern: Scalars['String'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_start: Scalars['Date'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_end: Scalars['Date'];
};

export type HumanitySyncShiftItem = {
  shift_id: Scalars['ID'];
  consultant_id?: Maybe<Scalars['ID']>;
  consultant_name?: Maybe<Scalars['String']>;
  status?: Maybe<HumanitySyncShiftStatusEnum>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
  shift_pattern?: Maybe<Scalars['String']>;
  shift_start?: Maybe<Scalars['Date']>;
  shift_end?: Maybe<Scalars['Date']>;
};

export type HumanitySyncShiftItemFilters = {
  consultantId?: Maybe<Scalars['ID']>;
  consultantName?: Maybe<Scalars['String']>;
  shiftStartDate?: Maybe<Scalars['String']>;
  shiftEndDate?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  warnings?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
};

export type HumanitySyncShiftItems = {
  total: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  shifts: Array<HumanitySyncShiftItem>;
};

export type HumanitySyncShifts = {
  /** @deprecated replaced by HumanitySyncShiftItems */
  total: Scalars['Int'];
  /** @deprecated replaced by HumanitySyncShiftItems */
  limit: Scalars['Int'];
  /** @deprecated replaced by HumanitySyncShiftItems */
  offset: Scalars['Int'];
  /** @deprecated replaced by HumanitySyncShiftItems */
  shifts: Array<Maybe<HumanitySyncShift>>;
};

export enum HumanitySyncShiftStatusEnum {
  Cancelled = 'cancelled',
  Ignored = 'ignored',
  Imported = 'imported',
  InvalidData = 'invalid_data',
  Pending = 'pending'
}

export type HumanitySyncStatus = {
  status: HumanitySyncStatusEnum;
  orphan_count?: Maybe<Scalars['Int']>;
};

export enum HumanitySyncStatusEnum {
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Unchanged = 'unchanged'
}

export type IdentityCheck = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  check_id?: Maybe<Scalars['String']>;
  verification_overwritten_by?: Maybe<PortalUser>;
  verification_overwritten_at?: Maybe<Scalars['String']>;
};

export type IdValidationResult = {
  isValid: Scalars['Boolean'];
  message: Scalars['String'];
};

export type IdVerification = {
  external_reference: Scalars['String'];
  external_sdk_token: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  patient_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type InsuranceClaim = {
  id: Scalars['ID'];
  state: Scalars['String'];
  updatedAt: Scalars['String'];
  createdAt: Scalars['String'];
};

export type InsuranceCompany = {
  id: Scalars['Int'];
  created_at: Scalars['String'];
  name: Scalars['String'];
  take_payment: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['String']>;
  memberships?: Maybe<Array<Maybe<InsuranceMembership>>>;
};

export type InsuranceInformation = {
  date_of_birth?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address_post_code?: Maybe<Scalars['String']>;
  insurance_membership_number?: Maybe<Scalars['String']>;
  insurance_company_id?: Maybe<Scalars['Int']>;
};

export type InsuranceMembership = {
  id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  insurance_company_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type InvalidCarePlanDraftNumbersOfEditsError = {
  draftId: Scalars['ID'];
  currentNumberOfEdits: Scalars['Int'];
  providedNumberOfEdits: Scalars['Int'];
};


export type KenticoCountryCode = {
  name: Scalars['String'];
  codename: Scalars['String'];
};

export type KongToken = {
  access_token: Scalars['String'];
  expires_in?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

export type Leaflet = {
  content?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ListingDetails = {
  id: Scalars['ID'];
  duration_minutes?: Maybe<Scalars['Int']>;
  clinic_id?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['ID']>;
  appointment_time?: Maybe<Scalars['Date']>;
  state?: Maybe<Scalars['String']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
  consultation_type?: Maybe<Scalars['String']>;
  appointment_reason?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  image_attached?: Maybe<Scalars['Boolean']>;
  public_healthcare_identifier?: Maybe<Scalars['ID']>;
  appointment_invite_id?: Maybe<Scalars['ID']>;
};

export type MedicalHistory = {
  medical_history?: Maybe<Scalars['String']>;
};

export type Medication = {
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type Medium = {
  medium?: Maybe<Scalars['String']>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
};

export type MediumInput = {
  medium?: Maybe<Scalars['String']>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
};

export type MemberDecisionForNoticeInput = {
  patientUUID: Scalars['ID'];
  noticeEnum: NoticeEnum;
  appName: AppName;
};

export type Membership = {
  id: Scalars['Int'];
  code: Scalars['String'];
  description: Scalars['String'];
  consumer_network?: Maybe<ConsumerNetwork>;
};

export type MembershipContract = {
  partner_id: Scalars['ID'];
  id: Scalars['ID'];
  state?: Maybe<EntryState>;
  region_id: Scalars['String'];
  region_path: Scalars['String'];
  partner_path: Scalars['String'];
  member_count: Scalars['Int'];
  created_by: Scalars['String'];
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  full_partner_path: Scalars['String'];
  full_region_path: Scalars['String'];
  plan?: Maybe<ContractPlan>;
};

export type MembershipPlan = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  partnerId: Scalars['String'];
  partner?: Maybe<TenancyPartner>;
  /** @deprecated Field no longer supported */
  availableRegions: Array<Scalars['String']>;
  regions: Array<TenancyRegion>;
  fullPartnerPath: Scalars['String'];
  createdAt: Scalars['Date'];
  featureStates: Array<FeatureState>;
};

export type MembershipPlansPaginated = {
  items: Array<MembershipPlan>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
};

export type MemberTask = {
  id: Scalars['ID'];
  thread: MemberThread;
  createdAt: Scalars['String'];
  logEntry?: Maybe<Scalars['String']>;
  state: MemberWorkflowState;
};

export type MemberTaskFilter = {
  uuid: Scalars['ID'];
};

export type MemberTasksFilter = {
  threadUuid: Scalars['ID'];
};

export type MemberThread = {
  id: Scalars['ID'];
  currentTask: MemberTask;
  tasks: Array<MemberTask>;
  memberId: Scalars['ID'];
  state: MemberWorkflowState;
  createdAt: Scalars['String'];
  type: MemberThreadType;
  subType: MemberThreadSubType;
};

export type MemberThreadFilter = {
  uuid: Scalars['ID'];
};

export type MemberThreadsFilter = {
  memberId: Array<Scalars['ID']>;
  type?: Maybe<Array<MemberThreadType>>;
};

export enum MemberThreadSubType {
  BloodTest = 'BLOOD_TEST'
}

export enum MemberThreadType {
  Test = 'TEST',
  Referral = 'REFERRAL',
  Prescription = 'PRESCRIPTION'
}

export enum MemberWorkflowState {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
}

export type Message = {
  message?: Maybe<Scalars['String']>;
};

export type MonthlyCalendarSchedule = {
  incompleteConsultations: Array<Scalars['String']>;
  shifts: Array<Scalars['String']>;
};

export type MopPatient = Subject & {
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  consumer_networks?: Maybe<Array<Maybe<PatientConsumerNetwork>>>;
  consumer_network_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  family_accounts_count?: Maybe<Scalars['Int']>;
  family_accounts_member_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  family_accounts_owner?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  healthcare_identifier?: Maybe<HealthcareIdentifier>;
  identity_check?: Maybe<IdentityCheck>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  minor?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['ID']>;
  patient_membership_link?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  total_appointments_count?: Maybe<Scalars['Int']>;
  verification_status?: Maybe<Scalars['String']>;
  invitation_was_sent_but_not_accepted?: Maybe<Scalars['Boolean']>;
  public_healthcare_admission?: Maybe<PublicHealthcareAdmission>;
  queued_for_activation?: Maybe<Scalars['Boolean']>;
};

export type MopPatientInput = {
  id?: Maybe<Scalars['ID']>;
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  birth_details?: Maybe<BirthDetailInput>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergency_contact_details?: Maybe<Array<Maybe<EmergencyContactDetails>>>;
  first_name?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  gp_details?: Maybe<GpDetailsInput>;
  healthcare_identifier?: Maybe<HealthcareIdentifierInput>;
  last_name?: Maybe<Scalars['String']>;
  previous_names?: Maybe<Scalars['String']>;
  previous_practice_name?: Maybe<Scalars['String']>;
  public_healthcare_details?: Maybe<PublicHealthcareDetailsInput>;
  region?: Maybe<RegionInput>;
  uuid?: Maybe<Scalars['ID']>;
};

export type MopPatientSearchCriteria = {
  id?: Maybe<Scalars['Int']>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  mop_search_using_profiles_service?: Maybe<Scalars['Boolean']>;
  mop_search_with_user_access_token?: Maybe<Scalars['Boolean']>;
};

export type MopSearchPatients = {
  encryptedSearchFilters?: Maybe<EncryptedSearchFilters>;
  decryptedPatientsSearchFilters?: Maybe<DecryptedPatientsSearchFilters>;
  pagination?: Maybe<SearchPagination>;
  patients: Array<Maybe<MopPatient>>;
};

export type Multimedia = {
  call_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  video_session_id?: Maybe<Scalars['Int']>;
};

export enum MultimediaFeedbackClientType {
  Clinician = 'clinician',
  Member = 'member'
}

export type MultimediaFeedbackInput = {
  consultation_id: Scalars['ID'];
  question_id: Scalars['ID'];
  client_type: Scalars['String'];
  client_id: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
  answer_ids?: Maybe<Array<Scalars['ID']>>;
  comments?: Maybe<Array<Scalars['String']>>;
};

export type MultimediaFeedbackQuestion = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  question: Scalars['String'];
  question_type: Scalars['String'];
  position: Scalars['Int'];
  total: Scalars['Int'];
  suggested_answers?: Maybe<Array<SuggestedAnswer>>;
};

export enum MultimediaFeedbackQuestionType {
  StarRating = 'star_rating',
  FreeText = 'free_text',
  SingleSelect = 'single_select',
  MultiSelect = 'multi_select',
  MultiSelectWithFreeText = 'multi_select_with_free_text'
}

export type MultimediaFeedbackResult = {
  id: Scalars['ID'];
  next_question?: Maybe<MultimediaFeedbackQuestion>;
};

export type Mutation = {
  addActionDraft: AddActionDraftResult;
  addAdminDrug?: Maybe<AdminDrug>;
  addAdminDrugAlert?: Maybe<AdminDrugAlert>;
  addAlert?: Maybe<Message>;
  addAvailabilitySlots?: Maybe<Scalars['Void']>;
  addCarer: Scalars['String'];
  addCliniciansToServiceType?: Maybe<Scalars['Boolean']>;
  addClinicsToServiceType?: Maybe<Scalars['Boolean']>;
  addComment?: Maybe<Message>;
  addCommentToPrescription?: Maybe<Prescription>;
  addConcept: NewContributionResponse;
  addConsumerNetworksToServiceType?: Maybe<Scalars['Boolean']>;
  addDrugToPrescription?: Maybe<Prescription>;
  addFamilyMember?: Maybe<Patient>;
  addGoalDraft: CarePlanAddGoalDraftResult;
  addPatientMetric?: Maybe<PatientMetric>;
  addPatientToConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  addRelationships: NewContributionResponse;
  addToAllergies: Array<Maybe<Allergy>>;
  addToConditions: Array<Maybe<Condition>>;
  addToMedications: Array<Maybe<Medication>>;
  adminActiveIngredient?: Maybe<Array<AdminActiveIngredient>>;
  applyCode: Code;
  applyRedemptionV1: Scalars['Int'];
  applyRefund: PortalTransaction;
  assignAppointmentCategoryToAppointmentConfiguration?: Maybe<Scalars['Void']>;
  assignContractToAppointmentConfiguration?: Maybe<Tenancies>;
  assignServiceTypesToAppointmentConfiguration?: Maybe<Scalars['Void']>;
  associateGoalAndActionDraft: AssociateGoalAndActionDraftResult;
  blacklistDrug?: Maybe<AdminDrug>;
  bookAppointment?: Maybe<BookingRequestResult>;
  bookAppointmentWithMicroservice?: Maybe<Appointment>;
  cancelAppointment: CancelledAppointment;
  cancelAppointmentWithMicroservice?: Maybe<Appointment>;
  cancelGPAtHand?: Maybe<GpAtHandActionResult>;
  cancelHumanitySync?: Maybe<HumanitySyncStatus>;
  cancelPatientSubscription?: Maybe<Subscription>;
  changePassword?: Maybe<Message>;
  checkInEhr?: Maybe<Scalars['Boolean']>;
  clinicalPresence?: Maybe<Scalars['Void']>;
  cloneAppointmentConfiguration?: Maybe<CloneAppointmentConfiguration>;
  completeConsultation?: Maybe<Consultation>;
  coverageEligibilityCreate: CoverageEligibility;
  coverageEligibilityUpdate: CoverageEligibilityUpdateResult;
  createAddress?: Maybe<Address>;
  createAppointmentCategory?: Maybe<CreateAppointmentCategoryOutput>;
  createAppointmentCredit: AppointmentCredit;
  /** @deprecated Deprecated in favour of createAppointmentInviteV2 */
  createAppointmentInvite: AppointmentInvite;
  /** @deprecated Deprecated in favour of createAppointmentInviteV3 */
  createAppointmentInviteV2?: Maybe<Array<AppointmentInvite>>;
  createAppointmentInviteV3: CreateAppointmentInviteResponse;
  createAppointmentMediumConfiguration?: Maybe<Configurations>;
  createCarePlanDraft: CreateCarePlanDraftResult;
  createConsultationAudioSession: ConsultationVideoSession;
  createConsultationFurtherInfo: ConsultationFurtherInfo;
  createConsultationReferral?: Maybe<ConsultationReferral>;
  createConsultationSickNote?: Maybe<ConsultationSickNote>;
  createConsultationTest: ConsultationTest;
  createConsumerNetworkAndContract?: Maybe<Contract>;
  /**
   * Creates a Ghost Appointment & Consultation. Patients do NOT attend these
   * appointments. Clinicians use it to record some patent-related activities.
   */
  createGhostConsultation?: Maybe<Appointment>;
  createPartnerMembershipCode?: Maybe<PartnerMembershipCode>;
  createPatientAlert?: Maybe<PatientAlert>;
  createPlan?: Maybe<MembershipPlan>;
  createPortalUser?: Maybe<PortalUser>;
  createPrescription?: Maybe<Prescription>;
  createProfession?: Maybe<Profession>;
  createServiceType?: Maybe<ServiceType>;
  createTenancyPartner?: Maybe<TenancyPartner>;
  createWorkflow: Array<Maybe<Workflow>>;
  deleteActionDraft: CarePlanDeleteActionDraftResult;
  deleteAdminDrugAlert: Scalars['Boolean'];
  deleteAppointmentCategory?: Maybe<Scalars['Void']>;
  deleteAppointmentWaitTimeConfigs?: Maybe<Scalars['Void']>;
  deleteCarePlanDraft: DeleteCarePlanDraftResult;
  deleteConsumerNetworkAndContract?: Maybe<Scalars['Boolean']>;
  deleteGoalDraft: CarePlanDeleteGoalDraftResult;
  deletePartner?: Maybe<Scalars['Boolean']>;
  deletePartnerBranding?: Maybe<PartnerBranding>;
  deletePartnerMembershipCode?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  deletePatient?: Maybe<Message>;
  deletePlan?: Maybe<Scalars['Boolean']>;
  deleteServiceType?: Maybe<Scalars['Boolean']>;
  deleteThePatient?: Maybe<DeletePatientReport>;
  deregisterGPAtHand?: Maybe<GpAtHandActionResult>;
  dissociateGoalAndActionDraft: CarePlanDissociateGoalAndActionDraftResult;
  duplicatePrescription: Prescription;
  editActionDraft: CarePlanEditActionDraftResult;
  editFamilyMember?: Maybe<Patient>;
  editGoalDraft: CarePlanEditGoalDraftResult;
  endAudioCall?: Maybe<Scalars['Boolean']>;
  endVideoCall?: Maybe<Scalars['Boolean']>;
  gpSurgerySendFax: Scalars['Boolean'];
  healthCheckInvalidateAnswers?: Maybe<Scalars['Boolean']>;
  healthCheckSubmitAssessment?: Maybe<Scalars['Boolean']>;
  linkPatientToTransaction: Scalars['Boolean'];
  lookupDemographics?: Maybe<Demographics>;
  markEHRNotesSensitive?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field no longer supported */
  markPrescriptionPrinted?: Maybe<Scalars['ID']>;
  markRepeatPrescriptionAsStopped?: Maybe<Scalars['Void']>;
  overrideIdentityCheck?: Maybe<IdentityCheck>;
  payAppointment: Appointment;
  postPatientDemographic?: Maybe<Demographics>;
  postcodeLookup?: Maybe<Address>;
  prescriptionChangeState?: Maybe<Prescription>;
  prescriptionMarkDrugsDispatched: Prescription;
  prescriptionPdfFile?: Maybe<File>;
  prescriptionRejectPharmacySupplier: Prescription;
  prescriptionRetryFax?: Maybe<Scalars['ID']>;
  prescriptionRetryPdf?: Maybe<Scalars['ID']>;
  privacyPolicyDecision?: Maybe<PrivacyNotice>;
  promoteCarePlanDraft: PromoteCarePlanDraftResult;
  rateAppointment: Appointment;
  rateConsultation: Review;
  reactivateRedemption: Scalars['Boolean'];
  reallocateShift?: Maybe<ReallocatedAppointments>;
  regenerateClinicianPin: Scalars['Boolean'];
  registerNhsMinor: Patient;
  registerNhsPatient: Patient;
  rejoinConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  removeAppointmentCredit: Scalars['Boolean'];
  removeCarer: Scalars['String'];
  removeClinicFromServiceType?: Maybe<Scalars['Boolean']>;
  removeClinicianFromServiceType?: Maybe<Scalars['Boolean']>;
  removeConsultationFurtherInfo?: Maybe<Scalars['ID']>;
  removeConsultationRecordings?: Maybe<Consultation>;
  removeConsultationReferral?: Maybe<Scalars['ID']>;
  removeConsultationSickNote?: Maybe<Scalars['ID']>;
  removeConsultationTest?: Maybe<Scalars['ID']>;
  removeConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  removeConsumerNetworkFromServiceType?: Maybe<Scalars['Boolean']>;
  removeDrugFromPrescription?: Maybe<Prescription>;
  removeFromAllergies: Array<Maybe<Allergy>>;
  removeFromConditions: Array<Maybe<Condition>>;
  removeFromMedications: Array<Maybe<Medication>>;
  removePatientAlert?: Maybe<Scalars['ID']>;
  removePrescription?: Maybe<Scalars['ID']>;
  removeRedemption: Scalars['Boolean'];
  removeReferral?: Maybe<Scalars['ID']>;
  reportCallStep?: Maybe<Scalars['ID']>;
  requestPrescriptionDelivery?: Maybe<PrescriptionDeliveryConfirmation>;
  requestSignerToken?: Maybe<TokenValidity>;
  requestSyncShiftsReport: ShiftsCsvDownload;
  resendInvitation?: Maybe<PatientResentInvitation>;
  resetPassword?: Maybe<Message>;
  resetPasswordById?: Maybe<Message>;
  reviewContribution: ReviewContributionResponse;
  revokeRecordingConsent?: Maybe<Consultation>;
  saveAppointmentWaitTimeConfigs?: Maybe<Scalars['Void']>;
  saveServiceType?: Maybe<ServiceType>;
  selectConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  sendAudioStream?: Maybe<Scalars['String']>;
  sendPatientReferral?: Maybe<Scalars['ID']>;
  sendPrescriptionToHome: Prescription;
  sendPrescriptionToPharmacy: Prescription;
  sendPrescriptionToPharmacyByReference: Prescription;
  sendReferralEmail?: Maybe<Scalars['ID']>;
  sendSlackMessage?: Maybe<SlackMessage>;
  setConsultantRTMAvailability: Scalars['String'];
  setConsultationExempted: ExemptedConsultation;
  setInsuranceClaimExempted: InsuranceClaim;
  signPrescription: Prescription;
  startAudioCall?: Maybe<Scalars['Boolean']>;
  startIdVerification?: Maybe<Patient>;
  startVideoCall?: Maybe<Scalars['Boolean']>;
  submitMultimediaFeedback: MultimediaFeedbackResult;
  suggestAllergies: Array<Maybe<ClinicalKnowledgeLabel>>;
  suggestConditions: Array<Maybe<ClinicalKnowledgeLabel>>;
  suggestDrugDirections?: Maybe<DrugDirections>;
  suggestLabels: Array<Maybe<ClinicalKnowledgeLabel>>;
  suggestMedications: Array<Maybe<ClinicalKnowledgeLabel>>;
  syncHumanity?: Maybe<HumanitySync>;
  transferOwnership?: Maybe<FamiliesOwnershipTransferral>;
  transferPatientToNhs: Patient;
  triggerWorkflow: WorkflowV2;
  unAssignAppointmentCategoryFromAppointmentConfiguration?: Maybe<Scalars['Void']>;
  unAssignServiceTypesToAppointmentConfiguration?: Maybe<Scalars['Void']>;
  unAssignTenancyFromAppointmentConfiguration?: Maybe<Scalars['Void']>;
  updateAdminDrug?: Maybe<AdminDrug>;
  updateAdminDrugAlert?: Maybe<AdminDrugAlert>;
  updateAppointment: Appointment;
  updateAppointmentGpConsent: AppointmentV2;
  updateAppointmentMediumConfiguration?: Maybe<Configurations>;
  updateAppointmentWaitTimeConfigs?: Maybe<Scalars['Void']>;
  updateConsultation?: Maybe<Consultation>;
  updateConsultationDiagnosis?: Maybe<ConsultationDiagnosis>;
  /** @deprecated Field no longer supported */
  updateConsultationGpConsent?: Maybe<Consultation>;
  updateConsultationNote?: Maybe<ConsultationNote>;
  updateConsultationReferral: ConsultationReferral;
  updateConsultationSickNote?: Maybe<ConsultationSickNote>;
  updateConsultationStatus?: Maybe<Consultation>;
  updateConsultationTest: ConsultationTest;
  updateConsultations: Array<Maybe<Consultation>>;
  updateDrugInPrescription?: Maybe<Prescription>;
  /** Create and/or update feature states given a list of feature names, scopes and states */
  updateFeatureStates: Array<FeatureState>;
  updateFlag?: Maybe<Flag>;
  updateGP?: Maybe<Patient>;
  updateGpInAppointment?: Maybe<Patient>;
  updateInsuranceCompany?: Maybe<Patient>;
  updateMarkedAsSent?: Maybe<Scalars['Boolean']>;
  updateMedicalHistory?: Maybe<MedicalHistory>;
  updateMopPatient: Patient;
  updatePanel?: Maybe<TestReport>;
  updatePartnerBranding?: Maybe<PartnerBranding>;
  updatePartnerMembershipCode?: Maybe<PartnerMembershipCode>;
  updatePatient: Patient;
  updatePatientAlert?: Maybe<PatientAlert>;
  updatePatientDetails: Patient;
  updatePatientName?: Maybe<Patient>;
  updatePortalUser?: Maybe<PortalUser>;
  updatePrimaryGuardian: Scalars['String'];
  updateReferral: ConsultationReferral;
  updateReportState?: Maybe<TestReport>;
  updateTenancyPartner?: Maybe<TenancyPartner>;
  updateTenancyPartnerParent?: Maybe<TenancyPartner>;
  uploadConsultationFile?: Maybe<Scalars['Boolean']>;
  uploadMembers?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  validateDrug?: Maybe<PrescriptionValidation>;
  validatePatientDetails?: Maybe<Scalars['ID']>;
  validateToken?: Maybe<TokenValidity>;
  /** @deprecated Field no longer supported */
  voidPrescription?: Maybe<Scalars['ID']>;
};


export type MutationAddActionDraftArgs = {
  input: CarePlanAddActionDraftInput;
};


export type MutationAddAdminDrugArgs = {
  adminDrug: AdminDrugInput;
};


export type MutationAddAdminDrugAlertArgs = {
  adminDrugAlert: AdminDrugAlertInput;
};


export type MutationAddAlertArgs = {
  patientId: Scalars['ID'];
  description: Scalars['String'];
};


export type MutationAddAvailabilitySlotsArgs = {
  input?: Maybe<AvailabilitySlotsInput>;
};


export type MutationAddCarerArgs = {
  carerId: Scalars['String'];
  dependantId: Scalars['String'];
};


export type MutationAddCliniciansToServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinician_ids: Array<Scalars['ID']>;
};


export type MutationAddClinicsToServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinic_ids: Array<Scalars['ID']>;
};


export type MutationAddCommentArgs = {
  patient_id: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddCommentToPrescriptionArgs = {
  prescriptionId: Scalars['ID'];
  comment: Scalars['String'];
};


export type MutationAddConceptArgs = {
  isContribution: Scalars['Boolean'];
  parentConcept: Scalars['String'];
  preferredLabel: Scalars['String'];
  ticket: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<RelationshipIris>>>;
};


export type MutationAddConsumerNetworksToServiceTypeArgs = {
  service_type_uuid?: Maybe<Scalars['ID']>;
  consumer_network_ids: Array<Scalars['ID']>;
};


export type MutationAddDrugToPrescriptionArgs = {
  id: Scalars['ID'];
  drug: PrescriptionDrugInput;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationAddFamilyMemberArgs = {
  input: FamilyMember;
  id: Scalars['ID'];
};


export type MutationAddGoalDraftArgs = {
  input: CarePlanAddGoalDraftInput;
};


export type MutationAddPatientMetricArgs = {
  patientUuid: Scalars['ID'];
  patientId: Scalars['ID'];
  clinicianId?: Maybe<Scalars['ID']>;
  clinicianUuid?: Maybe<Scalars['ID']>;
  consultationId: Scalars['ID'];
  clinicianRole?: Maybe<Scalars['String']>;
  observationType: PatientMetricObservationType;
  input: Scalars['JSON'];
  filter: PatientMetricSource;
  consultationType?: Maybe<Scalars['String']>;
  consultationUuid?: Maybe<Scalars['String']>;
};


export type MutationAddPatientToConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationAddRelationshipsArgs = {
  relationships?: Maybe<Array<Maybe<RelationshipIris>>>;
  originalConceptIri: Scalars['String'];
  ticket: Scalars['String'];
  isContribution: Scalars['Boolean'];
};


export type MutationAddToAllergiesArgs = {
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
};


export type MutationAddToConditionsArgs = {
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
};


export type MutationAddToMedicationsArgs = {
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
};


export type MutationAdminActiveIngredientArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type MutationApplyCodeArgs = {
  code: Scalars['String'];
  patient_id: Scalars['Int'];
  redemption_type: Scalars['String'];
};


export type MutationApplyRedemptionV1Args = {
  code: Scalars['String'];
  patientId: Scalars['Int'];
  redemptionType: RedemptionType;
};


export type MutationApplyRefundArgs = {
  input: ApplyRefundInput;
};


export type MutationAssignAppointmentCategoryToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  categoryIds: Array<Scalars['String']>;
};


export type MutationAssignContractToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  tenantId: Scalars['String'];
  tenantType: Scalars['String'];
};


export type MutationAssignServiceTypesToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  serviceTypeAssignments: Array<Maybe<ServiceTypeAssignment>>;
};


export type MutationAssociateGoalAndActionDraftArgs = {
  input: CarePlanAssociateGoalAndActionDraftInput;
};


export type MutationBlacklistDrugArgs = {
  id: Scalars['ID'];
  region: Scalars['String'];
  value: Scalars['Boolean'];
  reason: Scalars['String'];
};


export type MutationBookAppointmentArgs = {
  input: AppointmentRequestInput;
};


export type MutationBookAppointmentWithMicroserviceArgs = {
  input?: Maybe<AppointmentRequestInputForMicroservice>;
};


export type MutationCancelAppointmentArgs = {
  id: Scalars['Int'];
  cancel_reason_id?: Maybe<Scalars['Int']>;
  cancel_reason_comment?: Maybe<Scalars['String']>;
};


export type MutationCancelAppointmentWithMicroserviceArgs = {
  appointmentId: Scalars['ID'];
  cancelReasonId?: Maybe<Scalars['Int']>;
};


export type MutationCancelGpAtHandArgs = {
  patientId: Scalars['ID'];
};


export type MutationCancelHumanitySyncArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationCancelPatientSubscriptionArgs = {
  patientId: Scalars['ID'];
  subscriptionId: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckInEhrArgs = {
  consultationId: Scalars['ID'];
};


export type MutationCloneAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCompleteConsultationArgs = {
  id: Scalars['ID'];
};


export type MutationCoverageEligibilityCreateArgs = {
  partnerId: Scalars['String'];
  input: CoverageEligibilityCreateInput;
};


export type MutationCoverageEligibilityUpdateArgs = {
  input: CoverageEligibilityUpdateInput;
  partnerId: Scalars['String'];
  members: Array<Scalars['String']>;
};


export type MutationCreateAddressArgs = {
  address: AddressInput;
  patient_id: Scalars['ID'];
};


export type MutationCreateAppointmentCategoryArgs = {
  categoryDetails: AppointmentCategoryDetailsInput;
};


export type MutationCreateAppointmentCreditArgs = {
  patientId: Scalars['String'];
  consumerNetworkId: Scalars['String'];
  reasonId: Scalars['String'];
  professions?: Maybe<Array<Scalars['String']>>;
};


export type MutationCreateAppointmentInviteArgs = {
  input: AppointmentInviteInput;
};


export type MutationCreateAppointmentInviteV2Args = {
  input: AppointmentInviteInput;
};


export type MutationCreateAppointmentInviteV3Args = {
  input: AppointmentInviteInput;
};


export type MutationCreateAppointmentMediumConfigurationArgs = {
  configurationId: Scalars['String'];
  mediums?: Maybe<Array<Maybe<MediumInput>>>;
};


export type MutationCreateCarePlanDraftArgs = {
  input: CreateCarePlanDraftInput;
};


export type MutationCreateConsultationAudioSessionArgs = {
  consultationId: Scalars['ID'];
  consultantId: Scalars['ID'];
  patientId: Scalars['ID'];
};


export type MutationCreateConsultationFurtherInfoArgs = {
  consultationId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};


export type MutationCreateConsultationReferralArgs = {
  consultationId: Scalars['ID'];
  input: ConsultationReferralInput;
};


export type MutationCreateConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
  input: ConsultationSickNoteInput;
};


export type MutationCreateConsultationTestArgs = {
  consultationId: Scalars['ID'];
  input: ConsultationTestInput;
};


export type MutationCreateConsumerNetworkAndContractArgs = {
  contract: ContractInput;
};


export type MutationCreateGhostConsultationArgs = {
  input: CreateGhostConsultationInput;
};


export type MutationCreatePartnerMembershipCodeArgs = {
  membershipCode: CreatePartnerMembershipCodeInput;
};


export type MutationCreatePatientAlertArgs = {
  patientId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationCreatePlanArgs = {
  plan: CreatePlanInput;
};


export type MutationCreatePortalUserArgs = {
  portalUser: PortalUserInput;
};


export type MutationCreatePrescriptionArgs = {
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
  drugs?: Maybe<Array<Maybe<PrescriptionDrugInput>>>;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationCreateProfessionArgs = {
  input?: Maybe<CreateProfessionInput>;
};


export type MutationCreateServiceTypeArgs = {
  input?: Maybe<CreateServiceTypeInput>;
};


export type MutationCreateTenancyPartnerArgs = {
  partner: TenancyPartnerInput;
};


export type MutationCreateWorkflowArgs = {
  appointment?: Maybe<WorkflowAppointmentActionInput>;
  letter?: Maybe<WorkflowLetterActionInput>;
  investigation?: Maybe<WorkflowInvestigationActionInput>;
  referral?: Maybe<WorkflowReferralActionInput>;
  other?: Maybe<WorkflowOtherActionInput>;
  data: WorkflowInput;
};


export type MutationDeleteActionDraftArgs = {
  input: CarePlanDeleteActionDraftInput;
};


export type MutationDeleteAdminDrugAlertArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAppointmentCategoryArgs = {
  categoryId: Scalars['String'];
};


export type MutationDeleteAppointmentWaitTimeConfigsArgs = {
  input?: Maybe<DeleteAppointmentWaitTimeConfigsInput>;
};


export type MutationDeleteCarePlanDraftArgs = {
  input: DeleteCarePlanDraftInput;
};


export type MutationDeleteConsumerNetworkAndContractArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGoalDraftArgs = {
  input: CarePlanDeleteGoalDraftInput;
};


export type MutationDeletePartnerArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationDeletePartnerBrandingArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationDeletePartnerMembershipCodeArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePatientArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePlanArgs = {
  planId: Scalars['ID'];
};


export type MutationDeleteServiceTypeArgs = {
  uuid: Scalars['ID'];
};


export type MutationDeleteThePatientArgs = {
  id: Scalars['Int'];
};


export type MutationDeregisterGpAtHandArgs = {
  patientId: Scalars['ID'];
};


export type MutationDissociateGoalAndActionDraftArgs = {
  input: CarePlanDissociateGoalAndActionDraftInput;
};


export type MutationDuplicatePrescriptionArgs = {
  prescriptionId: Scalars['ID'];
};


export type MutationEditActionDraftArgs = {
  input: CarePlanEditActionDraftInput;
};


export type MutationEditFamilyMemberArgs = {
  id: Scalars['Int'];
  input: FamilyMember;
};


export type MutationEditGoalDraftArgs = {
  input: CarePlanEditGoalDraftInput;
};


export type MutationEndAudioCallArgs = {
  consultationId: Scalars['ID'];
};


export type MutationEndVideoCallArgs = {
  consultationId: Scalars['ID'];
  sessionId: Scalars['ID'];
};


export type MutationGpSurgerySendFaxArgs = {
  id: Scalars['ID'];
  faxNumberId: Scalars['String'];
};


export type MutationHealthCheckInvalidateAnswersArgs = {
  locale: Scalars['String'];
  memberId: Scalars['ID'];
  timeZoneOffset: Scalars['String'];
};


export type MutationHealthCheckSubmitAssessmentArgs = {
  locale: Scalars['String'];
  memberId: Scalars['ID'];
  reason: Scalars['String'];
  riskLevel: Scalars['String'];
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']>;
  termsAndConditionsText?: Maybe<Scalars['String']>;
  timeZoneOffset: Scalars['String'];
};


export type MutationLinkPatientToTransactionArgs = {
  patientUuid: Scalars['ID'];
  transactionId: Scalars['ID'];
  transactionType: Scalars['String'];
};


export type MutationLookupDemographicsArgs = {
  patientId: Scalars['ID'];
};


export type MutationMarkEhrNotesSensitiveArgs = {
  appointmentId: Scalars['ID'];
};


export type MutationMarkPrescriptionPrintedArgs = {
  id: Scalars['ID'];
};


export type MutationMarkRepeatPrescriptionAsStoppedArgs = {
  templateId: Scalars['ID'];
};


export type MutationOverrideIdentityCheckArgs = {
  patientId: Scalars['ID'];
  identityCheckId?: Maybe<Scalars['ID']>;
};


export type MutationPayAppointmentArgs = {
  id: Scalars['Int'];
  credit_card_id: Scalars['Int'];
  patient_id?: Maybe<Scalars['Int']>;
};


export type MutationPostPatientDemographicArgs = {
  id: Scalars['Int'];
  demographic?: Maybe<DemographicInformation>;
};


export type MutationPostcodeLookupArgs = {
  postcode: Scalars['String'];
};


export type MutationPrescriptionChangeStateArgs = {
  id: Scalars['ID'];
  action: PrescriptionStateActionType;
  reason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
};


export type MutationPrescriptionMarkDrugsDispatchedArgs = {
  id: Scalars['ID'];
};


export type MutationPrescriptionPdfFileArgs = {
  id: Scalars['ID'];
};


export type MutationPrescriptionRejectPharmacySupplierArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationPrescriptionRetryFaxArgs = {
  id: Scalars['ID'];
};


export type MutationPrescriptionRetryPdfArgs = {
  id: Scalars['ID'];
};


export type MutationPrivacyPolicyDecisionArgs = {
  decision: Scalars['Boolean'];
  noticeVersionId: Scalars['String'];
};


export type MutationPromoteCarePlanDraftArgs = {
  input: PromoteCarePlanDraftInput;
};


export type MutationRateAppointmentArgs = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationRateConsultationArgs = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationReactivateRedemptionArgs = {
  patientId: Scalars['ID'];
  redemptionId: Scalars['ID'];
};


export type MutationReallocateShiftArgs = {
  filters?: Maybe<ReallocateShiftFilters>;
};


export type MutationRegenerateClinicianPinArgs = {
  clinicianId: Scalars['ID'];
};


export type MutationRegisterNhsMinorArgs = {
  patient?: Maybe<NhsPatient>;
};


export type MutationRegisterNhsPatientArgs = {
  patient?: Maybe<NhsPatient>;
};


export type MutationRejoinConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationRemoveAppointmentCreditArgs = {
  patientId: Scalars['ID'];
  appointmentCreditId: Scalars['ID'];
};


export type MutationRemoveCarerArgs = {
  relationshipId: Scalars['String'];
};


export type MutationRemoveClinicFromServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinic_id: Scalars['ID'];
};


export type MutationRemoveClinicianFromServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinician_id: Scalars['ID'];
};


export type MutationRemoveConsultationFurtherInfoArgs = {
  consultationId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationRemoveConsultationRecordingsArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveConsultationReferralArgs = {
  consultationId: Scalars['ID'];
  referralId: Scalars['ID'];
};


export type MutationRemoveConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationRemoveConsultationTestArgs = {
  consultationId: Scalars['ID'];
  type: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationRemoveConsumerNetworkFromServiceTypeArgs = {
  service_type_uuid?: Maybe<Scalars['ID']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
};


export type MutationRemoveDrugFromPrescriptionArgs = {
  id?: Maybe<Scalars['ID']>;
  prescriptionDrugId?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationRemoveFromAllergiesArgs = {
  id: Scalars['Int'];
  patient_id: Scalars['Int'];
};


export type MutationRemoveFromConditionsArgs = {
  id: Scalars['Int'];
  patient_id: Scalars['Int'];
};


export type MutationRemoveFromMedicationsArgs = {
  id: Scalars['Int'];
  patient_id: Scalars['Int'];
};


export type MutationRemovePatientAlertArgs = {
  patientId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationRemovePrescriptionArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveRedemptionArgs = {
  patientId: Scalars['ID'];
  redemptionId: Scalars['ID'];
};


export type MutationRemoveReferralArgs = {
  referralId: Scalars['ID'];
};


export type MutationReportCallStepArgs = {
  consultationId: Scalars['String'];
  step: CallStep;
  appName: Scalars['String'];
  appVersion: Scalars['String'];
};


export type MutationRequestPrescriptionDeliveryArgs = {
  id: Scalars['ID'];
  addressId: Scalars['ID'];
  supplierId: Scalars['ID'];
};


export type MutationRequestSignerTokenArgs = {
  input: SignerTokenInput;
};


export type MutationRequestSyncShiftsReportArgs = {
  input: RequestSyncShiftsReportInput;
};


export type MutationResendInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordByIdArgs = {
  id: Scalars['ID'];
};


export type MutationReviewContributionArgs = {
  vote: Scalars['String'];
  comment: Scalars['String'];
  contributionId: Scalars['String'];
};


export type MutationRevokeRecordingConsentArgs = {
  id: Scalars['ID'];
};


export type MutationSaveAppointmentWaitTimeConfigsArgs = {
  input?: Maybe<AppointmentWaitTimeConfigsInput>;
};


export type MutationSaveServiceTypeArgs = {
  input?: Maybe<SaveServiceTypeInput>;
};


export type MutationSelectConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationSendAudioStreamArgs = {
  audioStream: Scalars['String'];
  consultationId: Scalars['String'];
};


export type MutationSendPatientReferralArgs = {
  input: SendPatientReferralInput;
};


export type MutationSendPrescriptionToHomeArgs = {
  id: Scalars['ID'];
};


export type MutationSendPrescriptionToPharmacyArgs = {
  id: Scalars['ID'];
  pharmacy_id: Scalars['ID'];
};


export type MutationSendPrescriptionToPharmacyByReferenceArgs = {
  prescriptionId: Scalars['ID'];
  pharmacyReference: Scalars['String'];
};


export type MutationSendReferralEmailArgs = {
  patientId: Scalars['ID'];
  referralId: Scalars['ID'];
};


export type MutationSendSlackMessageArgs = {
  body: SlackMessageInput;
};


export type MutationSetConsultantRtmAvailabilityArgs = {
  consultantId: Scalars['ID'];
  available: Scalars['Boolean'];
};


export type MutationSetConsultationExemptedArgs = {
  id: Scalars['ID'];
};


export type MutationSetInsuranceClaimExemptedArgs = {
  id: Scalars['ID'];
};


export type MutationSignPrescriptionArgs = {
  id: Scalars['ID'];
  input: PrescriptionSignatureRequestInput;
};


export type MutationStartAudioCallArgs = {
  consultationId: Scalars['ID'];
};


export type MutationStartIdVerificationArgs = {
  external_reference: Scalars['String'];
};


export type MutationStartVideoCallArgs = {
  consultationId: Scalars['ID'];
  sessionId: Scalars['ID'];
  activeParticipants?: Maybe<Array<VideoSessionUserTypes>>;
};


export type MutationSubmitMultimediaFeedbackArgs = {
  input: MultimediaFeedbackInput;
};


export type MutationSuggestAllergiesArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSuggestConditionsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSuggestDrugDirectionsArgs = {
  drugId?: Maybe<Scalars['ID']>;
  drugName: Scalars['String'];
  dosage: Scalars['String'];
  duration: Scalars['String'];
  region: Scalars['String'];
};


export type MutationSuggestLabelsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSuggestMedicationsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSyncHumanityArgs = {
  filters?: Maybe<SyncHumanityFilters>;
};


export type MutationTransferOwnershipArgs = {
  new_owner_id: Scalars['ID'];
  old_owner_id: Scalars['ID'];
};


export type MutationTransferPatientToNhsArgs = {
  id: Scalars['Int'];
  patient?: Maybe<NhsPatient>;
};


export type MutationTriggerWorkflowArgs = {
  idempotency_key: Scalars['String'];
  workflow_definition_key: Scalars['String'];
  tenant_id: Scalars['ID'];
  variables: Array<WorkflowVariableInput>;
};


export type MutationUnAssignAppointmentCategoryFromAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  categoryId: Scalars['String'];
};


export type MutationUnAssignServiceTypesToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  serviceIds: Array<Maybe<Scalars['String']>>;
};


export type MutationUnAssignTenancyFromAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  assignmentIds: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateAdminDrugArgs = {
  adminDrug: AdminDrugInput;
};


export type MutationUpdateAdminDrugAlertArgs = {
  adminDrugAlert: AdminDrugAlertInput;
};


export type MutationUpdateAppointmentArgs = {
  id: Scalars['Int'];
  gp_consent?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateAppointmentGpConsentArgs = {
  id: Scalars['ID'];
  gp_consent: Scalars['Boolean'];
};


export type MutationUpdateAppointmentMediumConfigurationArgs = {
  configurationId: Scalars['String'];
  mediums?: Maybe<Array<Maybe<MediumInput>>>;
};


export type MutationUpdateAppointmentWaitTimeConfigsArgs = {
  input?: Maybe<AppointmentWaitTimeConfigsInput>;
};


export type MutationUpdateConsultationArgs = {
  id: Scalars['ID'];
  input?: Maybe<ConsultationInput>;
};


export type MutationUpdateConsultationDiagnosisArgs = {
  consultationId: Scalars['String'];
  asessment?: Maybe<Scalars['String']>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};


export type MutationUpdateConsultationGpConsentArgs = {
  id: Scalars['ID'];
  gpConsent?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateConsultationNoteArgs = {
  consultationId: Scalars['ID'];
  input?: Maybe<ConsultationNoteInput>;
};


export type MutationUpdateConsultationReferralArgs = {
  consultationId: Scalars['ID'];
  referralId: Scalars['ID'];
  input: ConsultationReferralInput;
};


export type MutationUpdateConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
  sickNoteId: Scalars['ID'];
  input: ConsultationSickNoteInput;
};


export type MutationUpdateConsultationStatusArgs = {
  id: Scalars['ID'];
  status?: Maybe<ConsultationStatus>;
  exempted?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateConsultationTestArgs = {
  consultationId: Scalars['ID'];
  id: Scalars['ID'];
  type: Scalars['String'];
  input: ConsultationTestInput;
};


export type MutationUpdateConsultationsArgs = {
  ids: Array<Scalars['ID']>;
  changes: Array<ConsultationInput>;
};


export type MutationUpdateDrugInPrescriptionArgs = {
  id?: Maybe<Scalars['ID']>;
  prescriptionDrugId?: Maybe<Scalars['ID']>;
  drug: PrescriptionDrugInput;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationUpdateFeatureStatesArgs = {
  featureStates: Array<FeatureStateInput>;
};


export type MutationUpdateFlagArgs = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateGpArgs = {
  id: Scalars['Int'];
  formData: GpInformation;
};


export type MutationUpdateGpInAppointmentArgs = {
  appointmentId: Scalars['Int'];
  formData: GpInformation;
};


export type MutationUpdateInsuranceCompanyArgs = {
  id: Scalars['Int'];
  formData: InsuranceInformation;
};


export type MutationUpdateMarkedAsSentArgs = {
  id: Scalars['ID'];
  markedAsSent?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMedicalHistoryArgs = {
  patient_id: Scalars['Int'];
  medical_history: Scalars['String'];
};


export type MutationUpdateMopPatientArgs = {
  id?: Maybe<Scalars['ID']>;
  patient: MopPatientInput;
};


export type MutationUpdatePanelArgs = {
  state: Scalars['String'];
  id: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  appointmentSuggested: Scalars['Boolean'];
  reviewerComments?: Maybe<Scalars['String']>;
  profileComments?: Maybe<Array<Maybe<TestReportProfileComment>>>;
  supportMessage?: Maybe<Scalars['String']>;
};


export type MutationUpdatePartnerBrandingArgs = {
  partnerId?: Maybe<Scalars['ID']>;
  branding?: Maybe<PartnerBrandingInput>;
};


export type MutationUpdatePartnerMembershipCodeArgs = {
  membershipCode: PartnerMembershipCodeInput;
};


export type MutationUpdatePatientArgs = {
  id: Scalars['Int'];
  patient: ProfileInformation;
};


export type MutationUpdatePatientAlertArgs = {
  patientId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationUpdatePatientDetailsArgs = {
  id: Scalars['Int'];
  patient: ProfileInformation;
};


export type MutationUpdatePatientNameArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdatePortalUserArgs = {
  portalUser: PortalUserUpdateInput;
};


export type MutationUpdatePrimaryGuardianArgs = {
  dependantId: Scalars['String'];
  dependantUuid: Scalars['String'];
  carerUuid: Scalars['String'];
};


export type MutationUpdateReferralArgs = {
  referralId: Scalars['ID'];
  input: ConsultationReferralInput;
};


export type MutationUpdateReportStateArgs = {
  state: Scalars['String'];
  id: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  appointmentSuggested: Scalars['Boolean'];
  reviewerComments?: Maybe<Scalars['String']>;
  supportMessage?: Maybe<Scalars['String']>;
};


export type MutationUpdateTenancyPartnerArgs = {
  partner: TenancyPartnerUpdateInput;
};


export type MutationUpdateTenancyPartnerParentArgs = {
  partnerId: Scalars['String'];
  newParentId: Scalars['String'];
};


export type MutationUploadConsultationFileArgs = {
  id: Scalars['ID'];
  fileAsBase64String: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationUploadMembersArgs = {
  members: PartnerMembersInput;
};


export type MutationValidateDrugArgs = {
  region: Scalars['String'];
  patientId: Scalars['ID'];
  drug: PrescriptionDrugInput;
  consultationId?: Maybe<Scalars['ID']>;
  prescriptionDrugId?: Maybe<Scalars['ID']>;
};


export type MutationValidatePatientDetailsArgs = {
  patient?: Maybe<NhsPatient>;
};


export type MutationValidateTokenArgs = {
  input: OtpCheckRequest;
};


export type MutationVoidPrescriptionArgs = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type NewConceptResource = {
  parentConcept: Scalars['String'];
  preferredLabel: Scalars['String'];
  ticket: Scalars['String'];
};

export type NewContributionResponse = {
  preferredLabel: Scalars['String'];
  iri: Scalars['String'];
  contributionId?: Maybe<Scalars['String']>;
};

export type NextOfKin = {
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
};

export type NextOfKinInput = {
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
};

export type NhsPatient = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  previous_names?: Maybe<Scalars['String']>;
  previous_last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  emergency_contact_details?: Maybe<Array<Maybe<EmergencyContactDetails>>>;
  date_of_birth?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_first_line?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  previous_practice_name?: Maybe<Scalars['String']>;
  birth_details?: Maybe<BirthDetailInput>;
  public_healthcare_details?: Maybe<PublicHealthcareDetailsInput>;
  previous_addresses?: Maybe<Array<Maybe<PreviousAddressesInput>>>;
  accepted_notice_versions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NlpReview = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<NlpReviewStatus>;
};

export enum NlpReviewStatus {
  NotApplicable = 'not_applicable',
  NotReviewed = 'not_reviewed',
  Reviewed = 'reviewed'
}

export type None = {
  /** @deprecated A pointless field used to work around GraphQL's disallowance of empty object types. */
  _: Scalars['Void'];
};

export type NoteAssistantConsent = {
  decisions: Array<NoteAssistantConsentDecision>;
  permission_granted: Scalars['Boolean'];
};

export type NoteAssistantConsentDecision = {
  decision: Scalars['Boolean'];
  decline_reason?: Maybe<Scalars['String']>;
  speaker_id: Scalars['String'];
};

export type NoteAssistantNote = {
  notes: Array<Scalars['String']>;
  final?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type NoteAssistantRecordingId = {
  recording_id: Scalars['String'];
};

export type NoteAssistantRecordingIdInput = {
  encoding: Scalars['String'];
  encounterId: Scalars['String'];
  encounterType?: Maybe<Scalars['String']>;
  languageTag?: Maybe<Scalars['String']>;
  sampleRate: Scalars['String'];
  sourceType: Scalars['String'];
  speakers: Array<NoteAssistantSpeaker>;
};

export type NoteAssistantSpeaker = {
  speaker_id: Scalars['String'];
  speaker_type: Scalars['String'];
};

export type Notice = {
  noticeId: Scalars['String'];
  name: Scalars['String'];
  noticeEnum?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  defaultDecision: Scalars['Boolean'];
  ordering?: Maybe<Scalars['Int']>;
  defaultLocale?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  noticeType?: Maybe<Scalars['String']>;
  noticeVersions?: Maybe<Array<NoticeVersion>>;
};

export type NoticeDecision = {
  decisionId: Scalars['ID'];
  userId: Scalars['ID'];
  noticeVersionId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  expired?: Maybe<Scalars['Boolean']>;
  date: Scalars['Date'];
};

export enum NoticeEnum {
  InformedConsent = 'INFORMED_CONSENT',
  MedicationHistoryAuthorization = 'MEDICATION_HISTORY_AUTHORIZATION'
}

export type NoticeVersion = {
  noticeVersionId: Scalars['String'];
  title: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  noticeId: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  longText?: Maybe<Scalars['String']>;
  acceptButtonText?: Maybe<Scalars['String']>;
  declineButtonText?: Maybe<Scalars['String']>;
  readMoreButtonText?: Maybe<Scalars['String']>;
  popupTitle?: Maybe<Scalars['String']>;
  popupText?: Maybe<Scalars['String']>;
  chatbotText?: Maybe<Scalars['String']>;
  updateTitle?: Maybe<Scalars['String']>;
  updateText?: Maybe<Scalars['String']>;
  onboardingText?: Maybe<Scalars['String']>;
  settingsText?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  errorTitle?: Maybe<Scalars['String']>;
  errorText?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type OrphanedSlot = {
  consultant_id?: Maybe<Scalars['ID']>;
  consultant_name?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Date']>;
  appointment_id?: Maybe<Scalars['ID']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  priority?: Maybe<Scalars['Int']>;
  slot_size?: Maybe<Scalars['Int']>;
  supply_network_id?: Maybe<Scalars['ID']>;
  category_id?: Maybe<Scalars['ID']>;
  recurrent?: Maybe<Scalars['Boolean']>;
  marked_for_removal?: Maybe<Scalars['Boolean']>;
  physical?: Maybe<Scalars['Boolean']>;
  digital_bookable?: Maybe<Scalars['Boolean']>;
  physical_bookable?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['ID']>;
  rtm?: Maybe<Scalars['Boolean']>;
  availability_upload_id?: Maybe<Scalars['ID']>;
  shift_id?: Maybe<Scalars['ID']>;
  text_bookable?: Maybe<Scalars['Boolean']>;
  local_time?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type OrphanedSlots = {
  total: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  slots: Array<OrphanedSlot>;
};

export type OtpCheckRequest = {
  otpPassword: Scalars['String'];
};

export type PagedTimelineEvent = {
  totalEvents?: Maybe<Scalars['Int']>;
  hasMore?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Array<Maybe<TimelineEvent>>>;
};

export type PaginatedMembershipPlan = {
  more: Scalars['Boolean'];
  limit: Scalars['Int'];
  total: Scalars['Int'];
  offset: Scalars['Int'];
  memberships: Array<Maybe<PartnerMembershipPlan>>;
};

export type Pagination = {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<Paging>>;
};

export type PaginationCriteria = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Paging = {
  id: Scalars['ID'];
};

export type Participant = {
  type: Scalars['String'];
  userId: Scalars['String'];
  participant?: Maybe<Patient>;
};

export type Partner = {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  status: PartnerStatus;
  regionId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  supplyNetworkIds?: Maybe<Array<Scalars['ID']>>;
  supplyNetworks?: Maybe<Array<PartnerSupplyNetwork>>;
  membersCount?: Maybe<Scalars['Int']>;
  features?: Maybe<Array<PartnerFeature>>;
  membershipCodesCount?: Maybe<Scalars['Int']>;
  membershipCodes?: Maybe<Array<PartnerMembershipCode>>;
  pricing?: Maybe<Array<PartnerPricing>>;
  branding?: Maybe<PartnerBranding>;
  plans?: Maybe<Array<PartnerPlan>>;
};

export type PartnerBranding = {
  id?: Maybe<Scalars['ID']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoCaption?: Maybe<Scalars['String']>;
  welcomeLogoUrl?: Maybe<Scalars['String']>;
  contentIconUrl?: Maybe<Scalars['String']>;
  contentTitle?: Maybe<Scalars['String']>;
  contentUrl?: Maybe<Scalars['String']>;
};

export type PartnerBrandingInput = {
  logoUrl?: Maybe<Scalars['String']>;
  logoCaption?: Maybe<Scalars['String']>;
  contentTitle?: Maybe<Scalars['String']>;
  contentUrl?: Maybe<Scalars['String']>;
};

export type PartnerFeature = {
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export type PartnerMembershipCode = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  validationKey?: Maybe<Scalars['String']>;
  validationProvider?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  messageClinician?: Maybe<Scalars['String']>;
};

export type PartnerMembershipCodeInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  validationKey?: Maybe<Scalars['String']>;
  validationProvider?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  messageClinician?: Maybe<Scalars['String']>;
};

export type PartnerMembershipPlan = {
  membership_id: Scalars['ID'];
  member_id: Scalars['ID'];
  state?: Maybe<EntryState>;
  contract?: Maybe<MembershipContract>;
  created_by: Scalars['String'];
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
};

export type PartnerMembersInput = {
  csv: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
};

export type PartnerPlan = {
  id: Scalars['ID'];
  partnerId: Scalars['ID'];
  partner: Partner;
  isDefault: Scalars['Boolean'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  braintreeToken?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['Boolean']>;
  membersCount?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  minorPrice?: Maybe<Scalars['Float']>;
  gpAppointmentPrice?: Maybe<Scalars['Float']>;
  dietitianAppointmentPrice?: Maybe<Scalars['Float']>;
  seniorNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  specialistAppointmentPrice?: Maybe<Scalars['Float']>;
  therapistAppointmentPrice?: Maybe<Scalars['Float']>;
  triageNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  advancedNursePractitionerAppointmentPrice?: Maybe<Scalars['Float']>;
  dermatologyGpAppointmentPrice?: Maybe<Scalars['Float']>;
  diabeticSpecialistNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  physiotherapistAppointmentPrice?: Maybe<Scalars['Float']>;
  sexualHealthSpecialistNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  travelHealthNurseAppointmentPrice?: Maybe<Scalars['Float']>;
};

export type PartnerPlanDefaults = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  supplyNetworks: Array<PartnerSupplyNetwork>;
  features: Array<PartnerFeature>;
  pricing: Array<PartnerPricingDefaults>;
  membershipPlan: MembershipPlan;
};

export type PartnerPricing = {
  profession: Scalars['String'];
  price: Scalars['Float'];
  currency: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type PartnerPricingDefaults = {
  profession: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  price: Scalars['Float'];
  waitTime?: Maybe<Scalars['Int']>;
  showInPortal: Scalars['Boolean'];
};

export enum PartnerStatus {
  Active = 'active',
  Retired = 'retired'
}

export type PartnerSupplyNetwork = {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
};

export type PastHumanitySync = {
  id: Scalars['ID'];
  created_at: Scalars['Date'];
  user_uuid?: Maybe<Scalars['ID']>;
  user_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['Date']>;
  clinician_count?: Maybe<Scalars['Int']>;
  status: HumanitySyncStatusEnum;
  last_synced_at?: Maybe<Scalars['Date']>;
  is_auto_sync?: Maybe<Scalars['Boolean']>;
  errors: Array<Maybe<Scalars['String']>>;
};

export type PastHumanitySyncs = {
  total: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  syncs: Array<Maybe<PastHumanitySync>>;
};

export type PathologyTest = {
  id?: Maybe<Scalars['Int']>;
  tdl_test_id?: Maybe<Scalars['Int']>;
  tdl_test_name?: Maybe<Scalars['String']>;
};

export type Patient = Subject & {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  allergies?: Maybe<Array<Maybe<Allergy>>>;
  api_key?: Maybe<Scalars['String']>;
  ask_for_gp_details?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  avatar_image_path?: Maybe<Scalars['String']>;
  birth_details?: Maybe<BirthDetails>;
  cancelled_appointments_count?: Maybe<Scalars['Int']>;
  carePlan?: Maybe<CarePlan>;
  clinical_api_key?: Maybe<Scalars['String']>;
  clinical_api_key_expires?: Maybe<Scalars['Int']>;
  completed_appointments_count?: Maybe<Scalars['Int']>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  consumer_network?: Maybe<ConsumerNetwork>;
  consumer_networks?: Maybe<Array<Maybe<ConsumerNetwork>>>;
  contact_details?: Maybe<ContactDetails>;
  clinical_commissioning_group?: Maybe<ClinicalComissioningGroup>;
  created_at?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  demographics?: Maybe<Demographics>;
  email?: Maybe<Scalars['String']>;
  family_accounts_count?: Maybe<Scalars['Int']>;
  account_owner?: Maybe<Patient>;
  account_owner_id?: Maybe<Scalars['ID']>;
  /** @deprecated use 'account_owner.contact_details' instead */
  account_owner_contact_details?: Maybe<ContactDetails>;
  /** @deprecated use 'account_owner.date_of_birth' instead */
  account_owner_dob?: Maybe<Scalars['Date']>;
  /** @deprecated use 'account_owner.full_name' instead */
  account_owner_name?: Maybe<Scalars['String']>;
  accounts_count?: Maybe<Scalars['Int']>;
  pendingInvites?: Maybe<Array<Maybe<AppointmentInvite>>>;
  redeemedCodes: Array<Maybe<RedeemedCode>>;
  emergency_contact_details?: Maybe<Array<Maybe<PatientEmergencyContactDetails>>>;
  exact_match?: Maybe<Scalars['Boolean']>;
  family_accounts?: Maybe<Array<Maybe<FamilyAccount>>>;
  family_accounts_owner?: Maybe<Scalars['Boolean']>;
  first_name: Scalars['String'];
  flags?: Maybe<Array<Maybe<Flag>>>;
  full_address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  gp_details_id?: Maybe<Scalars['Int']>;
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_full_address?: Maybe<Scalars['String']>;
  gp_name?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
  gp_details?: Maybe<GpDetails>;
  height?: Maybe<Scalars['String']>;
  healthcare_identifier?: Maybe<HealthcareIdentifier>;
  id_verification_status?: Maybe<VerificationStatus>;
  id_verification?: Maybe<VerificationStatus>;
  identity_check?: Maybe<IdentityCheck>;
  image?: Maybe<Scalars['String']>;
  insurance_company_id?: Maybe<Scalars['Int']>;
  insurance_membership_id?: Maybe<Scalars['Int']>;
  insurance_membership_number?: Maybe<Scalars['String']>;
  is_password_present?: Maybe<Scalars['Boolean']>;
  just_in_time_notices?: Maybe<Array<Maybe<PrivacyNotice>>>;
  kong?: Maybe<KongToken>;
  last_name: Scalars['String'];
  medical_history?: Maybe<Scalars['String']>;
  medications?: Maybe<Array<Maybe<Medication>>>;
  minor?: Maybe<Scalars['Boolean']>;
  next_of_kin?: Maybe<NextOfKin>;
  no_show_appointments_count?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  patient_alerts?: Maybe<Array<PatientAlert>>;
  patient_consultations?: Maybe<Array<Maybe<Consultation>>>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  pending_appointments_count?: Maybe<Scalars['Int']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  preferred_consumer_network?: Maybe<ConsumerNetwork>;
  preferred_consumer_network_id?: Maybe<Scalars['Int']>;
  profile_locked?: Maybe<Scalars['Boolean']>;
  profile_locks: Array<ProfileLock>;
  previous_names?: Maybe<Scalars['String']>;
  previous_practice_name?: Maybe<Scalars['String']>;
  previous_addresses?: Maybe<Array<Maybe<PreviousAddresses>>>;
  privacy_notices?: Maybe<Array<Maybe<PrivacyNotice>>>;
  privacy_settings?: Maybe<Array<Maybe<PrivacyNotice>>>;
  promotion?: Maybe<Promotion>;
  public_healthcare_details?: Maybe<PublicHealthcareDetails>;
  region?: Maybe<Region>;
  region_id?: Maybe<Scalars['Int']>;
  service_types?: Maybe<Array<ServiceType>>;
  smoking_status?: Maybe<SmokingStatus>;
  total_appointments_count?: Maybe<Scalars['Int']>;
  upcoming_appointments_count?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
  account_state?: Maybe<Scalars['String']>;
  queued_for_activation?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field no longer supported */
  verified?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<TimelineEvent>>;
  futureConsultations?: Maybe<Array<TimelineEvent>>;
  historyV2: PatientHistory;
  pagedHistory?: Maybe<PagedTimelineEvent>;
  canCreatePrescriptionPdf?: Maybe<Validity>;
  public_healthcare_admission?: Maybe<PublicHealthcareAdmission>;
  patient_metrics?: Maybe<PatientMetrics>;
  timezone_id?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  timezone?: Maybe<Scalars['String']>;
  addressBasedTimezone?: Maybe<Scalars['String']>;
};


export type PatientPendingInvitesArgs = {
  includingDigital?: Maybe<Scalars['Boolean']>;
};


export type PatientRedeemedCodesArgs = {
  patientId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type PatientService_TypesArgs = {
  include?: Maybe<Array<Scalars['String']>>;
  including_digital?: Maybe<Scalars['Boolean']>;
};


export type PatientHistoryArgs = {
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  dangerousConsultationStatuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientFutureConsultationsArgs = {
  dangerousConsultationStatuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientHistoryV2Args = {
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  dangerousConsultationStatuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientPagedHistoryArgs = {
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientPatient_MetricsArgs = {
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PatientAlert = {
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdByName?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedByName?: Maybe<Scalars['String']>;
  viewer?: Maybe<Viewer>;
};

export enum PatientAlertsSortBy {
  Created = 'CREATED',
  Updated = 'UPDATED'
}

export type PatientAlertsSortOptions = {
  by?: Maybe<PatientAlertsSortBy>;
  order?: Maybe<PatientAlertsSortOrder>;
};

export enum PatientAlertsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PatientAnswer = {
  id: Scalars['ID'];
  dateAnswered: Scalars['Float'];
  answerText: Scalars['String'];
};

export type PatientConsumerNetwork = {
  id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  can_be_cancelled?: Maybe<Scalars['Boolean']>;
  can_be_deregistered?: Maybe<Scalars['Boolean']>;
  consumer_network?: Maybe<ConsumerNetwork>;
  gp_at_hand?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<PatientConsumerNetworkHistory>>>;
  is_default?: Maybe<Scalars['Boolean']>;
  is_preferred?: Maybe<Scalars['Boolean']>;
  is_retired?: Maybe<Scalars['Boolean']>;
  started_on?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  plan?: Maybe<SubscriptionPlan>;
  credit_card?: Maybe<PortalCreditCard>;
  subscription?: Maybe<Subscription>;
};

export type PatientConsumerNetworkHistory = {
  id: Scalars['ID'];
  created_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
};

export type PatientConsumerNetworkPayload = {
  networkId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
};

export type PatientEmergencyContactDetails = {
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type PatientHistory = {
  events: Array<TimelineEvent>;
  errors: Array<PatientHistoryError>;
};

export type PatientHistoryError = {
  type: TimelineEventType;
  message: Scalars['String'];
  fullError: Scalars['String'];
};

export type PatientMetric = {
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  source: PatientMetricSource;
  timestamp?: Maybe<Scalars['Date']>;
  observationType?: Maybe<PatientMetricObservationType>;
  value?: Maybe<Scalars['JSON']>;
  values?: Maybe<Array<Maybe<PatientMetricValue>>>;
  performer: Scalars['String'];
};

export enum PatientMetricObservationType {
  Height = 'height',
  Weight = 'weight',
  SmokingStatus = 'smoking_status',
  BloodPressure = 'blood_pressure',
  Diastolic = 'diastolic',
  Systolic = 'systolic'
}

export type PatientMetrics = {
  id: Scalars['ID'];
  height: Array<PatientMetric>;
  weight: Array<PatientMetric>;
  smoking_status: Array<PatientMetric>;
  blood_pressure: Array<PatientMetric>;
};

export enum PatientMetricSource {
  LegacyCore = 'legacy_core',
  ChrPrimaryStore = 'chr_primary_store',
  ChrCore = 'chr_core'
}

export type PatientMetricValue = {
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type PatientQuestion = TimelineEventInterface & {
  id: Scalars['ID'];
  patient: Patient;
  dateAsked: Scalars['Float'];
  text: Scalars['String'];
  fileCount: Scalars['Int'];
  files: Array<FileUpload>;
  state: PatientQuestionState;
  assignedTo?: Maybe<Consultant>;
  suggestedTags: Array<Tag>;
  answer?: Maybe<PatientAnswer>;
  eventType?: Maybe<TimelineEventType>;
  eventTime?: Maybe<Scalars['Date']>;
};

export enum PatientQuestionState {
  Open = 'Open',
  Assigned = 'Assigned',
  Done = 'Done'
}

export type PatientReferral = {
  patient_id: Scalars['ID'];
  contract_uuid: Scalars['ID'];
  referred_by_portal_login_id?: Maybe<Scalars['String']>;
  patient_email_sent_at: Scalars['String'];
  patient_email_reminder_scheduled_for: Scalars['String'];
  patient_email_reminder_sent_at?: Maybe<Scalars['String']>;
  partner_email_sent_at?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
};

export type PatientResentInvitation = {
  id?: Maybe<Scalars['ID']>;
};

export type PatientResult = Patient | RetrievalError;

export type PatientSearchCriteria = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Payer = {
  name?: Maybe<Scalars['String']>;
};

export type Payment = {
  total?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  payer?: Maybe<Payer>;
};

export type PaymentProvider = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type PgmConcept = {
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  absent?: Maybe<Scalars['Boolean']>;
  probability?: Maybe<Scalars['Float']>;
};

export type PgmDiagnosis = {
  id: Scalars['ID'];
  conversationId?: Maybe<Scalars['String']>;
  pgmUserId?: Maybe<Scalars['Int']>;
  conditions?: Maybe<Scalars['String']>;
  evidence?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PgmQuestion = {
  answers?: Maybe<Array<Maybe<Scalars['String']>>>;
  question?: Maybe<Scalars['String']>;
};

export type PgmReportCase = {
  age?: Maybe<Scalars['Int']>;
  completed_at?: Maybe<Scalars['Date']>;
  conversation_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  sex?: Maybe<Gender>;
  status: PgmReportStatus;
  user_id: Scalars['ID'];
};

export type PgmReportConditionFull = {
  certainty_gauge: Scalars['Float'];
  common_treatment: PgmReportTreatment;
  extra_overview?: Maybe<Array<Maybe<PgmReportExtra>>>;
  icd_10?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is_top_differential: Scalars['Boolean'];
  layman_name: Scalars['String'];
  might_cause_distress: Scalars['Boolean'];
  name: Scalars['String'];
  nonpositive_related_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  positive_related_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  probability: Scalars['Float'];
  probability_label: Scalars['String'];
  probability_text: Scalars['String'];
  short_description: Scalars['String'];
  short_overview: Scalars['String'];
};

export type PgmReportConditionSummary = {
  common_treatment: PgmReportTreatment;
  extra_overview: Array<Maybe<PgmReportExtra>>;
  id: Scalars['ID'];
  layman_name: Scalars['String'];
  leaflet_link?: Maybe<Scalars['String']>;
  might_cause_distress: Scalars['Boolean'];
  positive_related_symptoms: Array<Maybe<PgmReportSymptom>>;
  probability_layman_label: Scalars['String'];
  probability_level: Scalars['Int'];
  short_overview: Scalars['String'];
  triage?: Maybe<PgmReportTriage>;
};

export enum PgmReportDecision {
  SelfCare = 'SELF_CARE',
  Pharmacy = 'PHARMACY',
  Gp = 'GP',
  GpUrgent = 'GP_URGENT',
  Hospital = 'HOSPITAL',
  HospitalUrgent = 'HOSPITAL_URGENT'
}

export type PgmReportDiagnosisLegacy = {
  conditions?: Maybe<Array<Maybe<PgmReportConditionFull>>>;
  unexplained_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
};

export type PgmReportDiagnosisSummary = {
  conditions?: Maybe<Array<Maybe<PgmReportConditionSummary>>>;
  might_cause_distress?: Maybe<Scalars['Boolean']>;
  might_cause_distress_text?: Maybe<Scalars['String']>;
};

export type PgmReportEvidence = {
  id: Scalars['String'];
  layman_name: Scalars['String'];
  name: Scalars['String'];
  inferred: Scalars['Boolean'];
  state: Scalars['String'];
};

export type PgmReportExtra = {
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type PgmReportFailure = {
  code: Scalars['String'];
  message: Scalars['String'];
  unsupported_case_type?: Maybe<Scalars['String']>;
};

export type PgmReportLegacy = {
  diagnosis: PgmReportDiagnosisLegacy;
  triage?: Maybe<PgmReportTriage>;
  triage_enabled: Scalars['Boolean'];
};

export type PgmReportOutcome = {
  answered_all_questions: Scalars['Boolean'];
  case: PgmReportCase;
  conditions?: Maybe<Array<Maybe<PgmReportConditionFull>>>;
  evidence?: Maybe<Array<Maybe<PgmReportEvidence>>>;
  failure_reason?: Maybe<PgmReportFailure>;
  id: Scalars['String'];
  status: PgmReportStatus;
  triage: PgmReportTriage;
  triage_enabled: Scalars['Boolean'];
  unexplained_red_flag_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  unexplained_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
};

export enum PgmReportStatus {
  Scheduled = 'scheduled',
  Started = 'started',
  Completed = 'completed'
}

export type PgmReportSummary = {
  case?: Maybe<PgmReportCase>;
  created_at?: Maybe<Scalars['Date']>;
  diagnosis?: Maybe<PgmReportDiagnosisSummary>;
  id?: Maybe<Scalars['ID']>;
  nonpositive_symptons?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  positive_symptons?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  triage?: Maybe<PgmReportTriage>;
  triage_enabled?: Maybe<Scalars['Boolean']>;
};

export type PgmReportSymptom = {
  id: Scalars['ID'];
  layman_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type PgmReportTreatment = {
  description?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['Int']>;
};

export type PgmReportTriage = {
  decision: PgmReportDecision;
  diseases?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  explanation?: Maybe<Scalars['String']>;
  rule_name?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  soft_triage_text?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type PharmacyAddress = {
  addressLine?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export enum PlaceEnum {
  Gppractices = 'gppractices',
  Pharmacies = 'pharmacies',
  AccidentAndEmergency = 'accident_and_emergency',
  SexualHealthServices = 'sexual_health_services'
}

export type PlaceResource = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<PlaceResourceAddress>;
};

export type PlaceResourceAddress = {
  line?: Maybe<Array<Maybe<Scalars['String']>>>;
  postalCode?: Maybe<Scalars['String']>;
};

export type PlaceResourceInput = {
  type?: Maybe<PlaceEnum>;
  postalCode: Scalars['String'];
  countryCode: Scalars['String'];
  radiusMeters: Scalars['String'];
};

export type PortalCreditCard = {
  cardType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last4?: Maybe<Scalars['String']>;
};

export type PortalTransaction = {
  braintreeStatus?: Maybe<Scalars['String']>;
  braintreeToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  creditCard?: Maybe<PortalCreditCard>;
  currencySymbol: Scalars['String'];
  id: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  purchaseType?: Maybe<Scalars['String']>;
  refundable?: Maybe<Scalars['Boolean']>;
  refundedAt?: Maybe<Scalars['Date']>;
  showReason?: Maybe<Scalars['String']>;
};

export type PortalUser = Paging & {
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  timezone: Scalars['String'];
  securityPhoneNumber?: Maybe<Scalars['String']>;
  twoFactorAuthActive: Scalars['Boolean'];
  active: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  roles?: Maybe<Array<PortalUserRole>>;
  partnerIds?: Maybe<Array<Scalars['ID']>>;
  partners?: Maybe<Array<TenancyPartner>>;
  filteredPartners?: Maybe<Array<TenancyPartner>>;
};

export type PortalUserInput = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  securityPhoneNumber?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  twoFactorAuthActive: Scalars['Boolean'];
  active: Scalars['Boolean'];
  roleIds: Array<Scalars['ID']>;
  partnerIds?: Maybe<Array<Scalars['ID']>>;
};

export type PortalUserResults = Pagination & {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<PortalUser>>;
};

export type PortalUserRole = {
  id: Scalars['ID'];
  name: Scalars['String'];
  key: Scalars['String'];
};

export type PortalUsersList = {
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<PortalUser>;
};

export type PortalUserSortCriteria = {
  field?: Maybe<PortalUserSortField>;
  order?: Maybe<SortOrder>;
};

export enum PortalUserSortField {
  FullName = 'fullName',
  Email = 'email',
  Timezone = 'timezone',
  TwoFactorAuthActive = 'twoFactorAuthActive',
  CreatedAt = 'createdAt',
  Active = 'active'
}

export type PortalUserUpdateInput = {
  id: Scalars['ID'];
  fullName: Scalars['String'];
  securityPhoneNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  timezone: Scalars['String'];
  twoFactorAuthActive: Scalars['Boolean'];
  active: Scalars['Boolean'];
  roleIds?: Maybe<Array<Scalars['ID']>>;
  partnerIds?: Maybe<Array<Scalars['ID']>>;
};

export type Practice = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  full_address?: Maybe<PracticeAddress>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  arrival_instructions?: Maybe<Scalars['String']>;
  timezone_id?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  timezone?: Maybe<Scalars['String']>;
};

export type PracticeAddress = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
};

export type Prescription = Paging & {
  id: Scalars['ID'];
  consultationId?: Maybe<Scalars['ID']>;
  consultation?: Maybe<Consultation>;
  appointment?: Maybe<Appointment>;
  consultant?: Maybe<Consultant>;
  patientId?: Maybe<Scalars['ID']>;
  patient?: Maybe<Patient>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  drugs: Array<PrescriptionDrug>;
  prescriptionState: Scalars['String'];
  signatureState?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<Scalars['String']>;
  pharmacyId?: Maybe<Scalars['String']>;
  pharmacySupplierId?: Maybe<Scalars['String']>;
  pharmacyDetails?: Maybe<Scalars['String']>;
  finalized?: Maybe<Scalars['Boolean']>;
  draft?: Maybe<Scalars['Boolean']>;
  canNotBeVoided?: Maybe<Scalars['Boolean']>;
  canBeMarkedAsPrinted?: Maybe<Scalars['Boolean']>;
  canBeConfirmedReceived?: Maybe<Scalars['Boolean']>;
  canBeApproved?: Maybe<Scalars['Boolean']>;
  canBeFaxed?: Maybe<Scalars['Boolean']>;
  approvalNeeded?: Maybe<Scalars['Boolean']>;
  canPharmacyBeRejected?: Maybe<Scalars['Boolean']>;
  latestComment?: Maybe<Scalars['String']>;
  actions?: Maybe<Array<Maybe<Scalars['String']>>>;
  audit?: Maybe<Array<Maybe<PrescriptionAudit>>>;
  homeDeliveryAvailable?: Maybe<Scalars['Boolean']>;
  deliveryMethods?: Maybe<PrescriptionDeliveryMethods>;
  deliveryAddress?: Maybe<PrescriptionDeliveryAddress>;
  formattedAddress?: Maybe<Scalars['String']>;
  sendTo?: Maybe<Scalars['String']>;
};


export type PrescriptionAuditArgs = {
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PrescriptionAudit = {
  id: Scalars['ID'];
  auditId: Scalars['ID'];
  babylonRequestId?: Maybe<Scalars['ID']>;
  consumerNetwork?: Maybe<Scalars['Int']>;
  eventDetails?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['String']>;
  loginType?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  performedBy?: Maybe<Scalars['String']>;
  performedByName?: Maybe<Scalars['String']>;
  prescriptionId?: Maybe<Scalars['ID']>;
  prescription?: Maybe<Prescription>;
};

export type PrescriptionDeliveryAddress = {
  id?: Maybe<Scalars['ID']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
};

export type PrescriptionDeliveryConfirmation = {
  message: Scalars['String'];
};

export type PrescriptionDeliveryMethods = {
  homeDelivery?: Maybe<Scalars['Boolean']>;
  homeDrugDelivery?: Maybe<Scalars['Boolean']>;
  pharmacyDelivery?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionDrug = {
  drugId?: Maybe<Scalars['ID']>;
  prescriptionDrugId: Scalars['ID'];
  drugName: Scalars['String'];
  patient?: Maybe<Patient>;
  dosage?: Maybe<Scalars['String']>;
  packagingSize?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  refills?: Maybe<Scalars['Int']>;
  directions: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<Maybe<PrescriptionDrugIndication>>>;
  repeatable?: Maybe<Scalars['Boolean']>;
  repeats?: Maybe<Scalars['Int']>;
  reviewDate?: Maybe<Scalars['Date']>;
};

export type PrescriptionDrugIndication = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
};

export type PrescriptionDrugIndicationInput = {
  name?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
};

export type PrescriptionDrugInput = {
  drugId?: Maybe<Scalars['ID']>;
  drugName: Scalars['String'];
  dosage?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  refills?: Maybe<Scalars['Int']>;
  directions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<Maybe<PrescriptionDrugIndicationInput>>>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  packagingSize?: Maybe<Scalars['String']>;
  repeatableDrug?: Maybe<Scalars['Boolean']>;
  repeatable?: Maybe<Scalars['Boolean']>;
  repeats?: Maybe<Scalars['Int']>;
  reviewDate?: Maybe<Scalars['Date']>;
};

export type PrescriptionFaxStatus = {
  id: Scalars['ID'];
  prescription?: Maybe<Prescription>;
  pharmacyId?: Maybe<Scalars['String']>;
  faxableId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  errorId?: Maybe<Scalars['Int']>;
  errorType?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  failedAt?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type PrescriptionPharmacySupplier = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type PrescriptionRegion = {
  babylonRegionId: Scalars['ID'];
  regionCode: Scalars['String'];
  name: Scalars['String'];
  regionIri?: Maybe<Scalars['String']>;
};

export type Prescriptions = Pagination & {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<Prescription>>;
};

export type PrescriptionSignatureRequestInput = {
  signingToken?: Maybe<Scalars['String']>;
  base64Image?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export enum PrescriptionStateActionType {
  AcceptEpsCode = 'ACCEPT_EPS_CODE',
  ApprovePrescription = 'APPROVE_PRESCRIPTION',
  Create = 'CREATE',
  MarkAsConfirmedReceived = 'MARK_AS_CONFIRMED_RECEIVED',
  MarkAsPrinted = 'MARK_AS_PRINTED',
  MarkDrugsDispatched = 'MARK_DRUGS_DISPATCHED',
  MarkAsDispensed = 'MARK_AS_DISPENSED',
  MarkPrinted = 'MARK_PRINTED',
  RejectPharmacySelection = 'REJECT_PHARMACY_SELECTION',
  RejectPrescription = 'REJECT_PRESCRIPTION',
  RejectPrescriptionDispensing = 'REJECT_PRESCRIPTION_DISPENSING',
  ResendEpsCode = 'RESEND_EPS_CODE',
  Void = 'VOID'
}

export type PrescriptionStatistics = {
  id?: Maybe<Scalars['ID']>;
  toBePrintedCount?: Maybe<Scalars['Int']>;
  toBeSignedCount?: Maybe<Scalars['Int']>;
  toBeReviewedCount?: Maybe<Scalars['Int']>;
};

export type PrescriptionValidation = {
  drugName: Scalars['String'];
  alternativeDrugNote?: Maybe<Scalars['String']>;
  alternativeDrug?: Maybe<Scalars['String']>;
  warnings?: Maybe<Array<Maybe<PrescriptionValidationWarning>>>;
};

export type PrescriptionValidationWarning = {
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  allowPrescribing?: Maybe<Scalars['Boolean']>;
};

export type PreviousAddresses = {
  first_line?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  third_line?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
};

export type PreviousAddressesInput = {
  first_line?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
};

export type PrivacyApp = {
  appId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type PrivacyNotice = {
  accepted?: Maybe<Scalars['Boolean']>;
  noticeVersionId?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  noticeId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  longText?: Maybe<Scalars['String']>;
  acceptButtonText?: Maybe<Scalars['String']>;
  declineButtonText?: Maybe<Scalars['String']>;
  readMoreButtonText?: Maybe<Scalars['String']>;
  popupTitle?: Maybe<Scalars['String']>;
  popupText?: Maybe<Scalars['String']>;
  chatbotText?: Maybe<Scalars['String']>;
  updateTitle?: Maybe<Scalars['String']>;
  updateText?: Maybe<Scalars['String']>;
  onboardingText?: Maybe<Scalars['String']>;
  settingsText?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  errorTitle?: Maybe<Scalars['String']>;
  errorText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  mandatory?: Maybe<Scalars['Boolean']>;
};

export type Profession = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  key: Scalars['String'];
  alternative_slot_durations_minutes?: Maybe<Array<Scalars['Int']>>;
  default_slot_duration_minutes?: Maybe<Scalars['Int']>;
  default_appointment_price_cents?: Maybe<Scalars['Int']>;
  medical_identifier_label?: Maybe<Scalars['String']>;
};

export type Profile = {
  id: Scalars['ID'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export type ProfileInformation = {
  avatar?: Maybe<Scalars['String']>;
  avatar_image_path?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  password?: Maybe<Scalars['String']>;
  preferred_consumer_network_id?: Maybe<Scalars['Int']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  smoking_status?: Maybe<SmokingStatus>;
  address_first_line?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  is_password_present?: Maybe<Scalars['Boolean']>;
  next_of_kin?: Maybe<NextOfKinInput>;
};

export type ProfileLock = {
  name: Scalars['String'];
  locked_at: Scalars['String'];
};

export type PromoteCarePlanDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
};

export type PromoteCarePlanDraftResult = PromoteCarePlanDraftSuccess | CarePlanDraftDoesNotExistError | UnresolvedWarningsError | ConsultationDoesNotExistError | InvalidCarePlanDraftNumbersOfEditsError | CarePlanAuthorizationError;

export type PromoteCarePlanDraftSuccess = {
  carePlan: CarePlan;
};

export type Promotion = {
  id: Scalars['Int'];
  appointment_count?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  code_prefix?: Maybe<Scalars['String']>;
  credit_card_required_text?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  expires_on?: Maybe<Scalars['String']>;
  is_credit_card_required: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  percent_off?: Maybe<Scalars['Int']>;
  promotion_type?: Maybe<Scalars['String']>;
};

export type ProvenanceEventStatement = {
  subject: Scalars['String'];
  predicate: Scalars['String'];
  object: Scalars['String'];
};

export type PublicHealthcareAdmission = {
  id?: Maybe<Scalars['ID']>;
  location_checked?: Maybe<Scalars['Boolean']>;
  pds_trace_status?: Maybe<Scalars['String']>;
  verification_overwritten_by_ops?: Maybe<Scalars['Boolean']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
};

export type PublicHealthcareDetails = {
  id?: Maybe<Scalars['ID']>;
  armed_forces_enlisting_date?: Maybe<Scalars['String']>;
  arrival_date?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['String']>;
};

export type PublicHealthcareDetailsInput = {
  id?: Maybe<Scalars['ID']>;
  arrival_date?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['String']>;
  armed_forces_enlisting_date?: Maybe<Scalars['String']>;
};

export type Purchase = {
  id: Scalars['Int'];
  activity_id: Scalars['Int'];
  cancelled_at?: Maybe<Scalars['String']>;
  consultant_completed_at?: Maybe<Scalars['String']>;
  consultant_ended_at?: Maybe<Scalars['String']>;
  consultant_id: Scalars['Int'];
  consultant_switched_to_voice_at?: Maybe<Scalars['String']>;
  consultant_type: Scalars['String'];
  consumer_network_id: Scalars['Int'];
  created_at: Scalars['String'];
  diagnosis_codes?: Maybe<Scalars['String']>;
  diagnosis_notes?: Maybe<Scalars['String']>;
  encrypted_diagnosis_notes?: Maybe<Scalars['String']>;
  encrypted_examination_notes?: Maybe<Scalars['String']>;
  encrypted_further_action_notes?: Maybe<Scalars['String']>;
  encrypted_history_notes?: Maybe<Scalars['String']>;
  encrypted_management_notes?: Maybe<Scalars['String']>;
  encrypted_patient_note?: Maybe<Scalars['String']>;
  encrypted_pre_auth_number?: Maybe<Scalars['Int']>;
  examination_codes?: Maybe<Scalars['String']>;
  examination_notes?: Maybe<Scalars['String']>;
  fax_number_id?: Maybe<Scalars['Int']>;
  faxed: Scalars['Boolean'];
  further_action_codes?: Maybe<Scalars['String']>;
  further_action_notes?: Maybe<Scalars['String']>;
  gp_consent: Scalars['Boolean'];
  history_codes?: Maybe<Scalars['String']>;
  history_notes?: Maybe<Scalars['String']>;
  image_content_type?: Maybe<Scalars['String']>;
  image_file_name?: Maybe<Scalars['String']>;
  image_file_size?: Maybe<Scalars['String']>;
  image_updated_at?: Maybe<Scalars['String']>;
  management_codes?: Maybe<Scalars['String']>;
  management_notes?: Maybe<Scalars['String']>;
  medium: Scalars['String'];
  paid: Scalars['Boolean'];
  paid_at?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
  patient_note?: Maybe<Scalars['String']>;
  pre_auth_number?: Maybe<Scalars['Int']>;
  prescription_upload_content_type?: Maybe<Scalars['String']>;
  prescription_upload_file_name?: Maybe<Scalars['String']>;
  prescription_upload_file_size?: Maybe<Scalars['String']>;
  prescription_upload_updated_at?: Maybe<Scalars['String']>;
  redemption_id?: Maybe<Scalars['Int']>;
  sent_to_gp: Scalars['Boolean'];
  state: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type Query = {
  activityLog?: Maybe<ActivityLog>;
  adminActiveIngredient?: Maybe<Array<AdminActiveIngredient>>;
  adminActiveIngredients?: Maybe<AdminActiveIngredients>;
  adminCancelReasons?: Maybe<Array<Maybe<CancelReason>>>;
  adminDrug?: Maybe<AdminDrug>;
  adminDrugAlert?: Maybe<AdminDrugAlert>;
  adminDrugAlerts?: Maybe<AdminDrugAlerts>;
  adminDrugAudits?: Maybe<Array<Maybe<AdminDrugAudit>>>;
  adminDrugs?: Maybe<AdminDrugResult>;
  allergies: Array<Maybe<Allergy>>;
  appNoticeVersion: NoticeVersion;
  appNotices?: Maybe<Array<Notice>>;
  appointment?: Maybe<Appointment>;
  appointmentAvailabilitySlots?: Maybe<Array<Maybe<AppointmentAvailabilitySlot>>>;
  appointmentClinicians: Array<Consultant>;
  appointmentClinics: Array<Practice>;
  appointmentConfigurationNameExists: Scalars['Boolean'];
  appointmentConfigurations?: Maybe<Array<Maybe<ConfigurationByContract>>>;
  appointmentCredit?: Maybe<AppointmentCredit>;
  appointmentCreditReasons: Array<AppointmentCreditReason>;
  appointmentCredits: Array<AppointmentCredit>;
  appointmentInvite?: Maybe<AppointmentInvite>;
  appointmentInvites: Array<AppointmentInvite>;
  appointmentSlots?: Maybe<Array<Maybe<AppointmentSlot>>>;
  appointmentSlotsV2?: Maybe<AppointmentSlots>;
  appointmentV2?: Maybe<Appointment>;
  appointmentWaitTimeConfigs: AppointmentWaitTimeConfigs;
  appointmentWaitTimes: AppointmentWaitTimes;
  appointments: Array<Maybe<Appointment>>;
  availability: Array<Maybe<ClinicianAvailability>>;
  availabilitySlots: AvailabilitySlots;
  availableConsultants: Array<Maybe<Consultant>>;
  availablePharmacySuppliers: Array<Maybe<PrescriptionPharmacySupplier>>;
  cancelReasons?: Maybe<Array<Maybe<CancelReason>>>;
  carePlan: CarePlanResult;
  carePlanDraft: CarePlanDraftResult;
  carePlanPublishedInConsultation: CarePlanPublishedInConsultationResult;
  carers: Array<Carer>;
  chatscriptConversation?: Maybe<ChatscriptConversation>;
  chatscriptConversations?: Maybe<ChatscriptConversationsResponse>;
  chatscriptMessages?: Maybe<ChatscriptMessagesResponse>;
  clinicalKnowledgeEntity?: Maybe<ClinicalKnowledgeEntity>;
  clinicalKnowledgeViews: Array<ClinicalKnowledgeView>;
  clinicalUserInformation?: Maybe<Patient>;
  clinicianAvailabilityShifts: ClinicianAvailabilityShifts;
  clinicianServiceTypes: Array<ServiceType>;
  comment: Comment;
  comments?: Maybe<Comments>;
  conditions: Array<Maybe<Condition>>;
  configurationDetails?: Maybe<ConfigurationDetailsType>;
  consultant?: Maybe<Consultant>;
  consultantMetrics?: Maybe<Array<Maybe<ScheduleDateInfo>>>;
  consultantRTMStatus: ConsultantRtmStatus;
  consultantTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultation?: Maybe<Consultation>;
  consultationByAppointmentId?: Maybe<Consultation>;
  consultationHistory: Array<Maybe<ConsultationHistory>>;
  consultationNote?: Maybe<ConsultationNote>;
  consultationPermissions?: Maybe<Array<ConsultationPermission>>;
  consultationSickNote?: Maybe<ConsultationSickNote>;
  consultationTypes?: Maybe<Array<Maybe<AppointmentMedium>>>;
  consultationWorkflowsV2: ConsultationWorkflowsResponse;
  consultations: Array<Consultation>;
  consultationsv2: ConsultationResults;
  consumerNetworkAppointmentCategories: Array<AppointmentCategory>;
  consumerNetworkProfessions: Array<ConsumerNetworkProfession>;
  consumerNetworkServiceTypes: Array<ServiceType>;
  consumerNetworks: Array<Maybe<ConsumerNetwork>>;
  contract?: Maybe<Contract>;
  coverageEligibilityList?: Maybe<CoverageEligibilityList>;
  coverageEligibilityRead: CoverageEligibility;
  cp3ClinicalCodeWeightings: Array<Cp3ClinicalCodeWeightingsResult>;
  cp3LinksAndResources: Array<Cp3LinksAndResourcesResult>;
  cp3ReleaseNotes: Array<Cp3ReleaseNotesResult>;
  currentPortalUser?: Maybe<PortalUser>;
  diagnosticTestTypes: Array<Maybe<TestType>>;
  drug?: Maybe<Drug>;
  drugDosages?: Maybe<Array<Maybe<DrugDosage>>>;
  drugForms?: Maybe<Array<Maybe<Scalars['String']>>>;
  drugUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  drugs: Array<Maybe<Drug>>;
  encryptedSearchPatients?: Maybe<MopSearchPatients>;
  extensionalValueSet: Scalars['String'];
  familyAccount?: Maybe<FamilyAccount>;
  familyMembers: Array<Maybe<Patient>>;
  /** Get a list of feature states given a list of tags and a scope */
  featureStates: Array<FeatureState>;
  featureSwitches?: Maybe<FeatureSwitch>;
  fhirProfile?: Maybe<FhirProfile>;
  fhirProfileExample: Scalars['String'];
  fhirProfiles: Array<FhirProfile>;
  fhirValueSet?: Maybe<FhirValueSet>;
  fhirValueSets: Array<FhirValueSet>;
  findConsumerNetworks: Array<Maybe<ConsumerNetwork>>;
  flags: Array<Maybe<Flag>>;
  getAllAppointmentConfigurations?: Maybe<AppointmentConfigurations>;
  getAppointmentCheckIn?: Maybe<CheckInDetails>;
  getAppointmentConfigurationById?: Maybe<Configurations>;
  getAppointmentConfigurationByName?: Maybe<Configurations>;
  getCallEndedCallFlowSteps: CallFlowStatus;
  getCallFlowSteps: CallFlowStatus;
  getClinicalNotes: Array<Maybe<ClinicalNote>>;
  getClinicalTerminologyAttribute: ClinicalTerminologyAttributeComplete;
  getClinicalTerminologyAttributes: Array<Maybe<ClinicalTerminologyAttribute>>;
  getClinicalTerminologyEntity: ClinicalTerminologyEntity;
  getClinicalTerminologyEvents: ClinicalTerminologyEvents;
  getClinicalTerminologyLabels: ClinicalTerminologyLabelsEntity;
  getClinicianVisibility?: Maybe<ClinicianVisibility>;
  getConcepts?: Maybe<Array<Maybe<Concept>>>;
  getConsultants?: Maybe<Array<Maybe<Consultant>>>;
  getConsultantsPerId?: Maybe<Array<Maybe<Consultant>>>;
  getContribution: ClinicalTerminologyContribution;
  getContributionsByUser: ClinicalTerminologyContributionList;
  getDevices: Array<DeviceInfo>;
  getEHRPatient: EhrPatient;
  getEligibleConsultants?: Maybe<Array<Maybe<Consultant>>>;
  getFhirUsage: FhirContextUsage;
  getMembershipPlans?: Maybe<PaginatedMembershipPlan>;
  getPanel?: Maybe<TestReport>;
  getPanels?: Maybe<TestReports>;
  getPatientConsultations: ConsultationResults;
  getPatientReferrals: Array<PatientReferral>;
  getPatientSubscriptions: Array<Maybe<Subscription>>;
  getRawCallFlowSteps: CallFlowStatus;
  getReport?: Maybe<TestReport>;
  getReports?: Maybe<TestReports>;
  getSickNotes: Array<Maybe<SickNote>>;
  getTenancyRegion: TenancyRegion;
  getTenancyRegions?: Maybe<Array<TenancyRegion>>;
  getTimelineEventsByPatient?: Maybe<PagedTimelineEvent>;
  getTransactionReasons: Array<Reason>;
  getTransactions: Array<PortalTransaction>;
  getUserRoles: UserRoles;
  gpSurgeryFaxes: Array<GpSurgeryFax>;
  gpUserInformation?: Maybe<Patient>;
  gpahConsumerNetworks: Array<Maybe<ConsumerNetworkName>>;
  healthCheckAssessmentStage?: Maybe<HealthCheckAssessmentStage>;
  healthCheckReport?: Maybe<Array<Maybe<HealthCheckCategory>>>;
  healthCheckSummary: HealthCheckSummary;
  humanitySyncAllowed?: Maybe<HumanitySyncAllowed>;
  humanitySyncShiftItems: HumanitySyncShiftItems;
  /** @deprecated replaced by humanitySyncShiftItems */
  humanitySyncShifts: HumanitySyncShifts;
  humanitySyncStatus?: Maybe<HumanitySyncStatus>;
  humanitySyncs: PastHumanitySyncs;
  idVerification: IdVerification;
  identify?: Maybe<Patient>;
  incompleteConsultationsInfo: Array<ConsultationInfo>;
  insuranceCompanies: Array<Maybe<InsuranceCompany>>;
  intensionalValueSet: Scalars['String'];
  isNewValueSetId?: Maybe<IdValidationResult>;
  listTenancyPartners: TenancyPartnersList;
  medicalHistory?: Maybe<MedicalHistory>;
  medications: Array<Maybe<Medication>>;
  memberDecisionForNotice?: Maybe<NoticeDecision>;
  memberTask: MemberTask;
  memberTasks: Array<MemberTask>;
  memberThread: MemberThread;
  memberThreads: Array<MemberThread>;
  monthlyCalendarSchedule: Array<DateWithShiftInfo>;
  monthlyCalendarScheduleV2: MonthlyCalendarSchedule;
  multimediaFeedbackQuestion?: Maybe<MultimediaFeedbackQuestion>;
  multimediaFeedbackQuestionTemp?: Maybe<MultimediaFeedbackQuestion>;
  newAppointment?: Maybe<Appointment>;
  noteAssistantConsent: NoteAssistantConsent;
  noteAssistantNotes: NoteAssistantNote;
  noteAssistantRecordingId: NoteAssistantRecordingId;
  orphanedSlots: OrphanedSlots;
  partnerPlanDefaults?: Maybe<PartnerPlanDefaults>;
  pathologyTestTypes: Array<Maybe<TestType>>;
  patient?: Maybe<Patient>;
  patientConsumerNetworks: Array<Maybe<PatientConsumerNetwork>>;
  patient_alert?: Maybe<PatientAlert>;
  patient_alerts: Array<Maybe<PatientAlert>>;
  patients: Array<Patient>;
  patientsByIds: Array<Maybe<MopPatient>>;
  permittedRoles?: Maybe<Array<PortalUserRole>>;
  pgmReport?: Maybe<PgmReportOutcome>;
  pgmReportLegacy?: Maybe<PgmReportLegacy>;
  pgmReportSummary?: Maybe<PgmReportSummary>;
  pharmacyPrescriptions?: Maybe<Prescriptions>;
  placesSearch?: Maybe<Array<Maybe<PlaceResource>>>;
  portalUser?: Maybe<PortalUser>;
  portalUserPartners?: Maybe<Array<TenancyPartner>>;
  /** @deprecated Field no longer supported */
  portalUsers?: Maybe<PortalUserResults>;
  portalUsersList?: Maybe<PortalUsersList>;
  practices: Array<Practice>;
  practicesByFilter: Array<Practice>;
  practicesByIds: Array<Maybe<Practice>>;
  prescription: Prescription;
  prescriptionAudit?: Maybe<Array<Maybe<PrescriptionAudit>>>;
  prescriptionFaxStatus?: Maybe<Array<Maybe<PrescriptionFaxStatus>>>;
  prescriptionFetchFailed?: Maybe<Prescriptions>;
  prescriptionRegions?: Maybe<Array<Maybe<PrescriptionRegion>>>;
  prescriptionStatistics?: Maybe<PrescriptionStatistics>;
  prescriptions?: Maybe<Prescriptions>;
  privacyApps?: Maybe<Array<PrivacyApp>>;
  privacyNotice?: Maybe<PrivacyNotice>;
  profession?: Maybe<Profession>;
  professions: Array<Profession>;
  profile: Profile;
  redeemedCodes: Array<Maybe<RedeemedCode>>;
  referralSearch?: Maybe<Array<Maybe<ReferralResource>>>;
  region: Region;
  regionByCode: Region;
  regionStates?: Maybe<Array<Maybe<State>>>;
  regions: Array<Region>;
  relationships: Array<Relationship>;
  repeatTemplates?: Maybe<RepeatTemplateResults>;
  reportQuery: Array<Maybe<ReportQuery>>;
  rootAndTopLevelTenancyPartners?: Maybe<TenancyPartnersList>;
  searchClinicalCodes: Array<Maybe<ClinicalCode>>;
  searchClinicalKnowledgeEntities: Array<ClinicalKnowledgeEntity>;
  searchClinicalTerminologyEntities: ClinicalTerminologySearchEntity;
  searchCodingAll: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingAllergies: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingConditions: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingDiagnosis: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingMedications: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchFastCTConcepts: SearchFastCtConceptsEntity;
  searchLeaflets?: Maybe<Array<Maybe<Leaflet>>>;
  searchPatients?: Maybe<MopSearchPatients>;
  searchPointOfCareEntity: SearchPointOfCareEntity;
  serviceType?: Maybe<ServiceType>;
  serviceTypeCategories?: Maybe<Array<Maybe<ServiceTypeCategory>>>;
  serviceTypes: Array<ServiceType>;
  serviceTypesForConfiguration?: Maybe<Array<Maybe<ServiceTypeForConfig>>>;
  signupNotices?: Maybe<SignUpNotices>;
  specialismCategories: Array<Maybe<SpecialismCategory>>;
  specialist?: Maybe<Specialist>;
  specialists: Array<Maybe<Specialist>>;
  specialistsForPatient: Array<Maybe<Specialist>>;
  supplyNetworks?: Maybe<Array<SupplyNetwork>>;
  supportInteraction?: Maybe<SupportInteraction>;
  tags: Array<Maybe<Tag>>;
  tenancyPartner?: Maybe<TenancyPartner>;
  tenancyPartners?: Maybe<TenancyPartnersList>;
  tenancyPartnersFlatList: Array<TenancyPartner>;
  tenancyTypeLabels: Array<Maybe<Scalars['String']>>;
  therapists: Array<Maybe<Specialist>>;
  translateCode: CodeTranslation;
  translationLanguages?: Maybe<TranslationLanguages>;
  user?: Maybe<Patient>;
  userAppointments: Array<Maybe<Appointment>>;
  userConsumerNetworks: Array<Maybe<ConsumerNetwork>>;
  validateConcept: ValidationResult;
  viewer: Viewer;
  webPlatformAccessDecide?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  workflowDefinition?: Maybe<WorkflowDefinition>;
  workflowDefinitions?: Maybe<Array<Maybe<WorkflowDefinition>>>;
  workflowSubSpecialties?: Maybe<Array<Maybe<Scalars['String']>>>;
  workflowSubTypes?: Maybe<Array<Maybe<WorkflowSubType>>>;
  workflowTasks: Array<Maybe<WorkflowTask>>;
  workflows: Array<Maybe<Workflow>>;
  workflowsV2: WorkflowsResponse;
};


export type QueryActivityLogArgs = {
  patientId: Scalars['ID'];
};


export type QueryAdminActiveIngredientArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAdminActiveIngredientsArgs = {
  query: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAdminDrugArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryAdminDrugAlertArgs = {
  id: Scalars['ID'];
};


export type QueryAdminDrugAlertsArgs = {
  query: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAdminDrugAuditsArgs = {
  auditableId: Scalars['ID'];
};


export type QueryAdminDrugsArgs = {
  query?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAllergiesArgs = {
  id: Scalars['Int'];
};


export type QueryAppNoticeVersionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryAppNoticesArgs = {
  app?: Maybe<Scalars['String']>;
};


export type QueryAppointmentArgs = {
  id: Scalars['Int'];
};


export type QueryAppointmentAvailabilitySlotsArgs = {
  filter?: Maybe<AppointmentAvailabilitySlotsFilter>;
};


export type QueryAppointmentCliniciansArgs = {
  consultantType?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  serviceTypeUuid?: Maybe<Scalars['ID']>;
};


export type QueryAppointmentClinicsArgs = {
  consultantType?: Maybe<Scalars['String']>;
  consultant?: Maybe<Scalars['ID']>;
  consultationType?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  serviceTypeUuid?: Maybe<Scalars['ID']>;
};


export type QueryAppointmentConfigurationNameExistsArgs = {
  configurationName: Scalars['String'];
};


export type QueryAppointmentConfigurationsArgs = {
  contractIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAppointmentCreditArgs = {
  patientId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryAppointmentCreditsArgs = {
  patientId?: Maybe<Scalars['String']>;
};


export type QueryAppointmentInviteArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryAppointmentInvitesArgs = {
  filter?: Maybe<AppointmentInviteFilter>;
};


export type QueryAppointmentSlotsArgs = {
  request?: Maybe<AppointmentSlotsRequest>;
};


export type QueryAppointmentSlotsV2Args = {
  request?: Maybe<AppointmentSlotsRequest>;
};


export type QueryAppointmentV2Args = {
  id: Scalars['Int'];
};


export type QueryAppointmentWaitTimeConfigsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  consumerNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceTypeUuids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAppointmentWaitTimesArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  consumerNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceTypeUuids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAvailabilityArgs = {
  input?: Maybe<ClinicalQueryInput>;
};


export type QueryAvailabilitySlotsArgs = {
  request: AvailabilitySlotsRequest;
};


export type QueryAvailableConsultantsArgs = {
  memberId?: Maybe<Scalars['ID']>;
  consultantType?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
};


export type QueryAvailablePharmacySuppliersArgs = {
  prescriptionId: Scalars['ID'];
  postcode?: Maybe<Scalars['String']>;
};


export type QueryCarePlanArgs = {
  patientId: Scalars['ID'];
};


export type QueryCarePlanDraftArgs = {
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
};


export type QueryCarePlanPublishedInConsultationArgs = {
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
};


export type QueryCarersArgs = {
  id: Scalars['String'];
};


export type QueryChatscriptConversationArgs = {
  userId?: Maybe<Scalars['ID']>;
  conversationId?: Maybe<Scalars['ID']>;
};


export type QueryChatscriptConversationsArgs = {
  userId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  outcome?: Maybe<ChatscriptConversationOutcome>;
  status?: Maybe<ChatscriptConversationStatus>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  resultTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  covidOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryChatscriptMessagesArgs = {
  userId?: Maybe<Scalars['ID']>;
  conversationId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryClinicalKnowledgeEntityArgs = {
  iri: Scalars['String'];
  view?: Maybe<Scalars['String']>;
};


export type QueryClinicalUserInformationArgs = {
  id: Scalars['Int'];
};


export type QueryClinicianAvailabilityShiftsArgs = {
  consultantId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryClinicianServiceTypesArgs = {
  clinician_ids: Scalars['ID'];
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommentsArgs = {
  patient_id?: Maybe<Scalars['ID']>;
  pageIndex?: Maybe<Scalars['Int']>;
};


export type QueryConditionsArgs = {
  id: Scalars['Int'];
};


export type QueryConfigurationDetailsArgs = {
  serviceTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  configurationId?: Maybe<Scalars['String']>;
};


export type QueryConsultantArgs = {
  id: Scalars['ID'];
};


export type QueryConsultantMetricsArgs = {
  id: Scalars['ID'];
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};


export type QueryConsultantRtmStatusArgs = {
  consultantId: Scalars['ID'];
};


export type QueryConsultantTypesArgs = {
  memberId: Scalars['ID'];
};


export type QueryConsultationArgs = {
  id: Scalars['ID'];
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryConsultationByAppointmentIdArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryConsultationHistoryArgs = {
  patientId: Scalars['ID'];
};


export type QueryConsultationNoteArgs = {
  consultationId: Scalars['ID'];
};


export type QueryConsultationPermissionsArgs = {
  id: Scalars['ID'];
};


export type QueryConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
};


export type QueryConsultationTypesArgs = {
  memberId: Scalars['ID'];
};


export type QueryConsultationWorkflowsV2Args = {
  locale: Scalars['String'];
  tags: Scalars['String'];
  consumerNetwork: Scalars['ID'];
  consultationId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryConsultationsArgs = {
  consultations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  consultants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  patients?: Maybe<Array<Maybe<Scalars['ID']>>>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};


export type QueryConsultationsv2Args = {
  searchCriteria?: Maybe<ConsultationSearchCriteria>;
  sortCriteria?: Maybe<ConsultationSortCriteria>;
  paginationCriteria?: Maybe<PaginationCriteria>;
};


export type QueryConsumerNetworkAppointmentCategoriesArgs = {
  consumerNetworkUuid: Scalars['ID'];
  appointmentFeatures?: Maybe<Array<AppointmentFeaturesParams>>;
};


export type QueryConsumerNetworkProfessionsArgs = {
  id: Scalars['ID'];
};


export type QueryConsumerNetworkServiceTypesArgs = {
  consumerNetworkId: Scalars['ID'];
};


export type QueryContractArgs = {
  id: Scalars['ID'];
};


export type QueryCoverageEligibilityListArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sort: Scalars['String'];
  query: Scalars['String'];
  partnerId: Scalars['String'];
};


export type QueryCoverageEligibilityReadArgs = {
  id: Scalars['ID'];
  partnerId: Scalars['String'];
};


export type QueryCp3ClinicalCodeWeightingsArgs = {
  countryCode: Scalars['String'];
};


export type QueryCp3LinksAndResourcesArgs = {
  countryCode: Scalars['String'];
};


export type QueryCp3ReleaseNotesArgs = {
  countryCode: Scalars['String'];
};


export type QueryDrugArgs = {
  id: Scalars['ID'];
  region?: Maybe<Scalars['String']>;
};


export type QueryDrugDosagesArgs = {
  regionId: Scalars['String'];
};


export type QueryDrugsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
};


export type QueryEncryptedSearchPatientsArgs = {
  encryptedSearchCriteria?: Maybe<EncryptedSearchCriteria>;
};


export type QueryExtensionalValueSetArgs = {
  id: Scalars['String'];
  system: Scalars['String'];
  codeAndDisplayList: Array<Maybe<CodeAndDisplayInput>>;
};


export type QueryFamilyAccountArgs = {
  uuid: Scalars['ID'];
};


export type QueryFeatureStatesArgs = {
  tags?: Maybe<Array<Scalars['String']>>;
  scope: FeatureScopeInput;
};


export type QueryFeatureSwitchesArgs = {
  patientId: Scalars['Int'];
};


export type QueryFhirProfileArgs = {
  id: Scalars['ID'];
};


export type QueryFhirProfileExampleArgs = {
  id: Scalars['ID'];
};


export type QueryFhirValueSetArgs = {
  id: Scalars['String'];
};


export type QueryFindConsumerNetworksArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryFlagsArgs = {
  appointmentId?: Maybe<Scalars['Int']>;
};


export type QueryGetAllAppointmentConfigurationsArgs = {
  contractIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetAppointmentCheckInArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetAppointmentConfigurationByIdArgs = {
  configurationId: Scalars['String'];
};


export type QueryGetAppointmentConfigurationByNameArgs = {
  configurationName: Scalars['String'];
};


export type QueryGetCallEndedCallFlowStepsArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetCallFlowStepsArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetClinicalNotesArgs = {
  iris: Array<Maybe<ClinicalCodeInput>>;
  resolveDrugs?: Maybe<Scalars['Boolean']>;
};


export type QueryGetClinicalTerminologyAttributeArgs = {
  iri: Scalars['String'];
};


export type QueryGetClinicalTerminologyAttributesArgs = {
  subject?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
};


export type QueryGetClinicalTerminologyEntityArgs = {
  iri: Scalars['String'];
  contributionId?: Maybe<Scalars['String']>;
};


export type QueryGetClinicalTerminologyEventsArgs = {
  iri: Scalars['String'];
};


export type QueryGetClinicalTerminologyLabelsArgs = {
  iri: Scalars['String'];
};


export type QueryGetClinicianVisibilityArgs = {
  clinician_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clinic_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  service_type_uuid?: Maybe<Array<Maybe<Scalars['ID']>>>;
  profession?: Maybe<Array<Maybe<Scalars['String']>>>;
  tenancy_plan_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  slot_time_from: Scalars['String'];
  slot_time_to: Scalars['String'];
};


export type QueryGetConceptsArgs = {
  consultationId: Scalars['ID'];
};


export type QueryGetConsultantsArgs = {
  filters: ConsultantSearchFilters;
};


export type QueryGetConsultantsPerIdArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGetContributionArgs = {
  contributionId: Scalars['String'];
};


export type QueryGetContributionsByUserArgs = {
  author?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetDevicesArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetEhrPatientArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetEligibleConsultantsArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryGetFhirUsageArgs = {
  iri: Scalars['String'];
};


export type QueryGetMembershipPlansArgs = {
  member_uuid: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetPanelArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetPanelsArgs = {
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Scalars['String']>;
};


export type QueryGetPatientConsultationsArgs = {
  patientUuid: Scalars['ID'];
  dateRange?: Maybe<DateRange>;
  status?: Maybe<ConsultationStatus>;
};


export type QueryGetPatientReferralsArgs = {
  id: Scalars['ID'];
};


export type QueryGetPatientSubscriptionsArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetRawCallFlowStepsArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetReportArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetReportsArgs = {
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Scalars['String']>;
};


export type QueryGetSickNotesArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetTenancyRegionArgs = {
  id: Scalars['ID'];
};


export type QueryGetTimelineEventsByPatientArgs = {
  patientId: Scalars['ID'];
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type QueryGetTransactionsArgs = {
  patientId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGpUserInformationArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryHealthCheckAssessmentStageArgs = {
  locale: Scalars['String'];
  memberId: Scalars['ID'];
  includeDataPointCompleteness?: Maybe<Scalars['String']>;
};


export type QueryHealthCheckReportArgs = {
  userId: Scalars['ID'];
};


export type QueryHealthCheckSummaryArgs = {
  userId: Scalars['ID'];
};


export type QueryHumanitySyncShiftItemsArgs = {
  id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  filters?: Maybe<HumanitySyncShiftItemFilters>;
};


export type QueryHumanitySyncShiftsArgs = {
  id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryHumanitySyncStatusArgs = {
  id: Scalars['ID'];
};


export type QueryHumanitySyncsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryIdVerificationArgs = {
  referer: Scalars['String'];
};


export type QueryIdentifyArgs = {
  id: Scalars['Int'];
};


export type QueryIncompleteConsultationsInfoArgs = {
  consultantId: Scalars['ID'];
  timezoneOffset?: Maybe<Scalars['Int']>;
};


export type QueryIntensionalValueSetArgs = {
  id: Scalars['String'];
  system: Scalars['String'];
  code: Scalars['String'];
};


export type QueryIsNewValueSetIdArgs = {
  id: Scalars['String'];
};


export type QueryListTenancyPartnersArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryMedicalHistoryArgs = {
  patient_id: Scalars['Int'];
};


export type QueryMedicationsArgs = {
  id: Scalars['Int'];
};


export type QueryMemberDecisionForNoticeArgs = {
  input: MemberDecisionForNoticeInput;
};


export type QueryMemberTaskArgs = {
  filter: MemberTaskFilter;
};


export type QueryMemberTasksArgs = {
  filter: MemberTasksFilter;
};


export type QueryMemberThreadArgs = {
  filter: MemberThreadFilter;
};


export type QueryMemberThreadsArgs = {
  filter: MemberThreadsFilter;
};


export type QueryMonthlyCalendarScheduleArgs = {
  consultantId: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
};


export type QueryMonthlyCalendarScheduleV2Args = {
  consultantId: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};


export type QueryMultimediaFeedbackQuestionArgs = {
  client_type: MultimediaFeedbackClientType;
  consultation_id: Scalars['ID'];
};


export type QueryMultimediaFeedbackQuestionTempArgs = {
  client_type: MultimediaFeedbackClientType;
  consultation_id: Scalars['ID'];
};


export type QueryNewAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryNoteAssistantConsentArgs = {
  id: Scalars['ID'];
  encounterType?: Maybe<Scalars['String']>;
  speakers: Array<NoteAssistantSpeaker>;
};


export type QueryNoteAssistantNotesArgs = {
  id: Scalars['ID'];
};


export type QueryNoteAssistantRecordingIdArgs = {
  input: NoteAssistantRecordingIdInput;
};


export type QueryOrphanedSlotsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPartnerPlanDefaultsArgs = {
  id: Scalars['ID'];
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryPatientConsumerNetworksArgs = {
  id: Scalars['ID'];
};


export type QueryPatient_AlertArgs = {
  patientId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type QueryPatient_AlertsArgs = {
  patientId: Scalars['ID'];
  sortOptions?: Maybe<PatientAlertsSortOptions>;
};


export type QueryPatientsArgs = {
  searchCriteria?: Maybe<PatientSearchCriteria>;
};


export type QueryPatientsByIdsArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type QueryPgmReportArgs = {
  reportId: Scalars['ID'];
  appName?: Maybe<Scalars['String']>;
};


export type QueryPgmReportLegacyArgs = {
  reportId: Scalars['ID'];
  appName?: Maybe<Scalars['String']>;
};


export type QueryPgmReportSummaryArgs = {
  reportId: Scalars['ID'];
  appName?: Maybe<Scalars['String']>;
};


export type QueryPharmacyPrescriptionsArgs = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  states?: Maybe<Scalars['String']>;
};


export type QueryPlacesSearchArgs = {
  input: PlaceResourceInput;
};


export type QueryPortalUserArgs = {
  id: Scalars['ID'];
};


export type QueryPortalUserPartnersArgs = {
  id: Scalars['ID'];
};


export type QueryPortalUsersArgs = {
  paginationCriteria?: Maybe<PaginationCriteria>;
  sortCriteria?: Maybe<PortalUserSortCriteria>;
};


export type QueryPortalUsersListArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sort: Scalars['String'];
  query: Scalars['String'];
};


export type QueryPracticesArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryPracticesByFilterArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryPracticesByIdsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPrescriptionArgs = {
  id: Scalars['ID'];
};


export type QueryPrescriptionAuditArgs = {
  prescriptionId: Scalars['ID'];
  dateAfter?: Maybe<Scalars['String']>;
  dateBefore?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
};


export type QueryPrescriptionFaxStatusArgs = {
  prescriptionId: Scalars['ID'];
};


export type QueryPrescriptionFetchFailedArgs = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};


export type QueryPrescriptionStatisticsArgs = {
  consultantId?: Maybe<Scalars['ID']>;
};


export type QueryPrescriptionsArgs = {
  consultantId?: Maybe<Scalars['ID']>;
  consultationId?: Maybe<Scalars['ID']>;
  prescriptionId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  signatureState?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pharmacyDetails?: Maybe<Scalars['String']>;
  consultantName?: Maybe<Scalars['String']>;
};


export type QueryPrivacyNoticeArgs = {
  notice_id: Scalars['ID'];
};


export type QueryProfessionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryProfileArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryRedeemedCodesArgs = {
  patientId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReferralSearchArgs = {
  input?: Maybe<ReferralResourceInput>;
};


export type QueryRegionArgs = {
  id: Scalars['Int'];
};


export type QueryRegionByCodeArgs = {
  code: Scalars['String'];
};


export type QueryRegionStatesArgs = {
  regionIso: Scalars['String'];
};


export type QueryRelationshipsArgs = {
  id: Scalars['String'];
};


export type QueryRepeatTemplatesArgs = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  reviewDateMin?: Maybe<Scalars['Date']>;
  reviewDateMax?: Maybe<Scalars['Date']>;
  startDateMin?: Maybe<Scalars['Date']>;
  startDateMax?: Maybe<Scalars['Date']>;
  states?: Maybe<Array<Maybe<RepeatTemplateState>>>;
  lastChangeDateMin?: Maybe<Scalars['Date']>;
  lastChangeDateMax?: Maybe<Scalars['Date']>;
  userStates?: Maybe<Array<Maybe<UserTemplateState>>>;
  userStatesFilter?: Maybe<Array<Maybe<UserStateFilter>>>;
};


export type QueryReportQueryArgs = {
  type: Scalars['String'];
};


export type QueryRootAndTopLevelTenancyPartnersArgs = {
  ids: Array<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchClinicalCodesArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QuerySearchClinicalKnowledgeEntitiesArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type QuerySearchClinicalTerminologyEntitiesArgs = {
  system: CtSearchSystemType;
  query: Scalars['String'];
  semanticTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  pageCursor?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingAllArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingAllergiesArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingConditionsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingDiagnosisArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingMedicationsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchFastCtConceptsArgs = {
  language?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  semanticTags?: Maybe<Scalars['String']>;
  term: Scalars['String'];
};


export type QuerySearchLeafletsArgs = {
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchPatientsArgs = {
  searchCriteria?: Maybe<MopPatientSearchCriteria>;
};


export type QuerySearchPointOfCareEntityArgs = {
  index?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  useCases?: Maybe<Scalars['String']>;
  term: Scalars['String'];
};


export type QueryServiceTypeArgs = {
  uuid: Scalars['ID'];
};


export type QueryServiceTypesArgs = {
  include?: Maybe<Array<Scalars['String']>>;
  member_uuid?: Maybe<Scalars['ID']>;
  including_digital?: Maybe<Scalars['Boolean']>;
};


export type QueryServiceTypesForConfigurationArgs = {
  serviceTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySpecialismCategoriesArgs = {
  id: Scalars['ID'];
};


export type QuerySpecialistArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySpecialistsForPatientArgs = {
  patientId?: Maybe<Scalars['ID']>;
};


export type QuerySupportInteractionArgs = {
  transactionId: Scalars['ID'];
  transactionType: Scalars['String'];
};


export type QueryTenancyPartnerArgs = {
  id: Scalars['ID'];
  depth?: Maybe<Scalars['String']>;
};


export type QueryTenancyPartnersArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTenancyPartnersFlatListArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryTranslateCodeArgs = {
  code: Scalars['String'];
  system: Scalars['String'];
  targetsystem: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUserAppointmentsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserConsumerNetworksArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryValidateConceptArgs = {
  semanticTag?: Maybe<Scalars['String']>;
  preferredLabel?: Maybe<Scalars['String']>;
};


export type QueryWebPlatformAccessDecideArgs = {
  resources: Array<Maybe<WebPlatformAccessResourceInput>>;
};


export type QueryWorkflowDefinitionArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryWorkflowDefinitionsArgs = {
  tenantId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryWorkflowSubSpecialtiesArgs = {
  type: WorkflowType;
  subType: Scalars['String'];
};


export type QueryWorkflowSubTypesArgs = {
  type: WorkflowType;
};


export type QueryWorkflowTasksArgs = {
  memberId?: Maybe<Scalars['ID']>;
  searchCriteria?: Maybe<WorkflowSearchCriteria>;
};


export type QueryWorkflowsArgs = {
  consultationId: Scalars['ID'];
};


export type QueryWorkflowsV2Args = {
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<WorkflowState>;
  tenant_id?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};

export type Read2Code = {
  code?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ReallocatedAppointments = {
  orphan_appointments: Array<Maybe<Scalars['Int']>>;
};

export type ReallocateShiftFilters = {
  from_clinician_id: Scalars['Int'];
  to_clinician_id: Scalars['Int'];
  start_date_time: Scalars['Date'];
  end_date_time: Scalars['Date'];
  slots?: Maybe<Array<Maybe<SlotAppointmentMap>>>;
};

export type Reason = {
  id?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
};

export type Receipt = {
  pdf_path?: Maybe<Scalars['String']>;
  appointment?: Maybe<Appointment>;
  patient?: Maybe<Patient>;
  consultant?: Maybe<Consultant>;
  payment?: Maybe<Payment>;
  provider?: Maybe<PaymentProvider>;
};

export type Recurrence = {
  frequency?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export enum RecurrenceFrequency {
  Weekly = 'WEEKLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY'
}

export type RecurrenceInput = {
  frequency?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type RecurrencePatterns = {
  frequency: RecurrenceFrequency;
  interval: Scalars['Int'];
  max_sessions: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
};

export type RecurrenceSettings = {
  recurrence_patterns: Array<RecurrencePatterns>;
};

export type RedeemedCode = {
  activated_at?: Maybe<Scalars['Date']>;
  appointments_booked?: Maybe<Scalars['String']>;
  appointments_left?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_reactivatable: Scalars['Boolean'];
  plan_title?: Maybe<Scalars['String']>;
  promotion_name?: Maybe<Scalars['String']>;
  promotion_type?: Maybe<Scalars['String']>;
  redemption_end_date?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
};

export type RedemptionsAttributes = {
  patient_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  redemption_type?: Maybe<Scalars['String']>;
};

export enum RedemptionType {
  Promotion = 'promotion',
  Membership = 'membership'
}

export type Referral = {
  id?: Maybe<Scalars['ID']>;
  appointment?: Maybe<Appointment>;
  referral_pdf?: Maybe<Scalars['String']>;
  specialism_category?: Maybe<SpecialismCategory>;
  specialism?: Maybe<Specialism>;
  urgent?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  formatted_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type ReferralComment = {
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  viewer?: Maybe<Viewer>;
};

export type ReferralCommentsInput = {
  note?: Maybe<Scalars['String']>;
};

export type ReferralExternalSpecialist = {
  id?: Maybe<Scalars['ID']>;
  externalReference?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReferralExternalSpecialistInput = {
  externalReference?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReferralFax = {
  id?: Maybe<Scalars['ID']>;
  destinationNumber?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
};

export type ReferralFaxInput = {
  destinationNumber?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
};

export type ReferralLocation = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<ReferralLocationAddress>;
};

export type ReferralLocationAddress = {
  line?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type ReferralPractitioner = {
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
};

export type ReferralResource = {
  practitioner?: Maybe<ReferralPractitioner>;
  specialty?: Maybe<Array<Maybe<ReferralSpecialty>>>;
  location?: Maybe<Array<Maybe<ReferralLocation>>>;
  telecom?: Maybe<Array<Maybe<ReferralTelecom>>>;
};

export type ReferralResourceCodingInput = {
  system?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type ReferralResourceInput = {
  practitioner?: Maybe<ReferralResourcePractitionerInput>;
  location?: Maybe<ReferralResourceLocationInput>;
  coding?: Maybe<ReferralResourceCodingInput>;
};

export type ReferralResourceLocationInput = {
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type ReferralResourcePractitionerInput = {
  language?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReferralSpecialty = {
  coding?: Maybe<Array<Maybe<ReferralSpecialtyCoding>>>;
};

export type ReferralSpecialtyCoding = {
  system?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type ReferralTelecom = {
  system?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  use?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
};

export type Region = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  age_of_consent?: Maybe<Scalars['Int']>;
  iso_code?: Maybe<Scalars['String']>;
  appointment_one_off_cost?: Maybe<Scalars['String']>;
  appointment_specialist_cost?: Maybe<Scalars['String']>;
  free_subscription?: Maybe<Scalars['Boolean']>;
  use_babylon_practice?: Maybe<Scalars['Boolean']>;
  show_gp_consent?: Maybe<Scalars['Boolean']>;
  enable_voice_calls?: Maybe<Scalars['Boolean']>;
  enable_video_calls?: Maybe<Scalars['Boolean']>;
  allow_select_gp?: Maybe<Scalars['Boolean']>;
  enable_monitor_me?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Int']>;
  support_number?: Maybe<Scalars['String']>;
  support_email?: Maybe<Scalars['String']>;
  default_phone_country_code?: Maybe<Scalars['String']>;
  doctor_medical_identifier_label?: Maybe<Scalars['String']>;
  nurse_medical_identifier_label?: Maybe<Scalars['String']>;
  merchant_account_id?: Maybe<Scalars['String']>;
  show_in_app?: Maybe<Scalars['Boolean']>;
  twilio_phone?: Maybe<Scalars['String']>;
  sms_number?: Maybe<Scalars['String']>;
  inhibit_warnings?: Maybe<Scalars['Boolean']>;
  enable_apple_pay?: Maybe<Scalars['Boolean']>;
  default_consumer_network?: Maybe<Partner>;
  sick_notes?: Maybe<Scalars['Boolean']>;
  uses_postcode?: Maybe<Scalars['Boolean']>;
  enable_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  show_family_accounts?: Maybe<Scalars['Boolean']>;
  show_monitor?: Maybe<Scalars['Boolean']>;
  show_kit?: Maybe<Scalars['Boolean']>;
  show_pharmacy?: Maybe<Scalars['Boolean']>;
  show_membership_codes?: Maybe<Scalars['Boolean']>;
  show_insurance?: Maybe<Scalars['Boolean']>;
  enable_home_delivery?: Maybe<Scalars['Boolean']>;
  enable_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_consultant_booking_on_behalf_of_patient?: Maybe<Scalars['Boolean']>;
  triage_nurse_medical_identifier_label?: Maybe<Scalars['String']>;
  appointment_timeout_seconds?: Maybe<Scalars['Int']>;
  show_ask?: Maybe<Scalars['Boolean']>;
  show_check?: Maybe<Scalars['Boolean']>;
  show_gp_details?: Maybe<Scalars['Boolean']>;
  show_questions?: Maybe<Scalars['Boolean']>;
  senior_nurse_medical_identifier_label?: Maybe<Scalars['String']>;
  senior_nurse_can_prescribe?: Maybe<Scalars['Boolean']>;
  enable_sms_notifications?: Maybe<Scalars['Boolean']>;
  enable_referral_code?: Maybe<Scalars['Boolean']>;
  sms_provider?: Maybe<Scalars['String']>;
  voice_call_provider?: Maybe<Scalars['String']>;
  nida?: Maybe<Scalars['Boolean']>;
  video_provider?: Maybe<Scalars['String']>;
  enable_clinical_coding?: Maybe<Scalars['Boolean']>;
  public_healthcare_registration_text?: Maybe<Scalars['String']>;
  public_healthcare_registration_subtext?: Maybe<Scalars['String']>;
  public_healthcare_registration_help_url?: Maybe<Scalars['String']>;
};

export type RegionInput = {
  iso_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Relationship = {
  id: Scalars['ID'];
  dependant: Scalars['String'];
  type: Scalars['String'];
  profile: Profile;
};

export type RelationshipIris = {
  subject: Scalars['String'];
  attribute: Scalars['String'];
  object: Scalars['String'];
};

export type RepeatIssue = {
  id: Scalars['ID'];
  durationDays?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  issuedAt?: Maybe<Scalars['Date']>;
  repeatsIssued?: Maybe<Scalars['Int']>;
  sourceSystem?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  template?: Maybe<RepeatTemplate>;
};

export type RepeatRequest = {
  id: Scalars['ID'];
  daysEarly?: Maybe<Scalars['Int']>;
  denialReason?: Maybe<Scalars['String']>;
  patientReason?: Maybe<Scalars['String']>;
  repeatsRequested?: Maybe<Scalars['Int']>;
  state?: Maybe<RepeatRequestState>;
  requestedAt?: Maybe<Scalars['Date']>;
  template?: Maybe<RepeatTemplate>;
};

export enum RepeatRequestState {
  Requested = 'REQUESTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Issued = 'ISSUED'
}

export type RepeatTemplate = Paging & {
  id: Scalars['ID'];
  patient: Patient;
  daysToReorder?: Maybe<Scalars['Int']>;
  drugId?: Maybe<Scalars['String']>;
  drugName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  reviewDate?: Maybe<Scalars['Date']>;
  lastAvailabilityDateTime?: Maybe<Scalars['Date']>;
  lastUserChange?: Maybe<Scalars['Date']>;
  state?: Maybe<RepeatTemplateState>;
  latestRequest?: Maybe<RepeatRequest>;
  latestIssue?: Maybe<RepeatIssue>;
  latestPharmacy?: Maybe<RepeatTemplatePharmacy>;
  userState: UserTemplateState;
};

export type RepeatTemplatePharmacy = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<PharmacyAddress>;
};

export type RepeatTemplateResults = Pagination & {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<RepeatTemplate>>;
};

export enum RepeatTemplateState {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  PendingOnGp = 'PENDING_ON_GP',
  PendingOnPrescribingPharmacist = 'PENDING_ON_PRESCRIBING_PHARMACIST',
  Archived = 'ARCHIVED',
  Error = 'ERROR',
  Stopped = 'STOPPED'
}

export type ReportQuery = {
  key?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type RequestSyncShiftsReportInput = {
  syncId: Scalars['ID'];
};

export type RetrievalError = Error & {
  message: Scalars['String'];
};

export type Review = {
  id: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<Appointment>;
  appointment_id?: Maybe<Scalars['Int']>;
  reason_id?: Maybe<Scalars['Int']>;
};

export type ReviewContributionResponse = {
  status: Scalars['String'];
};

export type RevisionAlreadyPublishedInConsultationError = {
  carePlanRevisionId: Scalars['ID'];
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type Role = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SaveServiceTypeInput = {
  uuid: Scalars['ID'];
  name: Scalars['String'];
  member_facing_name?: Maybe<Scalars['String']>;
  default_duration_minutes?: Maybe<Scalars['Int']>;
  intimate?: Maybe<Scalars['Boolean']>;
  member_description?: Maybe<Scalars['String']>;
  member_instructions?: Maybe<Scalars['String']>;
  us_ehr_appointment_type?: Maybe<Scalars['String']>;
  grouping?: Maybe<Scalars['String']>;
  common?: Maybe<Scalars['Boolean']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_medium?: Maybe<Scalars['String']>;
  service_type_categories?: Maybe<Array<Maybe<ServiceTypeCategoryInput>>>;
};

export type ScheduleDateInfo = {
  date?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Int']>;
};

export type SearchFastCtConceptEntity = {
  value: Scalars['String'];
  lang: Scalars['String'];
};

export type SearchFastCtConcepts = {
  iri: Scalars['String'];
  semanticTag: Array<Maybe<SearchFastCtConceptEntity>>;
  prefLabel: Array<Maybe<SearchFastCtConceptEntity>>;
  altLabel: Array<Maybe<SearchFastCtConceptEntity>>;
};

export type SearchFastCtConceptsEntity = {
  concepts: Array<Maybe<SearchFastCtConcepts>>;
  pageCursor: Scalars['Int'];
  total: Scalars['Int'];
};

export type SearchPagination = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchPointOfCareCodeDetailsEntity = {
  code: Scalars['String'];
  system: Scalars['String'];
  display: Scalars['String'];
};

export type SearchPointOfCareCodeEntity = {
  code: SearchPointOfCareCodeDetailsEntity;
};

export type SearchPointOfCareEntity = {
  concepts: Array<Maybe<SearchPointOfCareCodeEntity>>;
  total: Scalars['Int'];
};

export type SemanticType = {
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
};

export type SendPatientReferralInput = {
  id: Scalars['ID'];
  contract_uuid: Scalars['String'];
};

export type ServiceCall = {
  service?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type ServiceType = {
  id?: Maybe<Scalars['ID']>;
  uuid: Scalars['ID'];
  name: Scalars['String'];
  member_facing_name?: Maybe<Scalars['String']>;
  default_duration_minutes?: Maybe<Scalars['Int']>;
  intimate?: Maybe<Scalars['Boolean']>;
  member_description?: Maybe<Scalars['String']>;
  member_instructions?: Maybe<Scalars['String']>;
  us_ehr_appointment_type?: Maybe<Scalars['String']>;
  clinicians: Array<Consultant>;
  clinics: Array<Practice>;
  consumer_networks: Array<ConsumerNetwork>;
  grouping?: Maybe<Scalars['String']>;
  first_available_slot?: Maybe<ServiceTypeFirstAvailableSlot>;
  preferred_medium: Scalars['String'];
  allowed_mediums: Array<Scalars['String']>;
  common?: Maybe<Scalars['Boolean']>;
  categories: Array<ServiceTypeCategory>;
  service_type_settings?: Maybe<ServiceTypeSettings>;
};

export type ServiceTypeAssignment = {
  id: Scalars['String'];
  minimum_wait_time_in_minute?: Maybe<Scalars['Int']>;
  refund_exclusion_time_in_minutes?: Maybe<Scalars['Int']>;
  booking_restricted?: Maybe<BookingRestrictedInput>;
};

export type ServiceTypeCategory = {
  id: Scalars['String'];
  uuid: Scalars['String'];
  name: Scalars['String'];
};

export type ServiceTypeCategoryInput = {
  uuid: Scalars['String'];
  name: Scalars['String'];
};

export type ServiceTypeFirstAvailableSlot = {
  time: Scalars['String'];
  clinician_id: Scalars['ID'];
  clinic_id: Scalars['ID'];
};

export type ServiceTypeForConfig = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  recommended?: Maybe<Scalars['Boolean']>;
  medium?: Maybe<Array<Maybe<Scalars['String']>>>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
  minimum_wait_time_in_minute?: Maybe<Scalars['Int']>;
  refund_exclusion_time_in_minutes?: Maybe<Scalars['Int']>;
  booking_restricted?: Maybe<BookingRestricted>;
};

export type ServiceTypeForConfigCategory = {
  id: Scalars['String'];
  name: Scalars['String'];
  medium: Array<Scalars['String']>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
  recommended?: Maybe<Scalars['Boolean']>;
};

export type ServiceTypeSettings = {
  recurrence_settings?: Maybe<RecurrenceSettings>;
};

export type SessionFile = {
  fileId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type ShiftsCsvDownload = {
  reportDownloadUrl: Scalars['String'];
};

export type SickNote = {
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  noteContent?: Maybe<Scalars['String']>;
  note_content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SignerTokenInput = {
  channel: Scalars['String'];
};

export type SignUpNotices = {
  terms?: Maybe<PrivacyNotice>;
  privacyPolicy?: Maybe<PrivacyNotice>;
};

export type SlackMessage = {
  blocks: Array<SlackMessageBlock>;
};

export type SlackMessageBlock = {
  type: Scalars['String'];
  fields?: Maybe<Array<SlackMessageField>>;
};

export type SlackMessageBlockInput = {
  type: Scalars['String'];
  fields?: Maybe<Array<SlackMessageFieldInput>>;
};

export type SlackMessageField = {
  type: Scalars['String'];
  text: Scalars['String'];
};

export type SlackMessageFieldInput = {
  type: Scalars['String'];
  text: Scalars['String'];
};

export type SlackMessageInput = {
  blocks: Array<SlackMessageBlockInput>;
};

export type SlotAppointmentMap = {
  slot_id: Scalars['Int'];
  appointment_id?: Maybe<Scalars['Int']>;
};

export enum SlotMedium {
  Digital = 'digital',
  Physical = 'physical',
  DigitalOrPhysical = 'digital_or_physical'
}

export enum SmokingStatus {
  Smoker = 'smoker',
  NonSmoker = 'non_smoker',
  ExSmoker = 'ex_smoker'
}

export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending'
}

export type Specialism = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SpecialismCategory = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  specialisms: Array<Maybe<Specialism>>;
  appointment?: Maybe<Appointment>;
};

export type Specialist = {
  id: Scalars['ID'];
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  specialism: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
};

export type State = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Subject = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
};

export type Subpartners = {
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<TenancyPartner>;
};

export type Subscription = {
  id?: Maybe<Scalars['ID']>;
  patient_id: Scalars['ID'];
  plan_id: Scalars['ID'];
  state: SubscriptionStatus;
  started_on?: Maybe<Scalars['String']>;
  ended_on?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency_symbol?: Maybe<Scalars['String']>;
  consumer_network_name?: Maybe<Scalars['String']>;
  is_preferred: Scalars['Boolean'];
  can_be_cancelled: Scalars['Boolean'];
  plan: SubscriptionPlan;
  credit_card?: Maybe<PortalCreditCard>;
};

export type SubscriptionPlan = {
  id: Scalars['ID'];
  title: Scalars['String'];
  default: Scalars['Boolean'];
};

export enum SubscriptionStatus {
  Pending = 'pending',
  Active = 'active',
  Expired = 'expired',
  Cancelled = 'cancelled',
  PastDue = 'past_due'
}

export type SuggestedAnswer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SuggestedPrescription = {
  drugId?: Maybe<Scalars['String']>;
  drugName?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  dosage?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  guidance?: Maybe<Scalars['String']>;
};

export type SupplyNetwork = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
};

export type SupportInteraction = {
  transactionId: Scalars['ID'];
  transactionType: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SyncHumanityFilterItem = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SyncHumanityFilters = {
  userId?: Maybe<Scalars['ID']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  clinicians?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
  supplyNetworks?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
  professions?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
  practices?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
};

export type SystemCode = {
  value: Scalars['String'];
  type: Scalars['String'];
};

export type Tag = {
  id: Scalars['ID'];
  name: Scalars['String'];
  associatedAnswer: AnswerTemplate;
  highPriority: Scalars['Boolean'];
};

export type Tenancies = {
  configuration_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  tenant_type?: Maybe<Scalars['String']>;
};

export type TenancyPartner = {
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  fullPath: Scalars['String'];
  parents: Array<TenancyPartner>;
  defaultChildType: Scalars['String'];
  type: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  parent?: Maybe<TenancyPartner>;
  subpartners?: Maybe<Array<Maybe<TenancyPartner>>>;
  subpartnersPaginated: Subpartners;
  plans?: Maybe<MembershipPlansPaginated>;
  contracts: ContractsPaginated;
  contractsByPlan: Array<ContractsByPlan>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  memberCount?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
};


export type TenancyPartnerSubpartnersPaginatedArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type TenancyPartnerPlansArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  hasParents?: Maybe<Scalars['Boolean']>;
  regionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeSubRegions?: Maybe<Scalars['Boolean']>;
};


export type TenancyPartnerContractsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  includeSubpartners?: Maybe<Scalars['Boolean']>;
};


export type TenancyPartnerContractsByPlanArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  includeSubpartners?: Maybe<Scalars['Boolean']>;
};

export type TenancyPartnerInput = {
  id: Scalars['ID'];
  parentId: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  defaultChildType: Scalars['String'];
};

export type TenancyPartnersList = {
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<TenancyPartner>;
};

export type TenancyPartnerUpdateInput = {
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  defaultChildType?: Maybe<Scalars['String']>;
};

export type TenancyRegion = {
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  parent?: Maybe<Region>;
  parentRegion?: Maybe<TenancyRegion>;
  country?: Maybe<TenancyRegion>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  subregions: Array<TenancyRegion>;
  level: Scalars['Int'];
  isCountry: Scalars['Boolean'];
  countryId?: Maybe<Scalars['ID']>;
  coreRubyRegion?: Maybe<Region>;
};

export type TestReport = Paging & TimelineEventInterface & {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  createdOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  reviewedOn?: Maybe<Scalars['String']>;
  sentOn?: Maybe<Scalars['String']>;
  appointmentSuggested?: Maybe<Scalars['Boolean']>;
  reviewerComments?: Maybe<Scalars['String']>;
  supportMessage?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  measures?: Maybe<Array<Maybe<TestReportComponent>>>;
  profiles?: Maybe<Array<Maybe<TestReportProfile>>>;
  code?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patient?: Maybe<TestReportPatient>;
  labPatient?: Maybe<TestReportPatient>;
  labName?: Maybe<Scalars['String']>;
  labNotes?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  additionalInfo?: Maybe<TestReportLabInfo>;
  sender?: Maybe<TestReportClinician>;
  reviewer?: Maybe<TestReportClinician>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export type TestReportClinician = {
  fullName?: Maybe<Scalars['String']>;
};

export type TestReportComponent = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  valueNumeric?: Maybe<Scalars['Float']>;
  valuePercentage?: Maybe<Scalars['Float']>;
  valueString?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  failureReason?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  recognisedBiomarker?: Maybe<Scalars['Boolean']>;
  recognisedValue?: Maybe<Scalars['Boolean']>;
  ranges?: Maybe<Array<Maybe<TestReportRange>>>;
};

export type TestReportLabInfo = {
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  labNotes?: Maybe<Scalars['String']>;
};

export type TestReportPatient = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type TestReportProfile = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  labNotes?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<TestReportLabInfo>;
  setId?: Maybe<Scalars['Int']>;
  reviewerComments?: Maybe<Scalars['String']>;
  biomarkers?: Maybe<Array<Maybe<TestReportComponent>>>;
};

export type TestReportProfileComment = {
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type TestReportRange = {
  lower?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
  labRange?: Maybe<Scalars['String']>;
};

export type TestReports = Pagination & {
  pageIndex?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<TestReport>>;
};

export type TestResult = {
  id?: Maybe<Scalars['ID']>;
};

export type TestType = {
  id: Scalars['ID'];
  testType?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type TimelineChatConversation = TimelineEventInterface & {
  id: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
  concepts?: Maybe<Array<Maybe<PgmConcept>>>;
  conversation?: Maybe<Array<Maybe<PgmQuestion>>>;
  triageOutcome?: Maybe<Scalars['String']>;
};

export type TimelineEvent = Consultation | PatientQuestion | ChatscriptConversation | BloodTest | TestReport | WorkflowV2 | RepeatTemplate | TimelineChatConversation;

export type TimelineEventInterface = {
  id: Scalars['ID'];
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export enum TimelineEventType {
  Consultation = 'Consultation',
  PatientQuestion = 'PatientQuestion',
  ChatscriptConversation = 'ChatscriptConversation',
  BloodTest = 'BloodTest',
  TestReport = 'TestReport',
  WorkflowV2 = 'WorkflowV2',
  RepeatTemplate = 'RepeatTemplate',
  TimelineChatConversation = 'TimelineChatConversation'
}

export type TokenValidity = {
  status?: Maybe<Scalars['Int']>;
  expiryTime?: Maybe<Scalars['String']>;
  expiresInMs?: Maybe<Scalars['Int']>;
};

export type TranslationLanguages = {
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TreatmentPlan = {
  ageGroup?: Maybe<Scalars['String']>;
  penicillinAllergicOption?: Maybe<Scalars['Boolean']>;
  prescription?: Maybe<Array<Maybe<SuggestedPrescription>>>;
};

export type UnresolvedWarningsError = {
  carePlanDraftId: Scalars['ID'];
  warnings: Array<DraftMetadataWarning>;
};

export type UsEhrConsultation = {
  externalAppointmentId?: Maybe<Scalars['String']>;
  ehrDepartmentId?: Maybe<Scalars['String']>;
  externalEncounterId?: Maybe<Scalars['String']>;
};

export type UsEhrNote = {
  htmlSummary: Scalars['String'];
};

export type UserRoles = {
  roles: Array<Scalars['String']>;
  user: Scalars['String'];
};

export type UserStateFilter = {
  userState?: Maybe<UserTemplateState>;
  lastChangeDateMin?: Maybe<Scalars['Date']>;
  lastChangeDateMax?: Maybe<Scalars['Date']>;
};

export enum UserTemplateState {
  Collected = 'COLLECTED',
  ReadyToCollect = 'READY_TO_COLLECT',
  ReorderSoon = 'REORDER_SOON',
  ReorderNow = 'REORDER_NOW',
  CheckingReorder = 'CHECKING_REORDER',
  FollowUpNeeded = 'FOLLOW_UP_NEEDED',
  OrderNow = 'ORDER_NOW',
  Archived = 'ARCHIVED',
  Stopped = 'STOPPED'
}

export type ValidationResult = {
  isDuplicate: Scalars['Boolean'];
};

export type Validity = {
  valid: Scalars['Boolean'];
  errorCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ValueCoding = {
  system: Scalars['String'];
  code: Scalars['String'];
};

export enum VerificationStatus {
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Stale = 'stale',
  NotSubmitted = 'not_submitted',
  New = 'new'
}

export enum VideoSessionUserTypes {
  Guest = 'GUEST',
  Patient = 'PATIENT',
  Consultant = 'CONSULTANT'
}

export type Viewer = {
  id: Scalars['ID'];
  uuid: Scalars['String'];
  type: Scalars['String'];
  roles: Array<Role>;
  patient?: Maybe<Patient>;
  consultant?: Maybe<Consultant>;
  timezone?: Maybe<Scalars['String']>;
  partnerIds?: Maybe<Array<Scalars['String']>>;
  full_name?: Maybe<Scalars['String']>;
};


export enum Vote {
  Up = 'UP',
  Down = 'DOWN',
  Neutral = 'NEUTRAL'
}

export type WebPlatformAccessResourceInput = {
  resource: Scalars['String'];
  action: Scalars['String'];
};

export type Workflow = {
  id: Scalars['ID'];
  status?: Maybe<WorkflowStatus>;
  type?: Maybe<Scalars['String']>;
  subType?: Maybe<WorkflowSubType>;
};

export type WorkflowAddressInput = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  admin_area_1?: Maybe<Scalars['String']>;
  admin_area_2?: Maybe<Scalars['String']>;
  country_code: Scalars['String'];
  postal_code: Scalars['String'];
};

export type WorkflowAppointmentActionInput = {
  type?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  interpreter?: Maybe<Scalars['String']>;
  mobility?: Maybe<Scalars['String']>;
  follow_up_date?: Maybe<Scalars['String']>;
  gp_nurse_pharmacist?: Maybe<Scalars['String']>;
};

export type WorkflowDefinition = {
  id: Scalars['ID'];
  key: Scalars['String'];
  version: Scalars['String'];
  tenant_id?: Maybe<Scalars['ID']>;
  workflow_definition_name?: Maybe<Scalars['String']>;
  variables?: Maybe<Array<WorkflowDefinitionVariable>>;
  group: WorkflowDefinitionGroup;
  roles: Array<Scalars['String']>;
};

export type WorkflowDefinitionGroup = {
  type: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  assistive_texts?: Maybe<Array<WorkflowDefinitionGroupAssistiveText>>;
};

export type WorkflowDefinitionGroupAssistiveText = {
  instruction: Scalars['String'];
};


export type WorkflowDefinitionVariable = {
  name: Scalars['String'];
  type: WorkflowDefinitionVariableType;
  label: Scalars['String'];
  is_required: Scalars['Boolean'];
  metadata: Array<WorkflowDefinitionVariableMetadata>;
};

export type WorkflowDefinitionVariableMetadata = {
  type: WorkflowDefinitionVariableMetadataType;
  value?: Maybe<WorkflowDefinitionVariableMetadataObject>;
};

export type WorkflowDefinitionVariableMetadataAllowedValue = {
  values: Array<WorkflowDefinitionVariableMetadataAllowedValuesOption>;
};

export type WorkflowDefinitionVariableMetadataAllowedValuesOption = {
  key: Scalars['String'];
  label: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataAsyncCodeSearchValue = {
  coding_system: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataChildAllowedValue = {
  parent_variable: Scalars['String'];
  parent_mappings: Scalars['WorkflowDefinitionParentMapping'];
};

export type WorkflowDefinitionVariableMetadataDateFormat = {
  format: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataDefaultValue = {
  default_value: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataObject = WorkflowDefinitionVariableMetadataChildAllowedValue | WorkflowDefinitionVariableMetadataAllowedValue | WorkflowDefinitionVariableMetadataAsyncCodeSearchValue | WorkflowDefinitionVariableMetadataDateFormat | WorkflowDefinitionVariableMetadataRegexValidation | WorkflowDefinitionVariableMetadataSource | WorkflowDefinitionVariableMetadataDefaultValue;

export type WorkflowDefinitionVariableMetadataPlaceholder = {
  placeholder: Scalars['String'];
  default_value?: Maybe<Scalars['String']>;
};

export type WorkflowDefinitionVariableMetadataRegexValidation = {
  regex: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataSource = {
  type: WorkflowDefinitionVariableMetadataSourceType;
  source_info?: Maybe<WorkflowDefinitionVariableMetadataPlaceholder>;
};

export enum WorkflowDefinitionVariableMetadataSourceType {
  User = 'USER',
  Context = 'CONTEXT'
}

export enum WorkflowDefinitionVariableMetadataType {
  AsyncCodeSearch = 'ASYNC_CODE_SEARCH',
  AllowedValues = 'ALLOWED_VALUES',
  ChildAllowedValues = 'CHILD_ALLOWED_VALUES',
  DateFormat = 'DATE_FORMAT',
  RegexValidation = 'REGEX_VALIDATION',
  Source = 'SOURCE',
  DefaultValue = 'DEFAULT_VALUE'
}

export enum WorkflowDefinitionVariableType {
  String = 'STRING',
  Long = 'LONG',
  Double = 'DOUBLE',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  DateTimeTz = 'DATE_TIME_TZ',
  MultiLine = 'MULTI_LINE'
}

export type WorkflowEngineMetadata = {
  page_number?: Maybe<Scalars['Int']>;
  page_size?: Maybe<Scalars['Int']>;
  number_of_pages?: Maybe<Scalars['Int']>;
  number_of_results?: Maybe<Scalars['Int']>;
};

export type WorkflowEngineResponse = {
  metadata?: Maybe<WorkflowEngineMetadata>;
};

export type WorkflowInput = {
  member_address?: Maybe<WorkflowAddressInput>;
  clinician_id: Scalars['ID'];
  clinician_name?: Maybe<Scalars['String']>;
  consultation_id?: Maybe<Scalars['ID']>;
  member_contact_number?: Maybe<Scalars['String']>;
  member_date_of_birth?: Maybe<Scalars['String']>;
  member_email?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['ID']>;
  member_name?: Maybe<Scalars['String']>;
  member_consumer_network?: Maybe<Scalars['String']>;
  member_ccg_name?: Maybe<Scalars['String']>;
  member_ccg_code?: Maybe<Scalars['String']>;
  nhs_number?: Maybe<Scalars['String']>;
};

export type WorkflowInvestigationActionInput = {
  type: Scalars['String'];
  sub_type: Scalars['String'];
  notes: Scalars['String'];
  urgency: Scalars['String'];
};

export type WorkflowLetterActionInput = {
  type: Scalars['String'];
  sub_type: Scalars['String'];
  notes: Scalars['String'];
  urgency: Scalars['String'];
  content: Scalars['String'];
};

export type WorkflowOtherActionInput = {
  type?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type WorkflowReferralActionInput = {
  type?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  interpreter?: Maybe<Scalars['String']>;
  mobility?: Maybe<Scalars['String']>;
  sub_speciality?: Maybe<Scalars['String']>;
};

export type WorkflowSearchCriteria = {
  state?: Maybe<WorkflowState>;
};

export type WorkflowsResponse = WorkflowEngineResponse & {
  metadata?: Maybe<WorkflowEngineMetadata>;
  results: Array<WorkflowV2>;
};

export enum WorkflowState {
  Completed = 'COMPLETED',
  Suspended = 'SUSPENDED',
  Active = 'ACTIVE',
  InternallyTerminated = 'INTERNALLY_TERMINATED',
  ExternallyTerminated = 'EXTERNALLY_TERMINATED',
  Unknown = 'UNKNOWN'
}

export enum WorkflowStatus {
  Submitted = 'submitted',
  InProgress = 'in_progress',
  Completed = 'completed'
}

export type WorkflowSubType = {
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type WorkflowTask = {
  id: Scalars['ID'];
  assignee?: Maybe<Scalars['String']>;
  clinician?: Maybe<Consultant>;
  consumerNetwork?: Maybe<ConsumerNetwork>;
  delete_reason?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['String']>;
  task_definition_key?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['ID']>;
  workflow?: Maybe<WorkflowV2>;
  workflow_id?: Maybe<Scalars['ID']>;
  variables?: Maybe<Array<Maybe<WorkflowTaskVariable>>>;
};

export type WorkflowTasksResponse = WorkflowEngineResponse & {
  metadata?: Maybe<WorkflowEngineMetadata>;
  results?: Maybe<Array<Maybe<WorkflowTask>>>;
};

export type WorkflowTaskVariable = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum WorkflowType {
  Appointment = 'appointment',
  Investigation = 'investigation',
  Letter = 'letter',
  Referral = 'referral',
  Other = 'other'
}

export type WorkflowV2 = TimelineEventInterface & {
  id: Scalars['ID'];
  start_time: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  state: WorkflowState;
  idempotency_key: Scalars['String'];
  workflow_definition_key: Scalars['String'];
  workflow_definition_id: Scalars['String'];
  tenant_id?: Maybe<Scalars['ID']>;
  variables: Array<WorkflowVariable>;
  tasks?: Maybe<WorkflowTasksResponse>;
  workflowDefinition?: Maybe<WorkflowDefinition>;
  /** @deprecated Field no longer supported */
  workflow_definition?: Maybe<WorkflowDefinition>;
  consumerNetwork?: Maybe<ConsumerNetwork>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export type WorkflowVariable = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type WorkflowVariableInput = {
  name: Scalars['String'];
  value?: Maybe<Scalars['WorkflowDefinitionParentMapping']>;
};

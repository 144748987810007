import { FC } from 'react'
import { appDownloadButtonTheme as appDownloadButton } from '../AppDownloadButton/theme'
import { buttonTheme as button } from '../Button/theme'
import { cardTheme as card } from '../Card/theme'
import { checkboxTheme as checkbox } from '../Forms/Checkboxes/theme'
import { inputTheme as input } from '../Forms/Input/theme'
import { linkTheme as link } from '../Link/theme'
import { selectTheme as select } from '../Forms/Select/theme'
import { spinnerTheme as spinner } from '../Spinner/theme'
import stepper from '../Stepper/theme'
import { textareaTheme as textarea } from '../Forms/Textarea/theme'
import { toggleTheme as toggle } from '../Toggle/theme'
import { breadcrumbsTheme as breadcrumbs } from '../Breadcrumbs/theme'
import { profileTheme as profile } from '../Profile/theme'
import { Theme } from './Theme.types'

export interface IThemeProvider<P> extends FC<P> {
  theme: typeof themes
}

export const baseTheme = {
  font: '--medkit-font',
  primary: '--medkit-primary',
  primaryLight: '--medkit-primary-light',
  primaryBorder: '--medkit-primary-border',
  onPrimary: '--medkit-on-primary',
  primaryActive: '--medkit-primary-active',
  onPrimaryActive: '--medkit-on-primary-active',
  secondary: '--medkit-secondary',
  secondaryBorder: '--medkit-secondary-border',
  onSecondary: '--medkit-on-secondary',
  secondaryActive: '--medkit-secondary-active',
  onSecondaryActive: '--medkit-on-secondary-active',
}

export const themes = {
  baseTheme,
  button,
  card,
  checkbox,
  input,
  select,
  stepper,
  textarea,
  link,
  spinner,
  toggle,
  appDownloadButton,
  breadcrumbs,
  profile,
}

export const generateTheme = (theme: Theme) => {
  const variables: any = {}

  if (theme.font) {
    variables[baseTheme.font] = theme.font.family
  }

  if (theme.colors) {
    variables[baseTheme.primary] = theme.colors.primary
    variables[baseTheme.primaryLight] = theme.colors.primaryLight
    variables[baseTheme.primaryActive] = theme.colors.primary
    variables[button.primaryBorderColorActive] = theme.colors.accent
    variables[button.primaryBackground] = theme.colors.primary
    variables[button.colorOne] = theme.colors.primary
    variables[input.borderColorActive] = theme.colors.accent
    variables[input.boxShadowColorActive] = theme.colors.primary
  }

  return variables
}

import React from 'react'
import { AtlasWrapper, TokenInHeader } from '@babylon/atlas.js'
import { envVar } from '@babylon/babylon-env'
import { getHeaders as getBabylonHeaders } from '@babylon/headers'

export const LiveConversationsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const atlasEnv = envVar('ATLAS_ENV')
  const debug = ['dev-uk', 'preprod-uk'].includes(atlasEnv)

  return (
    <AtlasWrapper
      token={TokenInHeader}
      environment={atlasEnv}
      getHeaders={() =>
        getBabylonHeaders({
          appId: 'Admin Portal',
          appVersion: process.env.REACT_APP_VERSION || 'unknown',
        }) as Record<string, string>
      }
      debug={debug}
    >
      {children}
    </AtlasWrapper>
  )
}

import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useQuery, QueryResult, useMutation } from '@apollo/client'
import { Card, Page } from '@babylon/core-ui'
import {
  useTrackClick,
  TrackingElementType,
  useTracking,
  TrackingActionType,
} from '@babylon/tracking/react'
import { UnexpectedError } from '../../Utils'
import CreateForm from './CreateForm'
import MemberQuery from '../MemberQuery'
import InviteSentConfirmation from '../../SetupAppointment/CreateInvite/InviteSentConfirmation'
import { AppointmentAnalyticsModuleName } from '../../analytics/types'
import GetServiceTypesQuery from './GetServiceTypesQuery'
import CreateAppointmentInvite from '../CreateInviteMutation'
import {
  getAllowedMediumsForSelectedMedium,
  filterByMediumServiceTypes,
  findServiceTypeById,
} from './FormWithServiceTypes.utils'
import { MediumTypes, Mediums } from './AppointmentMedium'
import ServiceType from '../../types/ServiceType'
import styles from '../DigitalAppointmentForm.module.css'

interface Props {
  memberId: string
  mediumSelectorEnabled: boolean
}

const baseValues = {
  appointment_type: '',
  notes_for_member: '',
  duration_minutes: null,
}

type ServiceTypesData = {
  serviceTypes: ServiceType[]
}
type ServiceTypesVars = {
  memberUuid: string
  includingDigital: boolean
}

export default function FormWithServiceTypes({
  memberId,
  mediumSelectorEnabled,
}: Props) {
  const [isSelectMediumDisplayed, setIsSelectMediumDisplayed] = useState(
    mediumSelectorEnabled
  )
  const defaultMedium = isSelectMediumDisplayed ? Mediums.f2f : null
  const { state } = useLocation()
  const [selectedMedium, setSelectedMedium] = useState<MediumTypes | null>(
    state?.selectedMedium || defaultMedium
  )

  const { data: patientData } = useQuery(MemberQuery, {
    variables: { memberId },
  })

  const {
    loading: loadingServiceTypes,
    error: errorServiceTypes,
    data: dataServiceTypes,
  }: QueryResult = useQuery<ServiceTypesData, ServiceTypesVars>(
    GetServiceTypesQuery,
    {
      skip: !patientData?.patient?.uuid,
      fetchPolicy: 'network-only',
      variables: {
        includingDigital: true,
        memberUuid: patientData?.patient?.uuid,
      },
      onCompleted: (data) => {
        const serviceTypes = data?.serviceTypes ?? []

        const noServiceTypeForF2F = !serviceTypes.find(({ allowed_mediums }) =>
          allowed_mediums.includes('physical')
        )

        if (noServiceTypeForF2F) {
          hideMediumSelector()
        }
      },
    }
  )

  const [
    createInvite,
    { data: submittedData, loading: submitting, error: errorSubmit },
  ] = useMutation(CreateAppointmentInvite)

  const { trackClick } = useTrackClick({
    moduleName: AppointmentAnalyticsModuleName.digitalInvite,
  })

  const { trackEvent } = useTracking()

  const onTrackClick = (elementName) => {
    trackClick({
      elementName,
      elementType: TrackingElementType.button,
    })
  }

  const handleSubmit = (values) => {
    if (!patientData?.patient?.uuid) {
      return
    }

    const includeMediumSelection = mediumSelectorEnabled
      ? getAllowedMediumsForSelectedMedium(
          selectedMedium,
          findServiceTypeById(
            dataServiceTypes?.serviceTypes ?? [],
            values.appointment_type
          )?.allowed_mediums
        )
      : {}

    const createInviteForm = {
      service_type_uuid: values.appointment_type,
      notes_for_member: values.notes_for_member,
      duration_minutes: values.duration_minutes,
      member_uuid: patientData.patient.uuid,
      intimate: false,
      earliest_booking_date: new Date().toISOString(),
      ...includeMediumSelection,
    }
    createInvite({
      variables: {
        input: createInviteForm,
      },
    })

    onTrackClick('send-to-patient')
  }

  const hideMediumSelector = () => {
    setIsSelectMediumDisplayed(false)
    setSelectedMedium(null)
  }

  const error = errorServiceTypes || errorSubmit
  const loading = loadingServiceTypes || submitting
  const invite = submittedData?.createAppointmentInvite

  const isInviteSentConfirmationOpen = !submitting && !errorSubmit && !!invite

  if (isInviteSentConfirmationOpen) {
    trackEvent({
      moduleName: AppointmentAnalyticsModuleName.digitalInvite,
      actionType: TrackingActionType.popupLoaded,
      elementType: TrackingElementType.popup,
      elementName: 'invite-successfully-sent',
    })
  }

  const serviceTypes = filterByMediumServiceTypes(
    dataServiceTypes?.serviceTypes ?? [],
    selectedMedium
  )

  return (
    <Page
      title="Book an Appointment"
      breadcrumbs={[
        <Link key="1" to="/admin/patients/search">
          Patients
        </Link>,
        <Link key="2" to={`/admin/patients/${memberId}/memberships`}>
          Member details
        </Link>,
        'Book Appointment',
      ]}
    >
      {error && <UnexpectedError message={error.message} visible />}
      <InviteSentConfirmation
        className={styles.ModalButton}
        memberId={memberId}
        isOpen={isInviteSentConfirmationOpen}
        serviceTypeName={invite?.service_type?.name}
        durationMinutes={`${invite?.duration_minutes} minutes`}
        notes={invite?.notes_for_member}
        onTrackDoneBtn={() => onTrackClick('done')}
      />
      <Card>
        <CreateForm
          initialValues={{
            ...baseValues,
            ...(state?.originalFilters || {}),
          }}
          loading={loading}
          appointmentTypes={serviceTypes}
          onSubmit={handleSubmit}
          memberId={memberId}
          selectedMedium={selectedMedium}
          setSelectedMedium={setSelectedMedium}
          isSelectMediumDisplayed={isSelectMediumDisplayed}
          serviceTypes={dataServiceTypes?.serviceTypes ?? []}
        />
      </Card>
    </Page>
  )
}

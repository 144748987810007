import { getJSON, CookieAttributes } from 'js-cookie'

interface IHeaderCookieAttributes extends CookieAttributes {
  csrf_token?: string
}

interface IGetLoginCookie {
  (cookieLoginInfo: string): IHeaderCookieAttributes
}

const getLoginCookie: IGetLoginCookie = (cookieLoginInfo) =>
  getJSON(cookieLoginInfo) || {}

export default getLoginCookie

import * as Yup from 'yup'
import { isValid } from 'date-fns'

export function atLeastOneOf(requiredFields) {
  return function validate(value) {
    return requiredFields.some((field) => {
      if (Array.isArray(value[field])) {
        return value[field]?.length > 0
      }

      return !!value[field]
    })
  }
}

export enum fieldLabelMap {
  'clinicianId' = 'Clinician Id',
  'serviceType' = 'Service Type',
  'clinicId' = 'Clinic Location',
  'clinicianInfo' = 'Clinician Name',
}

const ClinicianVisibilityValidation = Yup.object()
  .shape({
    profession: Yup.array().of(Yup.string()),
    dateTimeFrom: Yup.string()
      .required('')
      .typeError(' ')
      .test('is-valid-date-string', 'Invalid date time', (value) =>
        isValid(new Date(value))
      ),
    clinicianInfo: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
    ),
    consultantId: Yup.array().of(Yup.string()),
    serviceType: Yup.array().of(Yup.string()),
    clinicId: Yup.array().of(Yup.string()),
  })
  .test(
    'at-least-one-of',
    `Must have at least one of these fields: ${Object.values(
      fieldLabelMap
    ).join(', ')}`,
    atLeastOneOf(Object.keys(fieldLabelMap))
  )

export default ClinicianVisibilityValidation

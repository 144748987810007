import { gql } from '@apollo/client'

export const CONSUMER_NETWORKS_QUERY = gql`
  query getPatientConsumerNetwork($patientId: ID!) {
    patientConsumerNetworks(id: $patientId) {
      id
      active
      gp_at_hand
      is_preferred
    }
  }
`

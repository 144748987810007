import { useAtlas, useAtlasStore, useMemberMessages } from '@babylon/atlas.js'
import React, { useEffect } from 'react'
import { Spinner } from '@babylon/medkit'
import {
  CommaList,
  Pill,
  RelativeDateTimeWithOffset,
  WithTitle,
} from '../utils'
import { Panel, PanelRow } from '../panel'
import usePatientProfile from '../../pages/LiveConversationPage/usePatientProfile'
import { Message } from '../../pages/LiveConversationPage/components'
import styles from './conversationDetail.module.css'
import { Button } from '../button'
import { useLazyAgentName } from '../../hooks/useLazyAgentName'

interface Props {
  memberId: string
  conversationId: string
  onClose?: () => void
}

// eslint-disable-next-line import/prefer-default-export
export const ConversationDetail = ({
  memberId,
  conversationId,
  onClose,
}: Props) => {
  const { conversation, messages, state } = useMemberMessages(
    memberId,
    conversationId
  )
  const agentNameFetch = useLazyAgentName()
  const { patientProfile } = usePatientProfile(memberId)
  const [printMode, setPrintMode] = React.useState(false)
  const bots = useAtlasStore((s) => s.bots)
  const atlas = useAtlas()
  useEffect(() => {
    if (!bots || bots.status === 'idle') {
      atlas.memberHistory.loadBots()
    }
  }, [atlas, bots])

  useEffect(() => {
    if (printMode) {
      document.body.classList.add('allow-print')
      const root = document.getElementById('root')
      const rootDisplayStyle = root?.style.display

      if (root) {
        root.style.display = 'none'
      }

      const afterPrint = () => {
        setPrintMode(false)
      }
      window.addEventListener('afterprint', afterPrint)

      window.print()

      return () => {
        document.body.classList.remove('allow-print')
        const root = document.getElementById('root')

        if (root?.style) {
          root.style.display = rootDisplayStyle || ''
        }

        window.removeEventListener('afterprint', afterPrint)
      }
    }

    return () => {}
  }, [printMode])

  if (!conversation) {
    return <Spinner variant="primary" />
  }

  if (state === 'error') {
    return <>Error: ${messages}</>
  }

  const notAMember = ({ id }: { id?: string }) => id !== memberId

  const agent = conversation.participants.find(notAMember)
  const agents = conversation.participants.filter(notAMember)
  const sortedMessages = messages.sort((a, b) =>
    a.sentAt.localeCompare(b.sentAt)
  )
  const messagesAreLoading = state === 'loading'

  return (
    <Panel>
      <PanelRow type="header" data-testid="conversation-view-panel-head">
        <WithTitle title={agentNameFetch(agent?.id, agent?.type)}>
          Started{' '}
          <RelativeDateTimeWithOffset datetime={conversation.started_at} />
        </WithTitle>
        {onClose && (
          <Button onClick={onClose} className={styles.noPrint}>
            &times;
          </Button>
        )}
      </PanelRow>
      <PanelRow data-testid="conversation-view-panel-details">
        <dl>
          <dt>Status</dt>
          <dd>
            <Pill>{conversation.state}</Pill>
          </dd>
          <dt>ID</dt>
          <dd>{conversation.id}</dd>
          <dt>Participants</dt>
          <dd>
            <CommaList
              items={[
                patientProfile ? (
                  patientProfile.familyName
                ) : (
                  <Spinner variant="primary" />
                ),
                ...agents.map(({ id, type }) => agentNameFetch(id, type)),
              ]}
            />
          </dd>
          {conversation.tags.length > 0 && (
            <>
              <dt>Tags</dt>
              <dd>
                <CommaList items={conversation.tags} />
              </dd>
            </>
          )}
        </dl>
      </PanelRow>
      <PanelRow className={styles.messagesContainer}>
        {messagesAreLoading ? (
          <Spinner variant="primary" />
        ) : (
          sortedMessages.map((message) => (
            <Message
              key={message.messageId}
              message={message}
              mine={message.senderId !== memberId}
              attachments={conversation.attachments}
              conversationId={conversation.id}
            />
          ))
        )}
      </PanelRow>
      <PanelRow>
        {!printMode && (
          <Button
            disabled={messagesAreLoading}
            className={styles.noPrint}
            onClick={() => setPrintMode(true)}
          >
            Download this conversation
          </Button>
        )}
      </PanelRow>
    </Panel>
  )
}

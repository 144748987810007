// test doubles and mock used in story and test

import { DateTime } from 'luxon'
import {
  Atlas,
  AtlasAdminService,
  ApiContext,
  handlers,
  makeContainer,
  TextMessage,
  messageBus,
  BotService,
} from '@babylon/atlas.js'

// This module is used in tests and playground only
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  getAgentMatchingFake,
  getAttachmentServiceFake,
  getConversationServiceFake,
  getInboundMessageServiceFake,
  getBotServiceFake,
} from '@babylon/atlas.js-testing'

export const conversationWithLoadingMessages =
  '00000000-0000-0000-0000-000000000001'
export const memberId = '00000000-0000-0000-0000-00000000002a'

export const conversationA = {
  id: '965d177c-d1fc-41ba-9960-526c50f0370a',
  participants: [
    {
      id: memberId, // member
    },
    {
      id: 'kate',
    },
  ],
  last_message: 'Sure no worries',
  last_updated: DateTime.now().plus({ hours: -2 }).toISO(),
  state: 'started' as const,
  events: [
    {
      user_id: 'ADMIN',
      event_type: 'ABRUPT_TRANSFER' as const,
      timestamp: '2023-06-14T16:18:40.359535Z',
      event_id: '2b14ce47-190d-4446-a0b3-411bfb9b97a9',
      to_queue: 'SUPPORT',
      triggered_by: memberId,
    },
  ],
  started_at: DateTime.now().plus({ hours: -2 }).toISO(),
  tags: ['foo', 'bar', 'baz'],
  attachments: [],
}

const conversationB = {
  id: 'baaccc71-3ae1-43b7-9155-020c33c98175',
  participants: [
    {
      id: memberId,
    },
    {
      id: 'john',
    },
  ],
  last_message: 'bye!',
  last_updated: '2023-06-13T14:15:20.000000Z',
  state: 'finished' as const,
  events: [],
  started_at: DateTime.now().plus({ days: -3 }).toISO(),
  tags: ['intent:accounts'],
  attachments: [],
}
const conversationC = {
  id: '06b1b215-9d17-4309-b417-d38a4d6af71f',
  participants: [
    {
      id: memberId,
    },
    {
      id: 'bob',
    },
  ],
  last_message:
    ' [Screenshot 2023-05-09 at 12.35.24.png](https://services-uk.dev.babylontech.co.uk/atlas/v1/attachments/conversations/06b1b215-9d17-4309-b417-d38a4d6af71f/f1759562-7d3e-46a0-83f2-cd0cb9ad7c71)',
  last_updated: '2023-06-13T14:14:38.000000Z',
  state: 'finished' as const,
  events: [],
  started_at: DateTime.now().plus({ months: -2 }).toISO(),
  tags: ['intent:Something-else'],
  attachments: [
    {
      id: 'f1759562-7d3e-46a0-83f2-cd0cb9ad7c71',
      owning_member: 'kate',
      name: 'Screenshot 2023-05-09 at 12.35.24.png',
      content_type: 'image/png',
      metadata: {},
      status: 'uploaded' as const,
      created_at: '2023-06-13T14:14:21.000000Z',
    },
  ],
}
const conversationD = {
  id: conversationWithLoadingMessages,
  participants: [
    {
      id: memberId, // member
    },
    {
      id: 'pat',
    },
  ],
  last_message: 'Sure no worries',
  last_updated: DateTime.now().plus({ hours: -2 }).toISO(),
  state: 'started' as const,
  events: [
    {
      user_id: 'ADMIN',
      event_type: 'ABRUPT_TRANSFER' as const,
      timestamp: '2023-06-14T16:18:40.359535Z',
      event_id: '2b14ce47-190d-4446-a0b3-411bfb9b97a9',
      to_queue: 'SUPPORT',
      triggered_by: memberId,
    },
  ],
  started_at: DateTime.now().plus({ hours: -2 }).toISO(),
  tags: ['foo', 'bar', 'baz'],
  attachments: [],
}
const conversationWithBot = {
  id: 'with-bot',
  participants: [
    {
      id: memberId,
    },
    {
      id: 'bot-id',
    },
  ],
  last_message:
    ' [Screenshot 2023-05-09 at 12.35.24.png](https://services-uk.dev.babylontech.co.uk/atlas/v1/attachments/conversations/with-bot/f1759562-7d3e-46a0-83f2-cd0cb9ad7c71)',
  last_updated: '2023-06-13T14:14:38.000000Z',
  state: 'finished' as const,
  events: [],
  started_at: DateTime.now().plus({ months: -2 }).toISO(),
  tags: ['intent:Something-else'],
  attachments: [
    {
      id: 'f1759562-7d3e-46a0-83f2-cd0cb9ad7c71',
      owning_member: 'kate',
      name: 'Screenshot 2023-05-09 at 12.35.24.png',
      content_type: 'image/png',
      metadata: {},
      status: 'uploaded' as const,
      created_at: '2023-06-13T14:14:21.000000Z',
    },
  ],
}

const rawAdminServiceData = {
  conversations: [
    conversationA,
    conversationB,
    conversationC,
    conversationD,
    conversationWithBot,
  ],
  messages: {
    '965d177c-d1fc-41ba-9960-526c50f0370a': [
      {
        reqId: '7ef81d62-778b-4c05-9fa6-ae827d815b0b',
        messageId: '971eb144-3e0d-4a05-82d4-02f25278caf2',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '971eb144-3e0d-4a05-82d4-02f25278caf2',
        message: 'Hi, can you help me?',
        senderId: 'kate',
        sentAt: '2023-06-14T15:16:51.015562Z',
        conversationId: '965d177c-d1fc-41ba-9960-526c50f0370a',
      },
      {
        reqId: 'c63d9a61-962e-4bb1-9b18-7a0c1526bdab',
        messageId: '3bb17f37-8339-4ecb-88d6-e304da70cbe0',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '3bb17f37-8339-4ecb-88d6-e304da70cbe0',
        message: "I'm not sure if we can discuss it :)",
        senderId: 'kate',
        sentAt: '2023-06-14T15:17:05.576259Z',
        conversationId: '965d177c-d1fc-41ba-9960-526c50f0370a',
      },
      {
        reqId: '06cb3ea0-0619-415a-8025-16ba8ea3614f',
        messageId: '2f1d443f-6a3b-439e-9ad5-1eb994d7836f',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '2f1d443f-6a3b-439e-9ad5-1eb994d7836f',
        message: 'Sure no worries',
        senderId: memberId,
        sentAt: '2023-06-14T15:17:14.800540Z',
        conversationId: '965d177c-d1fc-41ba-9960-526c50f0370a',
      },
    ],
    'baaccc71-3ae1-43b7-9155-020c33c98175': [
      {
        reqId: '88644f6d-ff62-454c-af90-145440f44dd1',
        messageId: 'c952495d-3cd4-4e68-8634-6802861c8cfc',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: 'c952495d-3cd4-4e68-8634-6802861c8cfc',
        message: 'How are you?',
        senderId: 'kate',
        sentAt: '2023-06-13T14:15:02.970928Z',
        conversationId: 'baaccc71-3ae1-43b7-9155-020c33c98175',
      },
      {
        reqId: 'a38de98f-915e-4b05-9984-3013305f74b5',
        messageId: '7c332b43-abfc-4854-b2f8-320fb12d99c5',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '7c332b43-abfc-4854-b2f8-320fb12d99c5',
        message: "I'm good!",
        senderId: memberId,
        sentAt: '2023-06-13T14:15:17.034711Z',
        conversationId: 'baaccc71-3ae1-43b7-9155-020c33c98175',
      },
      {
        reqId: '5f95f7dd-23c6-483a-81bd-75cdecc9ef9d',
        messageId: '4abc469c-c807-4697-ac95-a854df9b1193',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '4abc469c-c807-4697-ac95-a854df9b1193',
        message: 'bye!',
        senderId: memberId,
        sentAt: '2023-06-13T14:15:18.874000Z',
        conversationId: 'baaccc71-3ae1-43b7-9155-020c33c98175',
      },
    ],
    '06b1b215-9d17-4309-b417-d38a4d6af71f': [
      {
        reqId: 'f7784859-ebd0-4049-9776-e6d701e89535',
        messageId: '95656479-e5db-4338-99a1-223a819f506e',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '95656479-e5db-4338-99a1-223a819f506e',
        message: 'Hello world',
        senderId: memberId,
        sentAt: '2023-06-13T14:14:07.954863Z',
        conversationId: '06b1b215-9d17-4309-b417-d38a4d6af71f',
      },
      {
        reqId: '6d744774-5e25-42e7-9153-74386f91d74c',
        messageId: '5f8ff11b-0762-43c9-b2c9-d8f667ff0979',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '5f8ff11b-0762-43c9-b2c9-d8f667ff0979',
        message: 'hello to you to!',
        senderId: 'kate',
        sentAt: '2023-06-13T14:14:13.936059Z',
        conversationId: '06b1b215-9d17-4309-b417-d38a4d6af71f',
      },
      {
        reqId: '155d858b-2d36-4707-93a8-61e7aba13250',
        messageId: 'a58d6408-ad61-430d-83c9-486f1e96168f',
        hasAttachment: true,
        messageType: 'TEXT',
        itemId: 'a58d6408-ad61-430d-83c9-486f1e96168f',
        message:
          ' [Screenshot 2023-05-09 at 12.35.24.png](https://services-uk.dev.babylontech.co.uk/atlas/v1/attachments/conversations/06b1b215-9d17-4309-b417-d38a4d6af71f/f1759562-7d3e-46a0-83f2-cd0cb9ad7c71)',
        senderId: memberId,
        sentAt: '2023-06-13T14:14:26.950817Z',
        conversationId: '06b1b215-9d17-4309-b417-d38a4d6af71f',
      },
    ],
    'with-bot': [
      {
        reqId: 'f7784859-ebd0-4049-9776-e6d701e89535',
        messageId: '95656479-e5db-4338-99a1-223a819f506e',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '95656479-e5db-4338-99a1-223a819f506e',
        message: 'Hello world',
        senderId: memberId,
        sentAt: '2023-06-13T14:14:07.954863Z',
        conversationId: 'with-bot',
      },
      {
        reqId: '6d744774-5e25-42e7-9153-74386f91d74c',
        messageId: '5f8ff11b-0762-43c9-b2c9-d8f667ff0979',
        hasAttachment: false,
        messageType: 'TEXT',
        itemId: '5f8ff11b-0762-43c9-b2c9-d8f667ff0979',
        message: 'hello to you to!',
        senderId: 'bot-id',
        sentAt: '2023-06-13T14:14:13.936059Z',
        conversationId: 'with-bot',
      },
      {
        reqId: '155d858b-2d36-4707-93a8-61e7aba13250',
        messageId: 'a58d6408-ad61-430d-83c9-486f1e96168f',
        hasAttachment: true,
        messageType: 'TEXT',
        itemId: 'a58d6408-ad61-430d-83c9-486f1e96168f',
        message:
          ' [Screenshot 2023-05-09 at 12.35.24.png](https://services-uk.dev.babylontech.co.uk/atlas/v1/attachments/conversations/with-bot/f1759562-7d3e-46a0-83f2-cd0cb9ad7c71)',
        senderId: memberId,
        sentAt: '2023-06-13T14:14:26.950817Z',
        conversationId: 'with-bot',
      },
    ],
  },
}

const fakeAdminService: AtlasAdminService = {
  queries: {
    getConversations: async () => rawAdminServiceData.conversations,
    getMessages: async (_, conversationId) => {
      if (conversationId === conversationWithLoadingMessages) {
        return new Promise<TextMessage[]>(() => {})
      }

      const messages = rawAdminServiceData.messages[conversationId]

      if (!messages) {
        throw new Error(`No messages found for conversation ${conversationId}`)
      }

      return messages
    },
  },
}

const fakeApiContext: ApiContext = {
  getHeaders: () => ({}),
  babylonAuthToken: 'fake-token',
  environment: 'dev-uk',
}

const fakeBot: BotService = getBotServiceFake().withBot({
  bot_id: 'bot-id',
  bot_name: 'bot-name',
  callback_url: 'some-callback-url',
  metadata: {},
  owner: 'bot-owner',
  token: 'some-token',
}).service as BotService

console.log('fakeBot', fakeBot)

const timestamp = DateTime.now().plus({ seconds: -42 }).toISO()!
export const atlasWithFakes = new Atlas(
  makeContainer()
    .bind(handlers.messageBus)
    .toConstantValue(messageBus())
    .bind(handlers.logger)
    .toConstantValue({
      log: (
        level: string,
        message: string | (() => string),
        sender: string
      ) => {
        // eslint-disable-next-line no-console
        console.log(
          `[${level}] ${sender}: ${
            typeof message === 'string' ? message : message()
          }`
        )
      },
    })
    .bind(handlers.admin)
    .toConstantValue(fakeAdminService)
    .bind(handlers.agentMatching)
    .toConstantValue(
      getAgentMatchingFake().withLiveConversation({
        assignation_confirmed: true,
        assignation_created_at: timestamp,
        live_conversation: {
          conversation_id: conversationA.id,
          initiator_id: memberId,
          timestamp,
          confirmed: true,
        },
      }).service
    )
    .bind(handlers.conversation)
    .toConstantValue(
      getConversationServiceFake().withConversationMessages(
        conversationA,
        rawAdminServiceData.messages[conversationA.id]
      ).service
    )
    .bind(handlers.inboundMessages)
    .toConstantValue(getInboundMessageServiceFake().service)
    .bind(handlers.apiContext)
    .toConstantValue(fakeApiContext)
    .bind(handlers.attachment)
    .toConstantValue(getAttachmentServiceFake().service)
    .bind(handlers.bot)
    .toConstantValue(fakeBot)
)

import useProductConfig from './useConfig'
import usePreFetchConfig from './usePrefetchConfig'
import getProductConfig from './getConfig'

const useBoundPreFetchConfig = usePreFetchConfig.bind(
  {},
  {
    getConfig: getProductConfig,
  }
)

const useBoundProuctConfig = useProductConfig.bind(
  {},
  {
    getConfig: getProductConfig,
  }
)

export {
  useBoundProuctConfig as useProductConfig,
  useBoundPreFetchConfig as usePreFetchConfig,
}
